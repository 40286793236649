import config from "../../config/api.config";
import { StartClockResponse, StopClockResponse } from "../../types/clock";
import ApiService from "../ApiService";

type CheckInRequestPayload = {
  users_id: number | undefined;
  starting_mileage: number;
};

type CheckOutRequestPayload = {
  clockId: number;
  users_id: number;
  ending_mileage: number;
};

const CheckInService = {
  CheckIn: async (data: CheckInRequestPayload): Promise<StartClockResponse> => {
    return ApiService.Post(
      `clockodo/clock/${config.customersId}/${config.servicesId}/start`,
      data
    );
  },
  CheckOut: async ({
    clockId,
    ...data
  }: CheckOutRequestPayload): Promise<StopClockResponse> => {
    return ApiService.Post(`clockodo/clock/${clockId}/stop`, data);
  },
};

export default CheckInService;
