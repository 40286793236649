import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import CustomerService from "../../api/CustomerService";
import PropertyService from "../../api/PropertyService";
import { generateUUIDFromID } from "../../services/UUIDGeneratorService";
import { RootState } from "../../store";
import { creatingTaskActions } from "../../store/task-slice";
import { BlockButton } from "../BlockButton/BlockButton";
import { CustomCollapseWithChildren } from "../CustomCollapseWithChildren/CustomCollapseWithChildren";
import { CustomTextArea } from "../CustomTextArea/CustomTextArea";
import { DateTimePicker2 } from "../DateTimePicker2/DateTimePicker2";
import { TechnicianListItem } from "../TechnicianListItem/TechnicianListItem";

import { AppointmentType } from "./AppointmentType";
import styles from "./task-form.module.css";

interface Props {
  setButtonDisabled: (value: boolean) => void;
}
export const StepThree = ({ setButtonDisabled }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const task = useSelector((state: RootState) => state.task);
  const technicians = useSelector((state: RootState) => state.technicians);

  const techniciansSelected = technicians.length > 0;

  // Create customer and object in backend
  useEffect(() => {
    const customerId = generateUUIDFromID(task.customer.id?.toString()!);
    const propertyId = generateUUIDFromID(task.property.id?.toString()!);
    setButtonDisabled(true);

    const createCustomer = async () => {
      try {
        const customer = await CustomerService.GetCustomer(customerId);
        console.log("This customer exists in our backend: ", customer.data.id);
        await createProperty(customer);
        setButtonDisabled(false);

      } catch (error: any) {
        if (error.status === 404) {
          try {
            const newCustomer = await CustomerService.CreateCustomer({
              firstName: task.customer?.name!,
              lastName: task.customer.name!,
              isSevdeskCustomer: true,
              id: customerId,
            });
            await createProperty(newCustomer);
            setButtonDisabled(false);

          } catch (error) {
            console.log("Customer creation failed. ", error);
            setButtonDisabled(false);
          }
        }
      }
    }

    const createProperty = async (customer: { data: Customer }) => {
      if (task.property.isSevdeskProperty === 0) return;

      await PropertyService.GetProperty(propertyId)
        .then((property) => {
          console.log("This property exists in our backend", property.data.id);
        })
        .catch(async (error) => {
          if (error.status === 404) {
            await PropertyService.CreateProperty({
              customerId: customer.data.id.toString(),
              id: propertyId,
              customerReferenceNumber: task.property.id,
              isSevdeskProperty: true,
              settings: {
                monitorEnumerationPerArea: false,
              },
              street: task.property.street,
              postcode: task.property.postcode,
              city: task.property.city
            })
              .then((property) => {
                console.log("Property created.");
              })
              .catch((error) => {
                console.log("error", error);
                setButtonDisabled(false);
              });
          }
        });
    };

    createCustomer();
  }, []);

  const handleTechniciansClicked = () => {
    navigate("assign-technicians", {
      state: {
        firstOpen: true,
      },
    });
  };

  const handleDateChange = (date: string) => {
    dispatch(creatingTaskActions.setDate(date));
  };

  const handleTimeChange = (time: string) => {
    dispatch(creatingTaskActions.setTime(time));
  };

  const handleComment = (value: string) => {
    dispatch(creatingTaskActions.setComment({ comment: value }));
  };

  return (
    <div className={styles["container"]}>
      <AppointmentType />

      <DateTimePicker2
        onDateChange={handleDateChange}
        onTimeChange={handleTimeChange}
        date={task.date}
        time={task.time}
      />

      <CustomTextArea
        placeholder={t("AdditionalText")}
        onTextareaChange={handleComment}
        defaultValue={task.comment}
      />

      {!techniciansSelected && (
        <BlockButton
          leftIcon="plus"
          rightIcon="account"
          onClick={handleTechniciansClicked}
        >
          Verantwortlichen hinzufügen
        </BlockButton>
      )}

      {(techniciansSelected || technicians.length > 0) && (
        <span>
          <CustomCollapseWithChildren
            onLeftIconClick={() => navigate("assign-technicians")}
            label="Verantwortliche"
            defaultText="noch keine verantwortliche Person zugewiesen."
            active={technicians.length > 0}
            color="gray"
            hideLeftIcon={false}
          >
            {technicians.map((technician, i) => (
              <TechnicianListItem key={i} user={technician} selected={true} />
            ))}
          </CustomCollapseWithChildren>
        </span>
      )}
    </div>
  );
};
