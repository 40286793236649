import camera from "./camera_24px_dark.png";

type CameraIconProps = {
  color?: "dark";
};

const CAMERA_IMAGES: Record<"dark", string> = {
  dark: camera,
};

export const CameraIcon = ({ color }: CameraIconProps) => (
  <img src={CAMERA_IMAGES[color || "dark"]} alt="Camera icon" />
);
