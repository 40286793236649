import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../store";
import { creatingServiceActions } from "../../store/job-slice";

import styles from "./reason-list-item.module.css";

type ReasonListItemProps = {
  reason: string;
  onSelect: (isSelected: boolean) => void;
};

export const ReasonListItem = ({ reason, onSelect }: ReasonListItemProps) => {
  const dispatch = useDispatch();
  const reasonName = useSelector((state: RootState) => state.service.reason);
  const isSelected = reasonName === reason;

  const onClick = () => {
    if (isSelected) {
      dispatch(creatingServiceActions.setReason(""));
      onSelect(false);
    } else {
      dispatch(creatingServiceActions.setReason(reason));
      onSelect(true);
    }
  };

  return (
    <li
      className={`${styles["card"]} ${
        isSelected ? styles["card--selected"] : ""
      }`}
      onClick={onClick}
    >
      <div className={styles["card__user-info"]}>
        <span className={styles["card__user-info__name"]}>{reason}</span>
      </div>
      <div
        className={`${styles["card__button-wrapper"]} ${
          isSelected ? styles["card__button-wrapper--selected"] : ""
        }`}
      >
        <img src="/img/green-tick.png" />
      </div>
    </li>
  );
};
