import { ImageProps } from "../types/image";
import { ResponseMeta, ResponseWarningQueries } from "../types/response";
import { Technician } from "../types/user";

import ApiService from "./ApiService";

type IncludesProps = {
  documents?: ImageProps[];
};

export type TechnicianByIdResponse = {
  data: Technician;
  includes?: IncludesProps;
} & ResponseMeta &
  ResponseWarningQueries;

const TechnicianService = {
  GetTechnicianById: async (id: string): Promise<TechnicianByIdResponse> => {
    return ApiService.GetById(`${id}?include=documents`, "employees");
  },
};

export default TechnicianService;
