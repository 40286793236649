import { Infestation } from "../types/infestation";
import { ResponseMeta, ResponseWarningQueries } from "../types/response";

import ApiService from "./ApiService";

type InfestationByIdResponse = {
  data: Infestation;
} & ResponseMeta &
  ResponseWarningQueries;

const InfestationService = {
  GetInfestationById: async (id: string): Promise<InfestationByIdResponse> => {
    return ApiService.GetById(id, `infestations`);
  },
};

export default InfestationService;
