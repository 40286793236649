import { DefaultizedPieValueType } from "@mui/x-charts";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";

import styles from "./piechart.module.css";

export interface Data {
  label: string;
  value: number;
  color: string;
}

interface PieChartProps {
  title: string;
  subtitle: string;
  data: Data[];
  thicklineShown?: boolean;
}

export const PieChartComponent = ({
  title,
  subtitle,
  data,
  thicklineShown,
}: PieChartProps) => {
  const sizing = {
    margin: { right: 5 },
    width: 312,
    height: 312,
    legend: { hidden: true },
  };

  const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

  const getArcLabel = (params: DefaultizedPieValueType) => {
    if (TOTAL <= 0) return "0%";

    const percent = params.value / TOTAL;
    return `${(percent * 100).toFixed(0)}%`;
  };

  return (
    <div className={styles["piechart"]}>
      <div className={styles["piechart__container"]}>
        <div className={styles["piechart__description"]}>
          <p className={styles["piechart__title"]}>{title}</p>

          <span className={styles["piechart__subtitle"]}>{subtitle}</span>

          <div className={styles["legend-container"]}>
            {data.map((item, index) => (
              <div key={index} className={styles["legend-item"]}>
                <span
                  className={styles["legend-color"]}
                  style={{ backgroundColor: item.color }}
                />

                <span className={styles["legend-label"]}>{item.label}</span>
              </div>
            ))}
          </div>
        </div>
        <PieChart
          series={[
            {
              outerRadius: 156,
              data,
              arcLabel: getArcLabel,
            },
          ]}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: "white",
              fontSize: 14,
            },
          }}
          {...sizing}
        />
      </div>

      {thicklineShown && <hr className={styles["thickline"]} />}
    </div>
  );
};
