import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AppointmentService from "../../api/AppointmentService";
import { TrackingEventService } from "../../api/TrackingEventService";
import UserService from "../../api/UserService";
import { useAppointment } from "../../hooks/useAppointment";
import { RootState } from "../../store";
import { setAppointment } from "../../store/appointment-slice";
import { clockActions } from "../../store/clock-slice";
import { activeTabsActions } from "../../store/tabs-slice";
import { userSliceActions } from "../../store/user-slice";
import { Appointment } from "../../types/appointment";
import { calculateTotalTime } from "../../utils/calculateTotalTime";
import { AppointmentCard } from "../AppointmentCard/AppointmentCard";
import { NoData } from "../NoData/NoData";
import PullToRefresh from "../PullToRefresh/PullToRefresh";
import { SubMenu } from "../SubMenu/SubMenu";
import { Swipeable } from "../Swipeable/Swipeable";

import styles from "./dashboard-home.module.css";

const DashboardHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const appointments = useSelector((state: RootState) => state.appointments);
  const currentPage = useSelector((state: RootState) => state.tabs.dashboard);

  const { fetchAppointments } = useAppointment(
    {
      "filter[sinceDate]": moment().format("YYYY-MM-DD"),
    },
    true
  );

  useEffect(() => {
    if (currentPage.url && currentPage.url !== "/") {
      navigate(currentPage.url);
    }
  }, [currentPage.url, navigate]);

  // Check if service is running and start clock
  useEffect(() => {
    UserService.GetCurrentUser()
      .then(({ data }) => {
        dispatch(userSliceActions.setCurrentUser(data));
        const userId = data?.user?.id;

        if (userId) {
          return TrackingEventService.GetAllByEmployee(userId);
        }
      })
      .then(async (result: any) => {
        if (result.data[0]) {
          const appointmentId = result.data[0]?.appointmentId;
          const isLastServiceStopped = result.data[0]?.eventType === "stop";

          if (!isLastServiceStopped) {
            dispatch(clockActions.setAppointmentId(appointmentId));

            const response =
              await AppointmentService.GetAppointment(appointmentId);
            dispatch(setAppointment(response.data));

            return calculateTotalTime(appointmentId);
          }
        }

        return {
          totalTime: 0,
        };
      })
      .then((result) => {
        if (result.totalTime > 0) {
          // Set timer
          dispatch(clockActions.setIsRunning(true));
          dispatch(clockActions.setCurrentTime(result.totalTime));
          dispatch(clockActions.setInitialTime(result.totalTime));
        }
      })
      .catch(() => {
        localStorage.clear();
        dispatch(userSliceActions.resetState());
        navigate("/signin");
      });
  }, [dispatch, navigate]);

  const handlePullToRefresh = (callback: Function) => {
    fetchAppointments()
      .then((result) => {
        callback();
      })
      .catch((error) => {
        callback(error);
      });
  };

  const handleAppointmentClicked = (appointment: Appointment) => {
    dispatch(setAppointment(appointment));

    const isDeficiency = appointment.incidents.length > 0;

    if (isDeficiency) {
      const deficiencyId = appointment.incidents[0].id;
      navigate(`/deficiencies/${deficiencyId}/show`);
      dispatch(activeTabsActions.setPreviousPath("/"));
    } else {
      navigate(`/properties/${appointment.id}/show`);
    }
  };

  return (
    <div className={styles["dashboard-home"]}>
      <PullToRefresh
        className={styles["dashboard-home-tasks"]}
        onRefresh={handlePullToRefresh}
      >
        <div>
          <div className={styles["tasks-today__header"]}>
            <SubMenu showSearchButton={true}>
              {t("Today")}
              <span>({moment(new Date()).format("D.M.YYYY.")})</span>
            </SubMenu>
          </div>

          {/* Today's Appointments */}
          <div className={styles["tasks-today__list"]}>
            {appointments.today.length > 0 &&
              appointments.today.map((appointment) => (
                <Swipeable
                  key={`today-${appointment.id}`}
                  appointment={appointment}
                >
                  <AppointmentCard
                    appointment={appointment}
                    dateInCircle={false}
                    upcoming={false}
                    onClick={() => handleAppointmentClicked(appointment)}
                    isDashboard
                  />
                </Swipeable>
              ))}

            {appointments.today.length === 0 && (
              <NoData>{t("NoOpenOrders")} 🥳</NoData>
            )}
          </div>
        </div>

        {/* Tomorrow's Appointments */}
        {appointments.tomorrow.length > 0 && (
          <div className={styles["tasks-tomorrow"]}>
            <h3>
              {t("Morning")}{" "}
              <span>({moment().add(1, "days").format("DD.MM.YYYY.")})</span>
            </h3>

            <div className={styles["tasks-tomorrows__list"]}>
              {appointments.tomorrow.map((appointment) => (
                <Swipeable
                  key={`today-${appointment.id}`}
                  appointment={appointment}
                >
                  <AppointmentCard
                    appointment={appointment}
                    dateInCircle={false}
                    upcoming={false}
                    onClick={() => handleAppointmentClicked(appointment)}
                    isDashboard
                  />
                </Swipeable>
              ))}
            </div>
          </div>
        )}

        {appointments.future.length > 0 && (
          <div className={styles["tasks-upcoming"]}>
            <h3>{t("Upcoming")}</h3>

            <div className={styles["tasks-upcoming__list"]}>
              {appointments.future.map((appointment, i) => (
                <Swipeable key={i} appointment={appointment}>
                  <AppointmentCard
                    appointment={appointment}
                    onClick={() => handleAppointmentClicked(appointment)}
                    isDashboard
                  />
                </Swipeable>
              ))}
            </div>
          </div>
        )}
      </PullToRefresh>
    </div>
  );
};

export default DashboardHome;
