import { t } from "i18next";

import { MonitorEventByIdResponse } from "../../api/MonitorService";
import { formatServiceData } from "../../utils/formatServiseData";
import { CustomInput } from "../CustomInput/CustomInput";
import { CustomTextArea } from "../CustomTextArea/CustomTextArea";
import { ResourceImages } from "../ResourceImages/ResourceImages";

interface Props {
  event: MonitorEventByIdResponse | undefined;
}

export const UnreachableDetails = ({ event }: Props) => {
  const { images } = formatServiceData(event);

  return (
    <div>
      <CustomInput
        label={t("Reason")}
        placeholder="No reason"
        onBlur={() => {}}
        initialValue={event?.data?.reason}
        maxLength={20}
        hideTick
        readonly
      />
      <ResourceImages images={images} />
      <CustomTextArea
        placeholder={t("AdditionalText")}
        defaultValue={event?.data?.comment ?? ""}
        onTextareaChange={() => {}}
        readonly
      />
    </div>
  );
};
