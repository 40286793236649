export const lightenColor = (color: string, percent: number) => {
  const num = parseInt(color.slice(1), 16);
  const amt = Math.round(2.55 * percent);
  let R = (num >> 16) + amt;
  let G = ((num >> 8) & 0x00ff) + amt;
  let B = (num & 0x0000ff) + amt;

  R = R < 255 ? (R < 1 ? 0 : R) : 255;
  G = G < 255 ? (G < 1 ? 0 : G) : 255;
  B = B < 255 ? (B < 1 ? 0 : B) : 255;

  const newColor =
    "#" + (0x1000000 + R * 0x10000 + G * 0x100 + B).toString(16).slice(1);

  return newColor;
};
