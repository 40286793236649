import { useCallback, useEffect, useState } from "react";

import styles from "../layout/AppLayout/app-layout.module.css";

export const useInfiniteScroll = (element: HTMLElement, hasMore: boolean) => {
  const [isFetching, setIsFetching] = useState(false);

  const layout = document.getElementsByClassName(
    styles["app-layout__content"]
  )[0] as HTMLElement;
  useEffect(() => {
    layout?.addEventListener("scroll", handleScroll);
    return () => layout?.removeEventListener("scroll", handleScroll);
  }, [hasMore]);

  const handleScroll = useCallback(() => {
    const notEndOfScroll =
      window.innerHeight + layout?.scrollTop - 150 !== element?.offsetHeight;
    if (notEndOfScroll || isFetching || !hasMore) return;
    setIsFetching(true);
  }, [hasMore, isFetching]);

  return [isFetching, setIsFetching] as const;
};
