import { CheckCircle } from "@mui/icons-material";
import classNames from "classnames";
import { MouseEventHandler, ReactNode } from "react";

import styles from "./custom-toggle-select.module.css";

type CustomToggleSelectProps = {
  children: ReactNode;
  selected: boolean | undefined | null;
  onClick: MouseEventHandler<HTMLDivElement>;
  backgroundColor?: "white" | "gray";
};

const BACKGROUND_COLOR: Record<"white" | "gray", string> = {
  white: styles["toggle--white"],
  gray: styles["toggle--gray"],
};

export const CustomToggleSelect = ({
  children,
  selected,
  onClick,
  backgroundColor = "gray",
}: CustomToggleSelectProps): JSX.Element => {
  return (
    <div
      className={classNames(
        styles["toggle"],
        BACKGROUND_COLOR[backgroundColor],
        {
          [styles["toggle--active"]]: selected,
        }
      )}
      onClick={onClick}
    >
      <span>{children}</span>
      <CheckCircle />
    </div>
  );
};
