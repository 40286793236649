import {
  AccessTime,
  Analytics,
  CreateNewFolder,
  Dashboard,
  EventNote,
  Settings,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { useScreenSize } from "../../hooks/useScreenSize";
import { DefectIcon } from "../../icons/DefectIcon/DefectIcon";
import { RootState } from "../../store";

import Logo from "./desktop-logo.png";
import styles from "./navigation.module.css";

type NavigationLink = {
  to: string;
  component: JSX.Element;
  label: string;
};

const NAV_LINKS: NavigationLink[] = [
  { to: "/", component: <Dashboard />, label: "LastServices" },
  { to: "/time", component: <AccessTime />, label: "Time" },
  {
    to: "/appointments/create",
    component: <CreateNewFolder />,
    label: "EnterTask",
  },
  { to: "/calendar", component: <EventNote />, label: "Absences" },
  { to: "/settings", component: <Settings />, label: "Settings" },
];

const CUSTOMER_NAV_LINKS: NavigationLink[] = [
  { to: "/", component: <Dashboard />, label: "LastServices" },
  {
    to: "/deficiencies",
    component: <DefectIcon />,
    label: "Defects & Infestation",
  },
  { to: "/analysis", component: <Analytics />, label: "Analysis" },
  { to: "/settings", component: <Settings />, label: "Settings" },
];

export const Navigation = () => {
  const [t] = useTranslation();
  const { isDesktop } = useScreenSize();
  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );
  const getNavLinkClassName = ({ isActive, isPending }: any) =>
    isPending ? styles.pending : isActive ? styles.active : "";

  const LINKS = isCustomerAdmin ? CUSTOMER_NAV_LINKS : NAV_LINKS;

  return (
    <div className={styles["dashboard-navigation"]}>
      {isDesktop && (
        <img src={Logo} className={styles["dashboard-navigation__logo"]} />
      )}
      <div className={styles["dashboard-navigation-links"]}>
        {LINKS.map(({ to, component, label }, i) => (
          <NavLink key={i} to={to} className={getNavLinkClassName}>
            {component}
            {isDesktop && (
              <label className={styles["dashboard-navigation-links__label"]}>
                {t(label)}
              </label>
            )}
          </NavLink>
        ))}
      </div>
    </div>
  );
};
