import styles from "./deficiency-type.module.css";

interface DeficiencyTypeProps {
  type: string;
  subType: string;
}

export const DeficiencyType = ({ type, subType }: DeficiencyTypeProps) => (
  <div className={styles["deficiency-type"]}>
    <h3>{type}</h3>
    <h4>{subType}</h4>
  </div>
);
