import React, { ReactNode, createContext, useContext, useState } from "react";

import { MinimalMonitorType } from "../types/monitor";

const INITIAL_CONTEXT = {
  monitorTypes: [],
  setMonitorTypes: () => [],
  monitorProducts: [],
  setMonitorProducts: () => [],
};

interface MonitorContextType {
  monitorTypes: MinimalMonitorType[] | [];
  setMonitorTypes: React.Dispatch<
    React.SetStateAction<MinimalMonitorType[] | []>
  >;
}

type MonitorContextProviderProps = {
  children: ReactNode;
};

const MonitorContext = createContext<MonitorContextType>(INITIAL_CONTEXT);

export const MonitorContextProvider: React.FC<MonitorContextProviderProps> = ({
  children,
}) => {
  const [monitorTypes, setMonitorTypes] = useState<MinimalMonitorType[] | []>(
    []
  );

  const values = {
    monitorTypes,
    setMonitorTypes,
  };

  return (
    <MonitorContext.Provider value={values}>{children}</MonitorContext.Provider>
  );
};

export const useMonitorContext = () => useContext(MonitorContext);
