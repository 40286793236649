import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import Modal from "../../components/Modal/Modal";
import PinCropMap from "../../components/PinCropMap/PinCropMap";
import { MonitorIcon } from "../../icons/MonitorIcon/MonitorIcon";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { RootState } from "../../store";
import { adjustPinActions } from "../../store/adjust-pin-slice";
import styles from "../../styles/monitor-adjust-position.module.css";
import { Crop } from "../../types/map";



export const MonitorAdjustPosition = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const monitor = useSelector((state: RootState) => state.monitor.monitor);
    const { monitorPosition, position, pinName } = useSelector((state: RootState) => state.adjustPin);
    const monitorTypes = useSelector(
        (state: RootState) => state.monitor.monitorTypes
    );
    const [isOpen, setIsOpen] = useState(false);

    const pinAbbr = {
        name: pinName,
        number: position.toString() ?? "001",
    };

    const handleBack = () => {
        navigate(-1);
    }

    const handleCrop = (crop: Crop) => {
        dispatch(adjustPinActions.setMonitorPosition(crop));
    }

    const handleClick = () => {
        setIsOpen(true);
    }

    const redirectToAdditionalData = () => {
        dispatch(adjustPinActions.setLocation(monitor.location));
        navigate("/monitor/adjust-position-data");
    }

    return (
        <AppLayout hideHeader>
            <BackButtonHeader handleBackButtonClicked={handleBack} />
            <AppointmentDetails />
            <div className={styles["content-wrapper"]}>
                <div className={styles["content"]}>
                    <p className={styles["monitor-adjust--title"]}>
                        <MonitorIcon color="dark" />  {monitor?.monitorType} {monitor?.position}
                    </p>

                    <div className={styles["monitor-adjust--map"]}>
                        <PinCropMap
                            map={monitorTypes.map!}
                            crop={monitorPosition}
                            onCropChange={handleCrop}
                            monitorPinAbbr={pinAbbr}
                        />
                    </div>

                </div>
            </div>
            <FloatingButtons>
                <CustomButton
                    color="green"
                    icon="plus"
                    iconPosition="right"
                    onClick={handleClick}
                    width={60}
                >
                    {t("PlaceMonitor")}
                </CustomButton>
            </FloatingButtons>

            <Modal
                isOpen={isOpen}
                onCancel={() => setIsOpen(false)}
                onConfirm={redirectToAdditionalData}
                message={"Position Übernehmen?"}
            />
        </AppLayout>
    )
};