import { AccountCircleIcon } from "../../icons/AccountCircleIcon/AccountCircleIcon";

import styles from "./tab-type-selection.module.css";

type TabTypeSelectionProps = {
  label: string;
  tabs: [string, string];
  activeTab: string;
  changeActiveTab: (arg: string) => void;
  icon?: "accountDark";
  readonly?: boolean;
};

const ICONS: Record<"accountDark", JSX.Element> = {
  accountDark: <AccountCircleIcon color="dark" />,
};

export const TabTypeSelection = ({
  label,
  tabs,
  activeTab,
  changeActiveTab,
  icon,
  readonly = false,
}: TabTypeSelectionProps) => (
  <div className={styles["services__issue-type"]}>
    <div className={`${icon && styles["servicess__issue-type__title--flex"]}`}>
      <p className={styles["services__issue-type__title"]}>{label}</p>
      {icon && ICONS["accountDark"]}
    </div>

    <div className={styles["services__issue-type__buttons"]}>
      {tabs.map((tab) => (
        <div
          key={tab}
          className={`${styles["services__issue-type__button"]} ${
            activeTab === tab && styles["services__issue-type__button--active"]
          } ${readonly && styles["services__issue-type__button--readonly"]} ${
            readonly &&
            activeTab !== tab &&
            styles["services__issue-type__button--readonly--inactive"]
          }`}
          onClick={() => changeActiveTab(tab)}
        >
          {tab}
        </div>
      ))}
    </div>
  </div>
);
