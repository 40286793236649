import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

import styles from "./custom-slider.module.css";

const PrettoSlider = styled(Slider)({
  color: "#11A8B8",
  height: 3,
  width: "95%",
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 13,
    width: 13,
    backgroundColor: "#11A8B8",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 14,
    background: "unset",
    padding: 0,
    width: 35,
    height: 35,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#11A8B8",
    transformOrigin: "bottom left",
    transform: "translate(50%, -75%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -75%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
  "& .MuiSlider-mark": {
    padding: 2,
    border: "2px solid #fff",
    borderRadius: 50,
    backgroundColor: "#A0DCE3",
    "&.MuiSlider-markActive": {
      backgroundColor: "#11A8B8",
    },
  },
});

interface Props {
  value: number;
  setValue: (value: number) => void;
  readOnly?: boolean;
}

export const CustomSlider = ({ value, setValue, readOnly = false }: Props) => {
  return (
    <div className={styles["custom-slider"]}>
      <PrettoSlider
        value={value ?? 0}
        onChange={(event: Event, newValue: number | number[]) => {
          if (!readOnly) {
            setValue(newValue as number);
          }
        }}
        step={10}
        marks
        min={0}
        max={100}
        valueLabelDisplay={readOnly ? "off" : "on"}
        disabled={readOnly}
      />
      <div className={styles["custom-slider__percentage"]}>
        <span>0%</span>
        <span>{readOnly ? `${value}%` : ""}</span>
        <span>100%</span>
      </div>
    </div>
  );
};
