import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import de from "./locale/de.json";
import en from "./locale/en.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
  },
  lng: "de", // if you're using a language detector, do not define the lng option
  fallbackLng: "de",

  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
});
