import { NavigateFunction, To } from "react-router-dom";

export const returnToDashboardPage = (navigate: NavigateFunction) => {
  navigate("/");
};

export const navigateToAbsenceInsight = (
  navigate: NavigateFunction,
  absence: Absence
) => {
  navigate(`/calendar/${absence.id}/show`, { state: { absence } });
};

export const goOneStepBack = (navigate: NavigateFunction) => {
  navigate(-1);
};

export const go = (navigate: NavigateFunction, url: string, state: any) => {
  navigate(url as To, { state: { ...state } });
};

export const unloadCallback = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  event.returnValue = "";
  return "";
};
