import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "../store";
import { clockActions } from "../store/clock-slice";
import { activeTabsActions } from "../store/tabs-slice";
import { calculateTotalTime } from "../utils/calculateTotalTime";
import { handleServiceEvent } from "../utils/handleServiceEvent";

export const useHandleService = (
  setIsModalOpen: (arg: boolean) => void,
  setLoading: (arg: boolean) => void,
  setIsModalStopped: (arg: boolean) => void
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const appointment = useSelector((state: RootState) => state.appointment);
  const isRunning = useSelector((state: RootState) => state.clock.isRunning);
  const clockodoUser = useSelector(
    (state: RootState) => state.user.clockodoUser
  );
  const user = useSelector((state: RootState) => state.user.currentUser.user);

  const handleStartService = () => {
    setIsModalOpen(false);
    setLoading(true);

    handleServiceEvent(appointment, isRunning, clockodoUser, user)
      .then(() => {
        if (appointment?.id) {
          return calculateTotalTime(appointment.id);
        }

        return {
          totalTime: 0,
        };
      })
      .then(({ totalTime }) => {
        // Set timer
        dispatch(
          clockActions.setClock({
            isRunning: true,
            appointmentId: appointment?.id,
            currentTime: totalTime,
            initialTime: totalTime,
          })
        );
      })
      .catch((error) => {
        console.log("Some error happened", error);
      })
      .finally(() => setLoading(false));
  };

  const handleStopService = () => {
    setIsModalStopped(false);

    handleServiceEvent(appointment, isRunning, clockodoUser, user)
      .then(() => {
        // Reset timer
        dispatch(clockActions.resetClock());
        dispatch(activeTabsActions.setMonitorsStep(1));

        navigate(`/services/${appointment?.id}/services-overview`);
      })
      .catch((error) => console.log("Some error happened", error));
  };

  return {
    handleStartService,
    handleStopService,
  };
};
