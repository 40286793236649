import { createSlice } from "@reduxjs/toolkit";

import LocalStorageService from "../api/LocalStorageService";
import { ClockodoUser, Technician } from "../types/user";

export const ROLES = {
  ADMIN: "admin",
  TECHNICIAN: "pestix.techniker",
  CUSTOMER_ADMIN: "kunde.admin",
  CUSTOMER_TECHNICIAN: "kunde.technik",
  PROPERTY_MANAGER: "kunde.objektbetreuer"
};

export type PropertyInfo = {
  id: string;
  street: string;
  postcode: string;
  city: string;
  isSevdeskProperty: number;
  customerReferenceNumber: string;
};

type userSliceState = {
  isCheckedIn: boolean;
  clockId: number | null;
  isSignedIn: boolean;
  accessToken: any;
  profileName: string;
  clockodoUser: ClockodoUser | null;
  currentUser: Technician;
  isCustomerAdmin: boolean;
  roles: string[];
  properties: PropertyInfo[];
  selectedProperty: PropertyInfo;
  selectedPropertyId: string;
};

const loadInitialState = (): userSliceState => {
  const accessToken = LocalStorageService.get("accessToken");
  const clockodoUser = LocalStorageService.get("clockodoUser");
  const profileName = LocalStorageService.get("profileName");
  const isCheckedIn = LocalStorageService.get("checkedIn") === "true";
  const clockId = LocalStorageService.get("clockId");
  const selectedPropertyId = LocalStorageService.get("selectedPropertyId");

  return {
    isCheckedIn: isCheckedIn || false,
    clockId: clockId !== "null" ? +clockId! : null,
    isSignedIn: !!accessToken,
    accessToken: accessToken || null,
    profileName: profileName || "",
    clockodoUser: clockodoUser ? JSON.parse(clockodoUser) : null,
    isCustomerAdmin: false,
    roles: [],
    currentUser: {
      id: "",
      customerId: "",
      email: "",
      expirationDate: "",
      firstName: "",
      lastName: "",
      roles: [],
      user: {
        id: "",
        type: "",
      },
      assignedProperties: []
    },
    properties: [],
    selectedProperty: {
      id: "",
      street: "",
      postcode: "",
      city: "",
      isSevdeskProperty: 0,
      customerReferenceNumber: ""
    },
    selectedPropertyId: selectedPropertyId || "",
  };
};

const initialState: userSliceState = loadInitialState();

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsCheckedIn(state, action) {
      state.isCheckedIn = action.payload;
      LocalStorageService.set({
        checkedIn: action.payload,
      });
    },
    setClockId(state, action) {
      state.clockId = action.payload;
      LocalStorageService.set({
        clockId: action.payload,
      });
    },
    setIsSignedIn(state, action) {
      state.isSignedIn = action.payload;
    },
    setRole(state, action) {
      state.roles = action.payload;
      state.isCustomerAdmin = action.payload.includes(ROLES.CUSTOMER_ADMIN) || action.payload.includes(ROLES.PROPERTY_MANAGER);
    },
    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },
    setProfileName(state, action) {
      state.profileName = action.payload;
    },
    setClockodoUser(state, action) {
      state.clockodoUser = action.payload;
    },
    setMileage(state, action) {
      if (state.clockodoUser) {
        state.clockodoUser.lastEndingMileage = action.payload;
        LocalStorageService.set({
          clockodoUser: JSON.stringify(state.clockodoUser),
        });
      }
    },
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
    setCustomerProperties(state, action) {
      state.properties = action.payload;
    },
    setSelectedPropertyId(state, action) {
      state.selectedPropertyId = action.payload;
      LocalStorageService.set({
        selectedPropertyId: action.payload,
      });
    },
    setSelectedProperty(state, action) {
      state.selectedProperty = action.payload;
    },
    resetState() {
      return loadInitialState();
    },
  },
});

export const userSliceActions = userSlice.actions;
