import { t } from "i18next";
import { useState } from "react";

import { WarningIcon } from "../../icons/WarningIcon/WarningIcon";
import { CustomButton } from "../CustomButton/CustomButton";
import { FloatingButtons } from "../FloatingButtons/FloatingButtons";

import styles from "./no-connection-modal.module.css";

type NoConnectionModalProps = {
  onClose: () => void;
};

export const NoConnectionModal = ({
  onClose,
}: NoConnectionModalProps): JSX.Element => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleUnderstandClick = () => {
    onClose();
  };

  return (
    <div className={styles["no-connection-modal"]}>
      <div className={styles["content"]}>
        <button type="button" className={styles["warning-button"]}>
          <WarningIcon /> <span>{t("NoInternet")}</span>
        </button>
        <p className={styles["text"]}>{t("NoConnection")}</p>
        <div className={styles["checkbox"]}>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <p className={styles["checkbox__text"]}>{t("NotifyMe")}</p>
        </div>

        <FloatingButtons>
          <CustomButton color="green" onClick={handleUnderstandClick}>
            {t("Understood")}
          </CustomButton>
        </FloatingButtons>
      </div>
    </div>
  );
};
