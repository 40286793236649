import moment from "moment";
import { useEffect, useState } from "react";

import stylesPicker from "../CustomPickerV2/custom-picker.module.css";
import { CustomPicker } from "../CustomPickerV2/CustomPicker";

import styles from "./date-picker.module.css";

export const DatePicker = ({
  onDateChange,
  initialValue,
}: {
  onDateChange: (newDate: string) => void;
  initialValue: string;
}): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState<string | null>(initialValue);
  const [dateOptions, setDateOptions] = useState<string[]>([]);

  const generateDateOptions = (fromDate?: string | null) => {
    const options: string[] = [];
    const startDate = fromDate ? moment(fromDate, "D.M.YYYY") : moment();
    const threeMonthsLater = moment().add(3, "month");

    while (startDate.isSameOrBefore(threeMonthsLater, "day")) {
      options.push(startDate.format("D.M.YYYY"));
      startDate.add(1, "day").startOf("day");
    }

    return options;
  };

  const handleDateFromScroll = (index: number) => {
    const value = dateOptions[index];
    setSelectedDate(value);
    onDateChange(value);
  };

  useEffect(() => {
    const dateOptions = generateDateOptions();

    setDateOptions(dateOptions);
    if (!selectedDate) {
      setSelectedDate(dateOptions[0]);
      onDateChange(dateOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      //wait for the state to be set
      document
        .querySelector(`.${stylesPicker["selected"]}.picker`)
        ?.scrollIntoView({ block: "center", inline: "center" });
    }, 5);
  }, []);

  return (
    <div className={styles["date-picker"]}>
      <CustomPicker
        label="Datum"
        data={dateOptions}
        selected={selectedDate}
        onScroll={handleDateFromScroll}
        withIcon
        className="picker"
      />
    </div>
  );
};
