import moment from "moment";
import { useCallback, useEffect, useState } from "react";

import stylesPicker from "../CustomPickerV2/custom-picker.module.css";
import { CustomPicker } from "../CustomPickerV2/CustomPicker";

import styles from "./date-time-picker2.module.css";

type DateTimePicker2Props = {
  onTimeChange: (arg: string) => void;
  onDateChange: (arg: string) => void;
  date: string;
  time: string;
};

export const DateTimePicker2 = ({
  onTimeChange,
  onDateChange,
  date,
  time,
}: DateTimePicker2Props): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState<string | null>(date);
  const [selectedTime, setSelectedTime] = useState<string | null>(time);
  const [dateOptions, setDateOptions] = useState<string[]>([]);
  const [timeOptions, setTimeOptions] = useState<string[]>([]);
  const [globalTimeOptions, setGlobalTimeOptions] = useState<string[]>([]);
  const [timeIndex, setTimeIndex] = useState<number>(0);

  // Generate date options for the next three months
  const generateDateOptions = () => {
    const today = moment();
    const twoYearsLater = moment().add(2, "years");
    const dateOptions: string[] = [];

    while (today.isSameOrBefore(twoYearsLater, "day")) {
      dateOptions.push(today.format("D.M.YYYY"));
      today.add(1, "day").startOf("day");
    }

    return dateOptions;
  };

  //set time options from 00:00 to 23:55
  //used in handleTimeScroll() function
  const generateGlobalTimeOptions = () => {
    const timeOptions: string[] = [];
    const currentTime = moment().startOf("day");
    const endTime = moment().endOf("day");
    const incrementMinutes = 5;

    while (currentTime.isSameOrBefore(endTime)) {
      timeOptions.push(currentTime.format("HH:mm"));
      currentTime.add(incrementMinutes, "minutes");
    }

    return timeOptions;
  };

  // Generate time options based on selected date and current time
  const generateTimeOptions = useCallback(() => {
    const timeOptions: string[] = [];
    const currentTime = moment().startOf("day");
    const endTime = moment().endOf("day");
    const incrementMinutes = 5;

    while (currentTime.isSameOrBefore(endTime)) {
      const isSelectableTime =
        !selectedDate ||
        moment(selectedDate, "DD.M.YYYY").isAfter(moment(), "day") ||
        (moment(selectedDate, "DD.M.YYYY").isSame(moment(), "day") &&
          currentTime.isSameOrAfter(moment(), "minutes"));

      if (isSelectableTime) {
        timeOptions.push(currentTime.format("HH:mm"));
      }

      currentTime.add(incrementMinutes, "minutes");
    }

    return timeOptions;
  }, [selectedDate]);

  const validateTimeValue = (index: number) => {
    //cover every case with different arrays of time and be sure to get right value
    let time: string = "";
    if (
      moment(selectedDate, "D.M.YYYY").isSame(moment(), "day") &&
      selectedTime
    ) {
      if (timeOptions[timeIndex] === undefined)
        //check if the index exist in array of today date, and if selectedDate is today date scrolled from day after. This should be coverd case where previous index is 277 and since there is no 277 values in today date it will switch to last one
        time = timeOptions.find(
          (time: string) => time === globalTimeOptions[timeIndex]
        )!;
      else time = timeOptions[index];
    } else {
      time = timeOptions.find(
        (time: string) => time === globalTimeOptions[index]
      )!;
    }
    return time;
  };

  useEffect(() => {
    // Set the first date and time as selected on initial render
    if (!selectedDate && dateOptions.length > 0) {
      onDateChange(dateOptions[0]);
      setSelectedDate(dateOptions[0]);
    }
  }, [dateOptions, onDateChange, selectedDate]);

  useEffect(() => {
    if (
      !selectedTime &&
      globalTimeOptions[0] !== timeOptions[0] &&
      timeOptions.length > 0
    ) {
      onTimeChange(timeOptions[0]);
      setSelectedTime(timeOptions[0]);
    }
  }, [
    globalTimeOptions,
    onTimeChange,
    selectedTime,
    timeOptions,
    selectedDate,
  ]);

  useEffect(() => {
    // Handle adjusting selected time when date changes
    if (
      moment(selectedDate, "D.M.YYYY").isSame(moment(), "day") &&
      selectedTime &&
      moment(selectedTime, "HH:mm").isBefore(moment())
    ) {
      onTimeChange(timeOptions[0]);
      setSelectedTime(timeOptions[0]);
    }
  }, [selectedDate, selectedTime, timeOptions]);

  useEffect(() => {
    const dateOptions = generateDateOptions();
    const globalTimeOptions = generateGlobalTimeOptions();
    setDateOptions(dateOptions);
    setGlobalTimeOptions(globalTimeOptions);
    //check if there is selected time and value to avoid value reset with changing the screen
    if (!date) {
      onDateChange(dateOptions[0]);
      setSelectedDate(dateOptions[0]);
    } else {
      setSelectedDate(date);
      setSelectedTime(time);
      setTimeout(() => {
        const target = document.querySelector(
          `.${stylesPicker["selected"]}`
        ) as HTMLElement;

        const wrapper = document.getElementsByClassName(
          `${stylesPicker["scrollable-list"]}`
        )[0] as HTMLElement;

        wrapper?.scrollTo({ top: target?.offsetTop - wrapper?.offsetTop - 17 });
      }, 50);
    }
  }, []);

  useEffect(() => {
    const timeOptions = generateTimeOptions();
    setTimeOptions(timeOptions);

    //wait for state to be selected and after that scroll to selected value in time picker
    setTimeout(() => {
      const target = document.querySelector(
        `.${stylesPicker["selected"]}.time-picker`
      ) as HTMLElement;
      const wrapper = document.getElementsByClassName(
        `${stylesPicker["scrollable-list"]}`
      )[1] as HTMLElement;

      wrapper?.scrollTo({ top: target.offsetTop - wrapper.offsetTop - 17 });
    }, 50);
  }, [generateTimeOptions, selectedDate]);

  const handleDateScroll = (index: number) => {
    setSelectedDate(dateOptions[index]);
    onDateChange(dateOptions[index]);
  };

  const handleTimeScroll = (index: number) => {
    let time: string = validateTimeValue(index);

    setSelectedTime(time);
    setTimeIndex(index); //important to set value in this line, to keep previous value that is different from current selected passed as prop
    onTimeChange(time);
  };
  return (
    <div className={styles["date-time-picker"]}>
      <CustomPicker
        label="Datum"
        data={dateOptions}
        selected={selectedDate}
        onScroll={handleDateScroll}
      />
      <CustomPicker
        label="Uhrzeit"
        data={timeOptions}
        selected={selectedTime}
        onScroll={handleTimeScroll}
        className="time-picker"
      />
    </div>
  );
};
