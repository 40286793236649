import { CSSProperties, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import styles from "./spinner.module.css";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  position: "absolute",
  top: "50%",
};

export const Spinner = () => {
  let [loading] = useState(true);
  return (
    <div className={styles["spinner"]} role="status">
      <ClipLoader
        loading={loading}
        cssOverride={override}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};
