import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { MaterialsList } from "../../components/MaterialsList/MaterialsList";
import { SubMenu } from "../../components/SubMenu/SubMenu";
import { WrenchIcon } from "../../icons/WrenchIcon/WrenchIcon";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { goOneStepBack } from "../../services/NavigationService";
import { RootState } from "../../store";
import { fetchProducts } from "../../store/products-slice";
import { searchActions } from "../../store/search-slice";
import { activeTabsActions } from "../../store/tabs-slice";
import styles from "../../styles/choose-products.module.css";
import { MinimalProduct } from "../../types/product";

export const ChooseProducts = () => {
  const dispatch: ThunkDispatch<any, unknown, AnyAction> = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchedProducts, setSearchedProducts] = useState<MinimalProduct[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);

  const monitor = useSelector((state: RootState) => state.monitor);
  const products = useSelector((state: RootState) => state.products);
  const appointment = useSelector((state: RootState) => state.appointment);
  const searchValue = useSelector((state: RootState) => state.search.value);

  useEffect(() => {
    const loadProducts = async () => {
      setLoading(true);
      await dispatch(fetchProducts());
      setLoading(false);
    };

    dispatch(activeTabsActions.setDashbaordUrl(location.pathname));

    if (products.length === 0) {
      loadProducts();
    }
  }, [
    appointment,
    dispatch,
    location.pathname,
    monitor.inProgress,
    products.length,
  ]);

  useEffect(() => {
    setSearchedProducts(products);
  }, [products]);

  const handleProductsChosen = () => {
    dispatch(searchActions.setIsExpanded(false));
    dispatch(searchActions.setSearchValue(""));

    goOneStepBack(navigate);
  };

  useEffect(() => {
    if (searchValue.length >= 3) {
      const lowerCaseSearchValue = searchValue.toLowerCase();

      setSearchedProducts(
        products.filter((product: MinimalProduct) =>
          product.name?.toLowerCase().includes(lowerCaseSearchValue)
        )
      );
    } else if (searchValue.length === 0) {
      setSearchedProducts(products);
    }
  }, [searchValue, products]);

  return (
    <AppLayout hideHeader={true}>
      <div>
        <BackButtonHeader
          handleBackButtonClicked={() => goOneStepBack(navigate)}
        />
        <div className={styles["content-wrapper"]}>
          <AppointmentDetails />

          {loading ? (
            <LoadingSpinner loading={loading}></LoadingSpinner>
          ) : (
            <div className={styles["content"]}>
              <SubMenu showSearchButton={true}>
                <span>Einsatzmaterial eintragen</span>
                <WrenchIcon color="dark" />
              </SubMenu>

              <MaterialsList
                title="Arbeits-, Material- und Präparateeinsatz"
                data={searchedProducts}
              />

              <FloatingButtons>
                <CustomButton color="green" onClick={handleProductsChosen}>
                  Erledigt
                </CustomButton>
              </FloatingButtons>
            </div>
          )}
        </div>
      </div>
    </AppLayout>
  );
};
