const { REACT_APP_API_URL, REACT_APP_GOOGLE_MAPS_API_KEY, REACT_APP_SERVICES_ID, REACT_APP_CUSTOMERS_ID } = process.env;
export class ApiConfig {
  apiUrl: string;
  googleMapsKey: string;
  servicesId: number;
  customersId: number;

  constructor() {
    this.apiUrl = REACT_APP_API_URL!;
    this.googleMapsKey = REACT_APP_GOOGLE_MAPS_API_KEY!;
    this.servicesId = parseInt(REACT_APP_SERVICES_ID!);
    this.customersId = parseInt(REACT_APP_CUSTOMERS_ID!);
  }
}
const config = new ApiConfig();
export default config;
