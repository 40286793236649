import { t } from "i18next";
import moment from "moment";

import { Status } from "../../types/absence";

import styles from "./approval-status.module.css";

type Props = {
  info: {
    by: {
      name: string;
    };
    date: string;
  };
  status: Status;
};

const STATUS: Record<Status, "reported" | "approved" | "declined"> = {
  "enquired/reported": "reported",
  approved: "approved",
  declined: "declined",
  "approval cancelled": "declined",
  "request cancelled": "declined",
};

const LABEL: Record<string, string> = {
  approved: "Genehmigt von",
  declined: "Abgelehnt von",
  "approval cancelled": "Abgelehnt von",
  "request cancelled": "Abgelehnt von",
};

export const ApprovalStatus = ({ info, status }: Props) => {
  const { by, date } = info;
  const formattedDate = moment(date).format("DD.MM.YYYY");

  return (
    <div
      className={`${styles["card"]} ${
        STATUS[status] === "declined" && styles["card--declined"]
      }`}
    >
      <div className={styles["issued-by"]}>
        <h4>{LABEL[status]}</h4>
        <h3>{by.name ?? t("N/A")}</h3>
      </div>

      <div className={styles["date-approved"]}>
        <h4>am</h4>
        <h3>{date && formattedDate}</h3>
      </div>
    </div>
  );
};
