import layersDark from "./layers_24px_dark.png";

type LayersIconProps = {
  color?: "dark";
};

const LAYERS_IMAGES: Record<"dark", string> = {
  dark: layersDark,
};

export const LayersIcon = ({ color }: LayersIconProps) => (
  <img src={LAYERS_IMAGES[color || "dark"]} alt="Layers icon" />
);
