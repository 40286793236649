import { East } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "../../store";
import { MonitorTypes, creatingMonitorActions } from "../../store/monitor-slice";
import { MonitorService } from "../../types/monitor";
import { CardWrapper } from "../CardWrapper/CardWrapper";
import { MonitorTypeListItem } from "../MonitorTypeListItem/MonitorTypeListItem";
import styles from "../PropertyAreaMonitorsCard/property-area-monitors-card.module.css";

interface Props {
  dataMonitor: MonitorTypes;
  isServiceStarted?: boolean;
  startService: () => void;
}

export const MonitorTypesCard = ({
  dataMonitor,
  isServiceStarted,
  startService,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const servicedMonitorsRedux = useSelector(
    (state: RootState) => state.servicedMonitors
  );

  const handleNavigation = (monitor: MonitorService) => {
    if (isServiceStarted) {
      dispatch(creatingMonitorActions.setMonitorInfoForServices(monitor));
      navigate(`/monitors/status`);
    } else {
      startService();
    }
  };

  const Title = () => (
    <>
      <b>{dataMonitor.monitorType} </b> {dataMonitor.propertyArea}
    </>
  );

  return (
    <CardWrapper title={<Title />} color="white">
      {dataMonitor.monitors?.map((monitor: MonitorService, i: number) => (
        <MonitorTypeListItem key={i} onClick={() => handleNavigation(monitor)}>
          <div className={styles["list__card--monitortype"]}>
            {servicedMonitorsRedux.servicedMonitors?.includes(monitor.id) ? (
              <img alt="" src="/img/green-tick.png" />
            ) : (
              <img alt="" src="/img/monitor-check.svg" />
            )}
            <span className={styles["list__card--monitortype__span"]}>
              {monitor.monitorType} {monitor.position}, {monitor.location}
            </span>
          </div>
          <East />
        </MonitorTypeListItem>
      ))}
    </CardWrapper>
  );
};
