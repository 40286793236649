import DashboardHome from "../../components/DashboardHome/DashboardHome";
import { AppLayout } from "../../layout/AppLayout/AppLayout";

const Dashboard: React.FC = () => {
  return (
    <AppLayout>
      <DashboardHome />
    </AppLayout>
  );
};

export default Dashboard;
