import moment from "moment";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import JobService from "../../api/JobService";
import { useAppointment } from "../../hooks/useAppointment";
import { RootState } from "../../store";
import { creatingMonitorActions } from "../../store/monitor-slice";
import { servicedMonitorsActions } from "../../store/serviced-monitors";
import { activeTabsActions } from "../../store/tabs-slice";
import { Document } from "../../types/document";
import { Event } from "../../types/event";
import { MonitorService } from "../../types/monitor";
import { MonitorTypesCard } from "../MonitorTypesCard/MonitorTypesCard";
import { NoData } from "../NoData/NoData";
import { PropertyAreaMonitorsCard } from "../PropertyAreaMonitorsCard/PropertyAreaMonitorsCard";
import PullToRefresh from "../PullToRefresh/PullToRefresh";

interface PropertyAreaMonitorsProps {
  readonly?: boolean;
  monitors: any[];
  isServiceStarted?: boolean;
  startService: () => void;
  isServicesOverview?: boolean;
  jobId: string;
  infestationCountPerArea?: { [key: string]: number };
  eventType?: "infestation" | "noInfestation" | "maintenance" | "unreachable";
}

type ServicedMonitorsItem = {
  [propertyArea: string]: { [monitorType: string]: number };
};

export const PropertyAreaMonitors = ({
  readonly,
  monitors,
  isServiceStarted,
  jobId,
  startService,
  isServicesOverview,
  infestationCountPerArea,
  eventType,
}: PropertyAreaMonitorsProps) => {
  const { appointmentId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { fetchAppointments } = useAppointment(
    {
      "filter[sinceDate]": moment().format("YYYY-MM-DD"),
    },
    true
  );

  const monitorsStep = useSelector(
    (state: RootState) => state.tabs.monitors.step
  );
  const monitorTypes = useSelector(
    (state: RootState) => state.monitor.monitorTypes
  );

  const onPropertyAreaClick = (monitor: any) => {
    navigate(`/services/${appointmentId}/services-overview/step2`, {
      state: { monitor, readonly },
    });
  };

  const handleNextStep = (monitorType: string, propertyArea: string, map: Document, propertyAreaId: string) => {
    dispatch(
      creatingMonitorActions.setMonitorTypes({
        monitorType: monitorType,
        propertyArea: propertyArea,
        map: map,
        propertyAreaId: propertyAreaId,
        monitors: monitors
          .find((monitor: any) => monitor.propertyArea === propertyArea)
          ?.monitors?.filter(
            (monitor: MonitorService) => monitor.monitorType === monitorType
          )
          .sort(
            (a: { position: number }, b: { position: number }) =>
              a.position - b.position
          ),
      })
    );
    dispatch(activeTabsActions.setMonitorsStep(2));
  };

  const fetchServicedMonitors = useCallback(async () => {
    if (jobId) {
      const { data } = await JobService.GetGroupedEventsByJob(jobId);
      const updatedServicedMonitorsCountByPropertyArea: ServicedMonitorsItem =
        {};

      monitors.forEach((monitorData: any) => {
        const { propertyArea, monitors } = monitorData;

        if (!updatedServicedMonitorsCountByPropertyArea[propertyArea]) {
          updatedServicedMonitorsCountByPropertyArea[propertyArea] = {};

          monitors.forEach((monitor: MonitorService) => {
            updatedServicedMonitorsCountByPropertyArea[propertyArea][
              monitor.monitorType
            ] = 0;
          });
        }

        monitors.forEach((monitor: MonitorService) => {
          const areaEvents = data.find(
            (item: any) => item.label === propertyArea
          );
          if (areaEvents) {
            const allEvents: Event[] = []
              .concat(
                areaEvents.events?.infestation,
                areaEvents.events?.unreachableOrMissing,
                areaEvents.events?.maintenancePerformed,
                areaEvents.events?.noInfestation
              )
              .filter(Boolean)
              .flat();
            const filteredEvents = allEvents.filter(
              (event: Event) => event.monitorId === monitor.id
            );
            if (filteredEvents.length > 0) {
              dispatch(servicedMonitorsActions.setServicedMonitors(monitor.id));
              dispatch(
                servicedMonitorsActions.setMonitorEvents({
                  id: filteredEvents[0].id,
                  monitorId: monitor.id,
                })
              );

              const distinctMonitorIds = Array.from(
                new Set(filteredEvents.map((event: any) => event.monitorId))
              );

              const propertyTypeCounts =
                updatedServicedMonitorsCountByPropertyArea[propertyArea];

              updatedServicedMonitorsCountByPropertyArea[propertyArea] = {
                ...propertyTypeCounts,
                [monitor.monitorType]:
                  (propertyTypeCounts[monitor.monitorType] || 0) +
                  distinctMonitorIds.length,
              };
            }
          }
        });
      });

      dispatch(
        servicedMonitorsActions.setServicedMonitorsCount(
          updatedServicedMonitorsCountByPropertyArea
        )
      );
    }
  }, [monitors, jobId, dispatch]);

  useEffect(() => {
    fetchServicedMonitors();
  }, [fetchServicedMonitors]);

  const handlePullToRefresh = (callback: Function) => {
    fetchAppointments()
      .then((result) => {
        callback();
      })
      .catch((error) => {
        callback(error);
      });
  };

  return (
    <PullToRefresh onRefresh={handlePullToRefresh}>
      {monitorsStep === 1 ? (
        monitors.length > 0 ? (
          monitors.map((monitor, i: number) => (
            <PropertyAreaMonitorsCard
              key={i}
              dataMonitor={monitor}
              onClick={handleNextStep}
              isServicesOverview={isServicesOverview}
              infestationCount={
                infestationCountPerArea &&
                infestationCountPerArea[monitor.propertyArea]
              }
              onPropertyAreaClick={() => onPropertyAreaClick(monitor)}
              eventType={eventType}
            />
          ))
        ) : (
          <NoData>{t("NoMonitors")}</NoData>
        )
      ) : (
        <MonitorTypesCard
          dataMonitor={monitorTypes}
          startService={startService}
          isServiceStarted={isServiceStarted}
        />
      )}
    </PullToRefresh>
  );
};
