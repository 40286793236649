import { East } from "@mui/icons-material";
import { useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../store";
import { Document } from "../../types/document";
import { MonitorService } from "../../types/monitor";
import { CardWrapper } from "../CardWrapper/CardWrapper";
import { MonitorTypeListItem } from "../MonitorTypeListItem/MonitorTypeListItem";

import styles from "./property-area-monitors-card.module.css";

type PropertyAreaMonitorsCardProps = {
  dataMonitor: {
    propertyArea: string;
    propertyAreaId: string;
    monitors: MonitorService[];
    map: Document;
  };
  onClick: (monitorType: string, propertyArea: string, map: Document, propertyAreaId: string) => void;
  isServicesOverview?: boolean;
  infestationCount?: number;
  onPropertyAreaClick?: () => void;
  eventType?: "infestation" | "noInfestation" | "maintenance" | "unreachable";
};

export const PropertyAreaMonitorsCard = ({
  dataMonitor,
  onClick,
  isServicesOverview,
  infestationCount,
  onPropertyAreaClick,
  eventType,
}: PropertyAreaMonitorsCardProps) => {
  const [collapsed] = useState(false);

  const servicedMonitorsRedux = useSelector(
    (state: RootState) => state.servicedMonitors
  );

  const groupedMonitors = dataMonitor.monitors.reduce(
    (arr: any, monitor: MonitorService) => {
      const { monitorType } = monitor;

      if (!arr[monitorType]) {
        arr[monitorType] = {
          monitorType,
          count: 1,
        };
      } else {
        arr[monitorType].count++;
      }

      return arr;
    },
    {}
  );

  const groupedMonitorsArray = Object.values(groupedMonitors);

  return (
    <CardWrapper
      collapsed={collapsed}
      title={dataMonitor.propertyArea}
      color={isServicesOverview ? "gray" : "white"}
      service={isServicesOverview ? "infestation" : eventType}
      infestationCount={infestationCount ?? 0}
      onClick={onPropertyAreaClick}
    >
      {groupedMonitorsArray.map((monitor: any, i: number) => (
        <MonitorTypeListItem
          key={i}
          onClick={
            !isServicesOverview
              ? () => onClick(monitor.monitorType, dataMonitor.propertyArea, dataMonitor.map, dataMonitor.propertyAreaId)
              : undefined
          }
          isServicesOverview={isServicesOverview}
        >
          <div className={styles["list__card--monitortype"]}>
            <span>
              {servicedMonitorsRedux.servicedMonitorsCount &&
                servicedMonitorsRedux.servicedMonitorsCount[
                dataMonitor.propertyArea
                ] &&
                servicedMonitorsRedux.servicedMonitorsCount[
                dataMonitor.propertyArea
                ][monitor.monitorType]
                ? servicedMonitorsRedux.servicedMonitorsCount[
                dataMonitor.propertyArea
                ][monitor.monitorType]
                : 0}
              /{monitor.count}
            </span>
            <span>{monitor.monitorType}</span>
          </div>
          {!isServicesOverview ? <East /> : null}
        </MonitorTypeListItem>
      ))}
    </CardWrapper>
  );
};
