import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../store";
import { searchActions } from "../../store/search-slice";
import Search from "../Search/Search";

import styles from "./sub-menu.module.css";

type SubMenuProps = {
  children: ReactNode;
  showSearchButton: boolean;
  stretched?: boolean;
};

export const SubMenu = ({
  children,
  showSearchButton,
  stretched = false,
}: SubMenuProps): JSX.Element => {
  const isSearchExpanded = useSelector(
    (state: RootState) => state.search.isExpanded
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(searchActions.setIsExpanded(false));
      dispatch(searchActions.setSearchValue(""));
    };
  }, []);

  return (
    <div className={styles["sub-menu"]}>
      <h3
        className={`${styles["sub-menu__label"]} ${
          stretched && styles["sub-menu__label--stretched"]
        } ${isSearchExpanded && styles["sub-menu__label--hide"]}`}
      >
        {children}
      </h3>
      {showSearchButton && <Search />}
    </div>
  );
};
