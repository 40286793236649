import { useTranslation } from "react-i18next";

import { InfestationPest } from "../../types/infestation";
import { formatServiceData } from "../../utils/formatServiseData";
import { CustomSlider } from "../CustomSlider/CustomSlider";
import { MaterialsList } from "../MaterialsList/MaterialsList";
import NoProductDocumentsModal from "../Modal/NoProductDocumentsModal";
import { ResourceImages } from "../ResourceImages/ResourceImages";

import styles from "./infestation-details.module.css";

interface Props {
  event: any;
  infestations: InfestationPest[] | undefined;
}

export const InfestationDetails = ({ event, infestations }: Props) => {
  const { t } = useTranslation();
  const { images, products } = formatServiceData(event);

  return (
    <div>
      <NoProductDocumentsModal />

      <label className={styles["create-floor__button"]}>
        <span>{t("TypeIssue")}</span>
      </label>
      <div className={styles["services__issue-type"]}>
        <div className={styles["services__issue-type__buttons"]}>
          <div
            className={`${styles["services__issue-type__button"]} ${
              event?.data?.infestation === "baitEaten" &&
              styles["services__issue-type__button--active"]
            }`}
          >
            {t("BaitEaten")}
          </div>

          <div
            className={`${styles["services__issue-type__button"]} ${
              event?.data?.infestation === "animalFound" &&
              styles["services__issue-type__button--active"]
            }`}
          >
            {t("AnimalFound")}
          </div>
        </div>
        {event?.data.infestation === "baitEaten" && (
          <div className={styles["slider"]}>
            <span>{t("PercentageOfBaitEaten")}</span>
            <CustomSlider
              value={event?.data.eatenQuantity ?? 0}
              setValue={() => {}}
              readOnly
            />
          </div>
        )}
      </div>

      <div className={`${styles["services__issue-type__infestation"]}`}>
        <p className={`${styles["issue-type__infestation-title"]}`}>
          {t("InfestationType")}
        </p>
        {infestations?.map((infestation: InfestationPest) => (
          <p className={`${styles["issue-type__infestation-item"]}`}>
            <span>{infestation.name}</span>
            <span>{infestation.pivot?.quantity}</span>
          </p>
        ))}
      </div>

      {products.length > 0 ? (
        <div className={styles.materials}>
          <MaterialsList title={t("Materials")} data={products} readOnly />
        </div>
      ) : null}

      <ResourceImages images={images} />
    </div>
  );
};
