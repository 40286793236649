import { now } from "moment";

import BackEndService from "../api/BackEndService";
import { generateUUIDFromID } from "../services/UUIDGeneratorService";
import { Document } from "../types/document";

type PropertyAreaProps = {
  id: string;
};

export const uploadMap = (
  event: React.ChangeEvent<HTMLInputElement>,
  propertyArea: PropertyAreaProps,
  onMapSet: (mapUrl: Document) => void
) => {
  const files = event.target.files;
  if (files && files.length > 0) {
    // Handle the selected image(s) here
    const selectedImage = files[0];

    const formData = new FormData();
    formData.append("uuid", generateUUIDFromID(now().toString()));
    formData.append("name", propertyArea.id);
    formData.append("file", selectedImage);

    BackEndService.UploadImage(formData)
      .then((image: any) => {
        BackEndService.AddDocument({
          documentType: "map",
          fileName: propertyArea.id + "-map",
          isInternal: true,
          media: image.uuid,
          attachedTo: {
            type: "PropertyArea",
            id: propertyArea.id,
          },
        })
          .then((document: any) => {
            onMapSet(document.data);
          })
          .catch((error) => {
            console.log("error", error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }
};
