import { CheckCircle } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { MonitorService } from "../../types/monitor";

import styles from "./service-info-box.module.css";

interface Props {
  data?: MonitorService;
  typeOfService: string;
  color?: string;
  hideCheck?: boolean;
}
export const ServiceInfoBox = ({
  data,
  typeOfService,
  color,
  hideCheck = false,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${styles["services__info-box"]} ${
        styles[`info-box-${color}`]
      }`}
    >
      <div className={styles["services__info-box--column"]}>
        <p className={styles["services__info-box--text"]}>
          <b>
            {data?.monitorType} {data?.position}
          </b>
        </p>
        <p className={styles["services__info-box--text"]}>{data?.location}</p>
        <p
          className={`${styles["services__info-box--tag"]} ${
            styles[`box-tag-${color}`]
          }`}
        >
          {t(typeOfService)}
        </p>
      </div>
      {!hideCheck && <CheckCircle />}
    </div>
  );
};
