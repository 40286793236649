import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { creatingTaskActions } from "../../store/task-slice";
import stylesPicker from "../CustomPickerV2/custom-picker.module.css";
import { CustomPicker } from "../CustomPickerV2/CustomPicker";

import styles from "./date-from-and-to-picker.module.css";

export const DateFromAndToPicker = ({
  onDateFromChange,
  onDateToChange,
  isHalfDay,
}: {
  onDateFromChange: (newDateFrom: string) => void;
  onDateToChange: (newDateTo: string) => void;
  isHalfDay: boolean | undefined;
}): JSX.Element => {
  const [selectedDateFrom, setSelectedDateFrom] = useState<string | null>(null);
  const [selectedDateTo, setSelectedDateTo] = useState<string | null>(null);
  const [dateFromOptions, setDateFromOptions] = useState<string[]>([]);
  const [dateToOptions, setDateToOptions] = useState<string[]>([]);
  const dispatch = useDispatch();

  const generateDateOptions = (fromDate?: string | null) => {
    const options: string[] = [];
    const startDate = fromDate ? moment(fromDate, "D.M.YYYY") : moment();
    const twoYearsLater = moment().add(2, "years");

    while (startDate.isSameOrBefore(twoYearsLater, "day")) {
      options.push(startDate.format("D.M.YYYY"));
      startDate.add(1, "day").startOf("day");
    }

    return options;
  };

  const handleDateFromScroll = (index: number) => {
    const value = dateFromOptions[index];
    setSelectedDateFrom(value);
    dispatch(creatingTaskActions.setDate({ date: value }));

    if (selectedDateTo && moment(value).isAfter(selectedDateTo, "day")) {
      setSelectedDateTo(value);
      dispatch(creatingTaskActions.setDate({ date: value }));
    }
    onDateFromChange(value);
  };

  const handleDateToScroll = (index: number) => {
    const value = dateToOptions[index];
    setSelectedDateTo(value);
    dispatch(creatingTaskActions.setDate({ value: value }));

    if (selectedDateFrom && moment(value).isBefore(selectedDateFrom, "day")) {
      setSelectedDateFrom(value);
      dispatch(creatingTaskActions.setDate({ date: value }));
    }
    onDateToChange(value);
  };

  useEffect(() => {
    const dateOptions = generateDateOptions();

    setDateFromOptions(dateOptions);
    setSelectedDateFrom(dateOptions[0]);
    onDateFromChange(dateOptions[0]);

    setDateToOptions(dateOptions);
    setSelectedDateTo(dateOptions[0]);
    onDateToChange(dateOptions[0]);
  }, []);

  useEffect(() => {
    const dateOptions = generateDateOptions(selectedDateFrom);
    setDateToOptions(dateOptions);
    setSelectedDateTo(dateOptions[0]);
    onDateToChange(dateOptions[0]);
    setTimeout(() => {
      //wait for the state to be set
      document
        .querySelector(`.${stylesPicker["selected"]}.date-to-picker`)
        ?.scrollIntoView({ block: "center", inline: "center" });
    }, 5);
  }, [selectedDateFrom]);

  useEffect(() => {
    if (isHalfDay) {
      setSelectedDateTo(selectedDateFrom);
      dispatch(creatingTaskActions.setDate({ value: selectedDateFrom }));
      setTimeout(() => {
        //wait for the state to be set
        document
          .querySelector(`.${stylesPicker["selected"]}.date-to-picker`)
          ?.scrollIntoView({ block: "center", inline: "center" });
      }, 5);
    }
  }, [isHalfDay]);

  return (
    <div className={styles["date-from-and-to-picker"]}>
      <CustomPicker
        label="Am/Ab"
        data={dateFromOptions}
        selected={selectedDateFrom}
        onScroll={handleDateFromScroll}
        background="dark"
      />
      <CustomPicker
        label="Bis (optional)"
        data={dateToOptions}
        selected={selectedDateTo}
        onScroll={handleDateToScroll}
        background="dark"
        className="date-to-picker"
        disable={isHalfDay}
      />
    </div>
  );
};
