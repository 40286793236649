import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import version from "../../../package.json";
import AuthService from "../../api/AuthService";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { Info } from "../../components/Info/Info";
import { NoData } from "../../components/NoData/NoData";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { RootState } from "../../store";
import { resetState } from "../../store/appointment-slice";
import { clockActions } from "../../store/clock-slice";
import { customerDeficienciesActions } from "../../store/customer-deficiencies-slice";
import { overviewAppointmentSliceActions } from "../../store/overview-appointment-slice";
import { activeTabsActions } from "../../store/tabs-slice";
import { userSliceActions } from "../../store/user-slice";
import styles from "../../styles/settings.module.css";

export const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const { isOnline } = useSelector((state: RootState) => state.online);

  useEffect(() => {
    if (isOnline) {
      setError("");
    }
  }, [isOnline]);

  const handleSignOut = () => {
    if (!isOnline) {
      setError(() => t("NoInternet"));
      return;
    }

    AuthService.SignOut()
      .then(() => {
        localStorage.clear();
        dispatch(userSliceActions.resetState());
        dispatch(clockActions.resetClock());
        dispatch(customerDeficienciesActions.resetState());
        dispatch(activeTabsActions.setDashbaordUrl(""));
        dispatch(overviewAppointmentSliceActions.resetState());
        dispatch(resetState());
        navigate("/");
      })
      .catch(() => {
        setError(() => t("Error"));
      });
  };

  return (
    <AppLayout>
      <div className={styles["container"]}>
        <NoData>{t("UnderConstruction")}</NoData>
        {error && <Info type="error">{error}</Info>}

        <span className={styles.version}>{version.version}</span>

        <FloatingButtons>
          <CustomButton color="gray" onClick={handleSignOut}>
            {t("SignOut")}
          </CustomButton>
        </FloatingButtons>
      </div>
    </AppLayout>
  );
};
