import axios from "axios";

import globalRouter from "../utils/globalRouter";

import LocalStorageService from "./LocalStorageService";

let calledOnce = false;

axios.interceptors.request.use(
  (request) => {
    // All requests need a bearer token except for login requests
    if (
      !request.url.includes("/api/auth") ||
      request.url.includes("/api/auth/logout")
    ) {
      const token = LocalStorageService.get("accessToken");

      if (token) {
        request.headers["Authorization"] = "Bearer " + token;
      }
    }
    return request;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    const excludedEndpointPattern =
      /\/sevdesk\/customers\/\d+\/communication-ways\?type=EMAIL/;

    if (
      error.response?.status === 401 &&
      !excludedEndpointPattern.test(originalRequest.url)
    ) {
      LocalStorageService.clear();
      globalRouter.navigate("/signin");
      return Promise.reject(error);
    }

    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      !calledOnce
    ) {
      originalRequest._retry = true;
      calledOnce = true;
    }
    return Promise.reject(error);
  }
);
