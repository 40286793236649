import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import AnalysisService, { AnalysisQueryParams } from "../api/AnalysisService";
import { Error } from "../api/ApiService";
import { Data } from "../components/PieChart/PieChart";
import { RootState } from "../store";
import { lightenColor } from "../utils/pieChartColorChanging";

const typeCustomerVisitMappings: {
  [key: string]: { label: string; color: string };
} = {
  monitoring: { label: "Monitoring", color: "#15A8FB" },
  auftrag: { label: "Bekämpfung", color: "#CB3844" },
  mangelauftrag: { label: "Mangelauftrag", color: "#F29100" },
};

const typeDeficiencyMappings: {
  [key: string]: { label: string; color: string };
} = {
  facility: { label: "Baulich", color: "#15A8FB" },
  hygienic: { label: "Hygienisch", color: "#0033E9" },
  organizational: { label: "Organisatorische", color: "#F29100" },
  other: { label: "sonstige", color: "#F9587F" },
};

const baseColors = ["#15A8FB", "#F29100", "#0033E9"];

const colorPool: string[] = baseColors.flatMap((color) => [
  color,
  lightenColor(color, 20),
]);

export interface PestCount {
  month: string;
  pestCounts: { pest: string; count: number }[];
}

export const useAnalysis = () => {
  const [t] = useTranslation();

  const [appointmentAnalysisData, setAppointmentAnalysisData] = useState<
    Data[]
  >([]);
  const [deficiencyAnalysisData, setDeficiencyAnalysisData] = useState<Data[]>(
    []
  );
  const [infestationAnalysisData, setInfestationAnalysisData] = useState<
    Data[]
  >([]);
  const [countAppointment, setCountAppointment] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const selectedPropertyId = useSelector(
    (state: RootState) => state.user.selectedPropertyId
  );

  const newQueryParams: AnalysisQueryParams = {
    "filter[propertyId]": selectedPropertyId,
    "filter[sinceDate]": "",
    "filter[untilDate]": "",
  };
  const [pestCounts, setPestCounts] = useState<PestCount[]>([]);

  const mapData = (
    responseData: any,
    typeMappings: any,
    isInfestation: boolean,
    colorPool?: string[]
  ) => {
    if (isInfestation) {
      return Object.entries(responseData || {})
        .filter(([pest]) => !pest.startsWith("unknownPest"))
        .map(([label, count]: [string, any], index) => ({
          label: typeMappings[label] || label,
          value: count,
          color:
            label === "noInfestation"
              ? "#808080"
              : colorPool
              ? colorPool[index % colorPool.length]
              : typeMappings[responseData.type].color,
        }))
        .filter((item: any) => item.value !== 0);
    } else {
      return responseData
        .map((dataItem: any) => ({
          label: typeMappings[dataItem.type].label,
          value: dataItem.count,
          color: typeMappings[dataItem.type].color,
        }))
        .filter((item: any) => item.value !== 0);
    }
  };

  const fetchCustomerVisitsData = useCallback(async () => {
    setLoading(true);

    try {
      const response = await AnalysisService.GetAnalysis(
        "appointment",
        newQueryParams
      );

      const mappedData = mapData(
        response.data?.appointments,
        typeCustomerVisitMappings,
        false
      );

      setAppointmentAnalysisData(mappedData);
    } catch (error) {
      const err = error as Error;
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [selectedPropertyId, typeCustomerVisitMappings, setLoading]);

  const fetchDeficiencyData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await AnalysisService.GetAnalysis(
        "deficiency",
        newQueryParams
      );

      const mappedData = mapData(
        response.data?.deficiencies,
        typeDeficiencyMappings,
        false
      );

      setDeficiencyAnalysisData(mappedData);
    } catch (error) {
      const err = error as Error;
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [selectedPropertyId, typeDeficiencyMappings, setLoading]);

  const fetchInfestationData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await AnalysisService.GetAnalysis("infestation", {
        "filter[propertyId]": selectedPropertyId,
      });

      const mappedData = mapData(
        response.data?.pestCount,
        { noInfestation: t("NoInfestation") },
        true,
        colorPool
      );

      setInfestationAnalysisData(mappedData);
      setCountAppointment(response.data?.totalAppointments);
    } catch (error) {
      const err = error as Error;
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [selectedPropertyId, setLoading, setCountAppointment]);

  const fetchPestAnalysis = useCallback(async () => {
    setLoading(true);
    try {
      const response = await AnalysisService.GetPestAnalysis({
        "filter[propertyId]": selectedPropertyId,
      });

      const months = Object.keys(response.data.pestCount);

      const pestCountsByMonth: PestCount[] = months.map((month) => {
        const pestCounts = Object.entries(response.data.pestCount[month])
          .filter(([pest]) => !pest.startsWith("unknownPest"))
          .map(([pest, count]) => ({
            pest,
            count: typeof count === "number" ? count : 0,
          }));

        return { month, pestCounts };
      });
      const filteredData: PestCount[] = pestCountsByMonth.filter(
        (item) => Array.isArray(item.pestCounts) && item.pestCounts.length !== 0
      );
      setPestCounts(filteredData);
    } catch (error) {
      const err = error as Error;
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [selectedPropertyId, setPestCounts, setLoading]);

  useEffect(() => {
    setAppointmentAnalysisData([]);
    setDeficiencyAnalysisData([]);
    setInfestationAnalysisData([]);
    setCountAppointment(0);
    setPestCounts([]);
    setError(null);
  }, [selectedPropertyId]);

  useEffect(() => {
    fetchCustomerVisitsData();
    fetchDeficiencyData();
    fetchInfestationData();
    fetchPestAnalysis();
  }, [
    selectedPropertyId,
    fetchCustomerVisitsData,
    fetchDeficiencyData,
    fetchInfestationData,
    fetchPestAnalysis,
  ]);

  return {
    appointmentAnalysisData,
    deficiencyAnalysisData,
    infestationAnalysisData,
    pestCounts,
    countAppointment,
    loading,
    error,
  };
};
