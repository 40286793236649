import { useTranslation } from "react-i18next";

import { Deficiencies } from "../../../components/Deficiencies/Deficiencies";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { DefectIcon } from "../../../icons/DefectIcon/DefectIcon";
import { AppLayout } from "../../../layout/AppLayout/AppLayout";
import styles from "../../../styles/customer-deficiencies.module.css";

export const CustomerDeficiencies = () => {
  const [t] = useTranslation();
  const { isDesktop } = useScreenSize();

  return (
    <AppLayout>
      <div className={styles["customer-deficiencies"]}>
        <div className={styles["customer-deficiencies__heading"]}>
          <p className={styles["customer-deficiencies__title"]}>
            {isDesktop && <DefectIcon />} {t("Defects & Infestation")}
          </p>

          {/* This needs to be hidden for now and will be adjusted later */}
          {/* {isDesktop && <div className={styles["customer-deficiencies__create-deficiency"]}>
                        <p>{t("CreateDeficiency")}</p>
                        <CustomButton
                            color="gray"
                            icon="defect"
                            onClick={handleCreateDeficiency}
                            width={36}
                        />
                    </div>} */}
        </div>
        <Deficiencies />

        {/* This needs to be hidden for now and will be adjusted later */}
        {/* {!isDesktop && <FloatingButtons>
                    <CustomButton
                        color="gray"
                        icon="defect"
                        onClick={handleCreateDeficiency}
                    >
                        Befall melden
                    </CustomButton>
                </FloatingButtons>} */}
      </div>
    </AppLayout>
  );
};
