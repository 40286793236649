import { Search as SearchIcon } from "@mui/icons-material";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../store";
import { searchActions } from "../../store/search-slice";

import styles from "./search.module.css";

const Search = () => {
    const dispatch = useDispatch();
    const inputRef = useRef<HTMLInputElement>(null);
    const { isExpanded, value } = useSelector((state: RootState) => state.search);

    const handleButtonClick = () => {
        dispatch(searchActions.setIsExpanded(!isExpanded));
        dispatch(searchActions.setSearchValue(""));
    };

    // const handleOutsideClick = (event: any) => {
    //     if (!event.target.closest(`.${styles['search-container']}`)) {
    //         // dispatch(searchActions.setIsExpanded(false));
    //         // dispatch(searchActions.setSearchValue(""));
    //     }
    // };

    // useEffect(() => {
    //     document.addEventListener('click', handleOutsideClick);
    //     return () => {
    //         document.removeEventListener('click', handleOutsideClick);
    //     };
    // }, []);

    useEffect(() => {
        if (isExpanded && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isExpanded]);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(searchActions.setSearchValue(event.target.value));
    }

    return (
        <div className={styles["search-container"]} style={{ width: isExpanded ? "100%" : "fit-content" }}>
            <button className={`${styles["search-button"]} ${isExpanded ? styles["expanded"] : ""}`} onClick={handleButtonClick}>
                <SearchIcon />
            </button>
            <input
                ref={inputRef}
                type="text"
                className={`${styles["search-input"]} ${isExpanded ? styles["expanded"] : ""}`}
                onChange={handleSearch}
                value={value}
            />
        </div>
    );
};

export default Search;