import { createSlice } from "@reduxjs/toolkit";

type WorkersSliceState = {
  totalActive: number;
  active: any[];
  absent: any[];
  notCheckedIn: any[];
};

const initialState: WorkersSliceState = {
  totalActive: 0,
  active: [],
  absent: [],
  notCheckedIn: [],
};

export const workersSlice = createSlice({
  name: "workers",
  initialState,
  reducers: {
    setTotalActive(state, action) {
      state.totalActive = action.payload;
    },
    setActive(state, action) {
      state.active = action.payload;
    },
    setAbsent(state, action) {
      state.absent = action.payload;
    },
    setNotCheckedIn(state, action) {
      state.notCheckedIn = action.payload;
    },
    addUserToActive(state, action) {
      state.active.push(action.payload);
      state.totalActive = state.active.length;
    },
    removeUserFromActive(state, action) {
      state.active = state.active.filter((user) => user.id !== action.payload);
      state.totalActive = state.active.length;
    },
    addUserToNotCheckedIn(state, action) {
      state.notCheckedIn.push(action.payload);
    },
    removeUserFromNotCheckedIn(state, action) {
      state.notCheckedIn = state.notCheckedIn.filter(
        (user) => user.id !== action.payload
      );
    },
  },
});

export const workersActions = workersSlice.actions;
