import { ResponseMeta, ResponseWarningQueries } from "../types/response";

import ApiService from "./ApiService";

export interface AnalysisQueryParams {
  "filter[propertyId]"?: string;
  "filter[sinceDate]"?: string;
  "filter[untilDate]"?: string;
}

export type AnalysisResponse = { data: any } & ResponseMeta &
  ResponseWarningQueries;

const AnalysisService = {
  GetAnalysis: async (
    type: string,
    params: AnalysisQueryParams
  ): Promise<AnalysisResponse> => {
    return ApiService.Get(`analysis/${type}`, { ...params });
  },

  GetPestAnalysis: async (
    propertyId: AnalysisQueryParams
  ): Promise<AnalysisResponse> => {
    return ApiService.Get(`analysis/infestation/monthly`, { ...propertyId });
  },
};

export default AnalysisService;
