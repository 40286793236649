import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type CustomerDeficiencies = {
  propertyId: string;
};

const initialState: CustomerDeficiencies = {
  propertyId: "",
};

export const customerDeficienciesSlice = createSlice({
  name: "customerDeficiencies",
  initialState: initialState,
  reducers: {
    setPropertytId(state, action: PayloadAction<string>) {
      state.propertyId = action.payload;
    },
    resetState() {
      return { ...initialState };
    },
  },
});

export const customerDeficienciesActions = customerDeficienciesSlice.actions;
