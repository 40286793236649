import classNames from "classnames";
import React, { useState } from "react";

import styles from "./pull-to-refresh.module.css";

type Props = {
  onRefresh: Function;
  children: React.ReactNode;
  className?: string;
};

const PULL_THRESHOLD = 80;

const PullToRefresh = (props: Props) => {
  const [startY, setStartY] = useState(0);
  const [currentY, setCurrentY] = useState(0);
  const [isMoving, setIsMoving] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const onRefreshDone = (hasError: boolean) => {
    setCurrentY(0);
    setIsRefreshing(false);
  };

  const onTouchStart = (e: React.TouchEvent) => {
    if (!isRefreshing) {
      setStartY(e.touches[0].pageY);
    }
  };

  const onTouchMove = (e: React.TouchEvent) => {
    if (!isRefreshing) {
      const height = e.touches[0].pageY - startY;
      setIsMoving(height > PULL_THRESHOLD);
      setCurrentY(Math.min(height, PULL_THRESHOLD));
    }
  };

  const onTouchEnd = (e: React.TouchEvent) => {
    if (isMoving) {
      setIsMoving(false);
      setIsRefreshing(true);
      props.onRefresh(onRefreshDone);
    } else {
      setCurrentY(0);
    }
  };

  return (
    <div
      className={classNames(styles["pull-refresh-container"], props.className)}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <div
        style={{ height: `${currentY}px` }}
        className={classNames([styles["pull-refresh-container__indicator"]])}
      >
        <div className={styles["pull-refresh__indicator__spinner"]} />
      </div>
      <div>{props.children}</div>
    </div>
  );
};

export default PullToRefresh;
