import document from "./document_36px_light.png";

type DocumentIconProps = {
  color?: "light";
};

const DOCUMENT_IMAGES: Record<"light", string> = {
  light: document,
};

export const DocumentIcon = ({ color = "light" }: DocumentIconProps) => (
  <img src={DOCUMENT_IMAGES[color]} height={20} alt="Left arrow icon" />
);
