import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import BackEndService from "../../../api/BackEndService";
import styles from "../form.module.css";

function NewPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [queryParams] = useSearchParams();
  const token = queryParams.get("token");

  const navigate = useNavigate();

  const handleSubmit = () => {
    setError("");
    if (newPassword.length === 0 || passwordConfirm.length === 0) {
      setError("ANMELDEDATEN FALSCH");
      return;
    }
    if (newPassword !== passwordConfirm) {
      setError("ANMELDEDATEN FALSCH");
      return;
    }
    if (token != null) {
      BackEndService.NewPassword(token, newPassword, passwordConfirm).then(
        (data) => {
          setPasswordChanged(true);
        },
        () => {
          setError("ANMELDEDATEN FALSCH");
        }
      );
      setTimeout(function () {
        onPasswordChange();
      }, 5000);
    } else return;
  };

  const onPasswordChange = () => {
    navigate("/signin");
  };

  const { t } = useTranslation();
  return (
    <div className={styles["sign-in"]}>
      <div className={styles["form"]}>
        <div className={styles["form__password"]}>
          <input
            type="password"
            placeholder={t("Password") ?? ""}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className={styles["form__password"]}>
          <input
            type="password"
            placeholder={t("Confirm password") ?? ""}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
        </div>
        <button onClick={handleSubmit} className={styles["form__button"]}>
          {t("SetPassword")}
        </button>
        {error && <div className={styles["error-message"]}>{error}</div>}
        {passwordChanged ? (
          <h3 className={styles["form__confirmation-message"]}>
            {t("ChangedPasswordMessage")}
          </h3>
        ) : null}
      </div>

      <img className={styles["logo"]} alt="" src="/img/pestix.svg" />
    </div>
  );
}

export default NewPassword;
