import { t } from "i18next";

import styles from "./no-documentation.module.css";
import noDocumentation from "./no-documentation.png";

export const NoDocumentation = () => (
  <div className={styles.wrapper}>
    <img
      src={noDocumentation}
      height={85}
      alt="Red colored icon of a document with exclamation mark in the center"
    />
    <h1>{t("NoDocumentation")}</h1>
    <p>{t("NoDocumentationText")}</p>
  </div>
);
