import { ImageProps } from "../types/image";
import { Product } from "../types/product";
import { ResponseMeta, ResponseWarningQueries } from "../types/response";

import ApiService from "./ApiService";

type ProductsResponse = {
  data: Product[];
} & ResponseMeta &
  ResponseWarningQueries;

type IncludesProps = {
  documents?: ImageProps[];
};

export type ProductByIdResponse = {
  data: Product;
  includes?: IncludesProps;
} & ResponseMeta &
  ResponseWarningQueries;

const ProductService = {
  GetProducts: async (): Promise<ProductsResponse> => {
    return ApiService.Get("product-management/products", {
      sort: "name",
    });
  },

  GetSevdeskProducts: async (): Promise<ProductsResponse> => {
    return ApiService.Get(`sevdesk/products`);
  },

  GetProduct: async (productId: string): Promise<ProductByIdResponse> => {
    return ApiService.GetById(
      `${productId}?include=documents`,
      "product-management/products"
    );
  },
};

export default ProductService;
