import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { PlusIcon } from "../../icons/PlusIcon/PlusIcon";
import { RemoveIcon } from "../../icons/RemoveIcon/RemoveIcon";
import { creatingDocumentationActions } from "../../store/documentation-slice";

import styles from "./custom-email-input.module.css";

export type EmailProps = {
  id: string;
  email: string;
};

type CustomEmailInputProps = {
  onInputChange: (value: EmailProps) => void;
  initialValues?: EmailProps[];
  placeholder?: string;
};

export const CustomEmailInput = ({
  onInputChange,
  placeholder,
  initialValues = [],
}: CustomEmailInputProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [emailInputs, setEmailInputs] = useState<EmailProps[]>(initialValues);

  useEffect(() => {
    setEmailInputs(initialValues);
  }, [initialValues]);

  const handleInputChange = useCallback(
    (id: string, email: string) => {
      setEmailInputs((prevEmailInputs) =>
        prevEmailInputs.map((input) =>
          input.id === id ? { ...input, email } : input
        )
      );
    },
    [setEmailInputs]
  );

  const handleBlur = useCallback(
    (id: string) => {
      const input = emailInputs.find((input) => input.id === id);
      if (input && input.email) {
        onInputChange(input);
      }
    },
    [emailInputs, onInputChange]
  );

  const handleAddEmail = () => {
    setEmailInputs((prevEmailInputs) => [
      ...prevEmailInputs,
      { id: uuidv4(), email: "" },
    ]);
  };

  const handleDeleteEmail = (id: string) => {
    setEmailInputs((prevEmailInputs) =>
      prevEmailInputs.filter((input) => input.id !== id)
    );

    dispatch(creatingDocumentationActions.removeEmail(id));
  };

  return (
    <>
      <label className={styles["custom-email-input"]}>
        {t("Recipient")}

        <PlusIcon color="dark" handlePlusClicked={handleAddEmail} />
      </label>

      <ul className={styles["custom-email-input__email"]}>
        {emailInputs.map((input) => (
          <li key={input.id} className={styles["custom-email-input__box"]}>
            <input
              type="text"
              placeholder={placeholder}
              onChange={(e) => handleInputChange(input.id, e.target.value)}
              onBlur={() => handleBlur(input.id)}
              value={input.email}
            />

            {input.email && (
              <RemoveIcon
                handleRemoveClicked={() => handleDeleteEmail(input.id)}
              />
            )}
          </li>
        ))}
      </ul>
    </>
  );
};
