const LocalStorageService = (function () {
  let _service;

  function _getService() {
    if (!_service) {
      _service = this;
    }

    return _service;
  }

  function _set(props) {
    Object.entries(props).forEach(([key, value]) =>
      localStorage.setItem(key, value)
    );
  }

  function _get(key) {
    return localStorage.getItem(key) ? localStorage.getItem(key) : undefined;
  }

  function _clear() {
    Object.keys(localStorage).forEach((key) => localStorage.removeItem(key));
  }

  function _removeAccessToken() {
    localStorage.removeItem("accessToken");
  }

  function _getAccessToken() {
    const obj = localStorage.getItem("accessToken")
      ? localStorage.getItem("accessToken")
      : undefined;
    return obj ? JSON.parse(obj).accessToken : undefined;
  }

  function _getUserClaims() {
    const obj = localStorage.getItem("accessToken")
      ? localStorage.getItem("accessToken")
      : undefined;
    return JSON.parse(obj).userClaims;
  }

  function _all() {
    return { ...localStorage };
  }

  function _redirectToLogin(func) {
    func();
  }

  return {
    getService: _getService,
    set: _set,
    get: _get,
    clear: _clear,
    all: _all,
    getAccessToken: _getAccessToken,
    getUserClaims: _getUserClaims,
    removeAccessToken: _removeAccessToken,
    redirectToLogin: _redirectToLogin,
  };
})();

export default LocalStorageService;
