import monitorDark from "./monitor_24px_dark.png";
import monitorLight from "./monitor_24px_light.png";

type MonitorIconProps = {
  color?: "light" | "dark";
};

const MONITOR_IMAGES: Record<"light" | "dark", string> = {
  light: monitorLight,
  dark: monitorDark,
};

export const MonitorIcon = ({ color }: MonitorIconProps) => (
  <img src={MONITOR_IMAGES[color || "light"]} alt="Monitor icon" />
);
