import { t } from "i18next";

import { ImageProps } from "../../types/image";
import { BlockButton } from "../BlockButton/BlockButton";
import { ReadonlyImage } from "../ReadonlyImage/ReadonlyImage";

import styles from "./resource-images.module.css";

type ResourceImagesProps = {
  images: ImageProps[];
};

export const ResourceImages = ({ images }: ResourceImagesProps) => (
  <div>
    <BlockButton
      leftIcon="plus"
      rightIcon="camera"
      onClick={() => {}}
      standalone={false}
      readonly
    >
      {t("Images")}
    </BlockButton>

    {images.length > 0 ? (
      <div className={styles["images-container"]}>
        {images.map(
          (image) =>
            image.originalUrl && <ReadonlyImage key={image.id} image={image} />
        )}
      </div>
    ) : (
      <div className={styles["images-container--empty"]}>
        {t("NoImagesAvailable")}
      </div>
    )}
  </div>
);
