import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../store";
import { creatingDeficiencyActions } from "../../store/deficiencies-slice";
import { ImageProps } from "../../types/image";
import { CustomInput } from "../CustomInput/CustomInput";
import { DatePicker } from "../DatePicker/DatePicker";
import { MultiImageUploader2 } from "../MultiImageUploader2/MultiImageUploader2";

type SBKFormProps = {
  deficiencyId: string;
  onLoadingChange?: (loading: boolean) => void;
};

export const KundeForm = ({ deficiencyId, onLoadingChange }: SBKFormProps) => {
  const dispatch = useDispatch();

  const deficiency = useSelector((state: RootState) => state.deficiency);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const handleRecommendationChange = (recommendation: string) => {
    dispatch(creatingDeficiencyActions.setRecommendation(recommendation));
  };

  const handleDateChange = (date: string) => {
    dispatch(creatingDeficiencyActions.setDueDate(date));
  };

  const handleImageUploaded = (image: ImageProps) => {
    dispatch(creatingDeficiencyActions.addImage(image));
  };

  const handleImageDelete = (uuid: string) => {
    dispatch(creatingDeficiencyActions.removeImage(uuid));
  };
  const handleLoadingChange = (loading: boolean) => {
    setLoading(loading);
    if (onLoadingChange) onLoadingChange(loading);
  };
  return (
    <>
      <CustomInput
        label="Empfehlungen"
        placeholder="Bitte Empfehlungen eingeben"
        onInputChange={handleRecommendationChange}
        onBlur={() => {}}
        initialValue={deficiency.recommendation}
      />

      {/* Multiple images */}
      <MultiImageUploader2
        images={deficiency.images}
        onImageUpload={handleImageUploaded}
        onDeleteImage={handleImageDelete}
        onLoadingChange={handleLoadingChange}
      />

      <DatePicker
        initialValue={deficiency.dueDate}
        onDateChange={handleDateChange}
      />
    </>
  );
};
