import moment from "moment";

// Function to convert separate date and time strings to the desired format
export const createCombinedDateTime = (date: string, time: string) => {
  // Parse the separate date and time strings into Moment.js objects
  const dateMoment = moment(date, "D.M.YYYY");
  const timeMoment = moment(time, "HH:mm");

  // Combine the date and time objects
  const combinedDateTime = dateMoment
    .hour(timeMoment.hour())
    .minute(timeMoment.minute())
    .second(0) // You can set seconds to 0 if you don't have them in your separate time format
    .format(); // Format the Moment.js object into the desired format

  return combinedDateTime;
};

export const splitDateTime = (timestamp?: string) => {
  if (!timestamp) {
    return { date: null, time: null };
  }

  const datePart = moment(timestamp).format("DD.MM.");
  const timePart = moment(timestamp).format("HH:mm");

  return { date: datePart, time: timePart };
};
