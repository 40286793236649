import checkLight from "./check_icon_24px_light.png";
import checkDark from "./check_icon_dark.png";

type CheckIconProps = {
  color?: "light" | "dark";
};

const CHECK_IMAGES: Record<"light" | "dark", string> = {
  light: checkLight,
  dark: checkDark,
};

export const CheckIcon = ({ color }: CheckIconProps) => (
  <img src={CHECK_IMAGES[color || "light"]} alt="Check icon" />
);
