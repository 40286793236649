import { useState } from "react";

import { useOpenPreview } from "../../hooks/useOpenPreview";
import { ImageProps } from "../../types/image";
import FullScreenPreviewImage from "../FullScreenPreviewImage/FullScreenPreviewImage";
import Modal from "../Modal/Modal";

import styles from "./deleteable-image2.module.css";

type DeleteableImageProps = {
  onDeleteImage: () => void;
  image: ImageProps;
};

export const DeleteableImage2 = ({
  image,
  onDeleteImage,
}: DeleteableImageProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showPreview, openPreview, closePreview } = useOpenPreview();

  const handleDeleteImage = () => {
    setIsModalOpen(false);
    onDeleteImage();
  };

  return (
    <div className={styles["container"]}>
      <img
        className={styles["container__image"]}
        src={image.preview_url}
        alt="Pests or monitor that is set"
        onClick={openPreview}
      />
      {showPreview && (
        <FullScreenPreviewImage
          imageUrl={image?.preview_url?.replace("/preview", "")}
          closePreview={closePreview}
        />
      )}

      <button
        onClick={() => setIsModalOpen(true)}
        className={styles["container__button"]}
      >
        X
      </button>

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          onConfirm={() => handleDeleteImage()}
        />
      )}
    </div>
  );
};
