import { Analytics } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import BarChart from "../../../components/BarChart/BarChart";
import { LoadingSpinner } from "../../../components/LoadingSpinner/LoadingSpinner";
import { PieChartComponent } from "../../../components/PieChart/PieChart";
import { useAnalysis } from "../../../hooks/useAnalysis";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { AppLayout } from "../../../layout/AppLayout/AppLayout";
import styles from "../../../styles/customer-analysis.module.css";

export const CustomerAnalysis = () => {
  const [t] = useTranslation();
  const { isDesktop } = useScreenSize();

  const {
    appointmentAnalysisData,
    deficiencyAnalysisData,
    infestationAnalysisData,
    pestCounts,
    countAppointment,
    loading,
  } = useAnalysis();
  return (
    <AppLayout>
      <div className={styles["customer-analysis"]}>
        <div className={styles["customer-analysis__heading"]}>
          <p className={styles["customer-analysis__title"]}>
            {isDesktop && <Analytics />} {t("Analysis")}
          </p>
        </div>

        {loading && <LoadingSpinner loading={loading} />}

        <div className={styles["charts"]}>
          <PieChartComponent
            title={t("LastServices")}
            subtitle={t("InTheSelectedPeriod")}
            data={appointmentAnalysisData}
            thicklineShown
          />

          <PieChartComponent
            title={t("Deficiency")}
            subtitle={t("DeficiencyAnalysisSubtitle")}
            data={deficiencyAnalysisData}
            thicklineShown
          />

          <PieChartComponent
            title={t("InfestationAnalysis")}
            subtitle={t("InfestationAnalysisSubtitle", { countAppointment })}
            data={infestationAnalysisData}
          />

          <BarChart
            title={t("NumberOfPests")}
            subtitle={t("NOPsubtitle")}
            data={pestCounts}
          />
        </div>
      </div>
    </AppLayout>
  );
};
