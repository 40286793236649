import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../store";
import { clockActions } from "../store/clock-slice";
import { calculateTotalTime } from "../utils/calculateTotalTime";
import { getServiceStatus } from "../utils/getServiceStatus";

export const useClock = () => {
  const dispatch = useDispatch();
  const appointment = useSelector((state: RootState) => state.appointment);

  const readjustClockTime = useCallback(() => {
    if (!appointment.id) {
      // Do not attempt to make request if no appointment ID exists
      return;
    }

    getServiceStatus(appointment.id)
      .then(({ isServiceInProgress }) => {
        if (isServiceInProgress) {
          return calculateTotalTime(appointment.id);
        }

        return { totalTime: 0 };
      })
      .then(({ totalTime }) => {
        if (totalTime) {
          dispatch(
            clockActions.setClock({
              isRunning: true,
              appointmentId: appointment.id,
              currentTime: totalTime,
              initialTime: 0,
            })
          );
        }
      })
      .catch((error) => console.error(error));
  }, [appointment.id, dispatch]);

  // Readjust the clock time
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        readjustClockTime();
      }
    };

    window.addEventListener("focus", handleVisibilityChange);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("focus", handleVisibilityChange);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [appointment.id, readjustClockTime]);

  return { readjustClockTime };
};
