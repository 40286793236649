import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { DeficiencyPosition, DeficiencyType } from "../types/deficiencies";
import { ImageProps } from "../types/image";
import { MinimalProduct } from "../types/product";

type ResponsibilityOption = "exterminator" | "customer";

export type DeficiencySliceState = {
  inProgress: boolean;
  customerId: string;
  responsibility: ResponsibilityOption;
  propertyId: string;
  propertyAreaId: string;
  location: string;
  deficiencyType: {
    key: DeficiencyType | "";
    name: string;
  };
  deficiencySubtype: {
    key: string;
    name: string;
  };
  recommendation: string;
  comment: string;
  dueDate: string;
  plannedStart: {
    date: string;
    time: string;
  };
  images: ImageProps[];
  usedProducts: MinimalProduct[];
  sevdeskProducts: MinimalProduct[];
  deficiencyPosition: DeficiencyPosition;
};

const initialState: DeficiencySliceState = {
  inProgress: false,
  customerId: "",
  responsibility: "exterminator",
  propertyId: "",
  propertyAreaId: "",
  location: "",
  deficiencyType: {
    key: "",
    name: "",
  },
  deficiencySubtype: {
    key: "",
    name: "",
  },
  recommendation: "",
  comment: "",
  dueDate: "",
  plannedStart: {
    date: "",
    time: "",
  },
  images: [],
  usedProducts: [],
  sevdeskProducts: [],
  deficiencyPosition: {
    x: 1,
    y: 1,
  },
};

export const creatingDeficiencySlice = createSlice({
  name: "deficiency",
  initialState: initialState,
  reducers: {
    setInProgress(state, action: PayloadAction<boolean>) {
      state.inProgress = action.payload;
    },
    setCustomerId(state, action: PayloadAction<string>) {
      state.customerId = action.payload;
    },
    setResponsibility(state, action: PayloadAction<ResponsibilityOption>) {
      state.responsibility = action.payload;
    },
    setPropertyId(state, action: PayloadAction<string>) {
      state.propertyId = action.payload;
    },
    setPropertyAreaId(state, action: { payload: string }) {
      state.propertyAreaId = action.payload;
    },
    setLocation(state, action: PayloadAction<string>) {
      state.location = action.payload;
    },
    setDeficiencyType(
      state,
      action: PayloadAction<{ key: DeficiencyType; name: string }>
    ) {
      state.deficiencyType = action.payload;
    },
    setDeficiencySubtype(
      state,
      action: PayloadAction<{
        key: string;
        name: string;
      }>
    ) {
      state.deficiencySubtype = action.payload;
    },
    setRecommendation(state, { payload }: PayloadAction<string>) {
      state.recommendation = payload;
    },
    setComment(state, { payload }: PayloadAction<string>) {
      state.comment = payload;
    },
    setPlannedStartDate(state, { payload }: PayloadAction<string>) {
      state.plannedStart.date = payload;
    },
    setPlannedStartTime(state, { payload }: PayloadAction<string>) {
      state.plannedStart.time = payload;
    },
    setDueDate(state, { payload }: PayloadAction<string>) {
      state.dueDate = payload;
    },
    addImage(state, { payload }: PayloadAction<ImageProps>) {
      state.images.push(payload);
    },
    removeImage(state, { payload }: PayloadAction<string>) {
      state.images = state.images.filter((image) => image.uuid !== payload);
    },
    setDeficiencyPosition(
      state,
      { payload }: PayloadAction<DeficiencyPosition>
    ) {
      state.deficiencyPosition = payload;
    },
    resetState() {
      return { ...initialState };
    },
    responsibilityReset(state) {
      return {
        ...initialState, // Reset to initial state first
        // Retain specific fields from the current state
        responsibility: state.responsibility,
        inProgress: state.inProgress,
        propertyId: state.propertyId,
        propertyAreaId: state.propertyAreaId,
        deficiencyType: state.deficiencyType,
        deficiencySubtype: state.deficiencySubtype,
      };
    },
  },
});

export const creatingDeficiencyActions = creatingDeficiencySlice.actions;
