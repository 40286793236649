import { t } from "i18next";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "../../store";
import { creatingDeficiencyActions } from "../../store/deficiencies-slice";
import { activeTabsActions } from "../../store/tabs-slice";
import styles from "../../styles/create-deficiency.module.css";
import { ImageProps } from "../../types/image";
import { BlockButton } from "../BlockButton/BlockButton";
import { CustomCollapseWithChildren } from "../CustomCollapseWithChildren/CustomCollapseWithChildren";
import { CustomInput } from "../CustomInput/CustomInput";
import { DateTimePicker2 } from "../DateTimePicker2/DateTimePicker2";
import { Info } from "../Info/Info";
import { MultiImageUploader2 } from "../MultiImageUploader2/MultiImageUploader2";
import { TechnicianListItem } from "../TechnicianListItem/TechnicianListItem";

type SBKFormProps = {
  deficiencyId: string;
  onLoadingChange?: (loading: boolean) => void;
};

export const SBKForm = ({ deficiencyId, onLoadingChange }: SBKFormProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deficiency = useSelector((state: RootState) => state.deficiency);
  const technicians = useSelector((state: RootState) => state.technicians);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );

  const techniciansSelected = technicians.length > 0;

  const handleRecommendationChange = (recommendation: string) => {
    dispatch(creatingDeficiencyActions.setRecommendation(recommendation));
  };

  const handleDateChange = (date: string) => {
    dispatch(creatingDeficiencyActions.setPlannedStartDate(date));
  };

  const handleTimeChange = (time: string) => {
    dispatch(creatingDeficiencyActions.setPlannedStartTime(time));
  };

  const handleImageUploaded = (image: ImageProps) => {
    dispatch(creatingDeficiencyActions.addImage(image));
  };

  const handleImageDelete = (uuid: string) => {
    dispatch(creatingDeficiencyActions.removeImage(uuid));
  };

  const handleTechniciansClicked = () => {
    dispatch(
      activeTabsActions.setDashbaordUrl(
        "/deficiencies/create/assign-technicians"
      )
    );

    navigate("/deficiencies/create/assign-technicians");
  };
  const handleLoadingChange = (loading: boolean) => {
    setLoading(loading);
    if (onLoadingChange) onLoadingChange(loading);
  };

  return (
    <>
      <div className={styles["space-under"]}>
        {!techniciansSelected && !isCustomerAdmin && (
          <BlockButton
            leftIcon="plus"
            rightIcon="accountDark"
            onClick={handleTechniciansClicked}
          >
            {t("AddResponsibleTechnicians")}
          </BlockButton>
        )}

        {(techniciansSelected || technicians.length > 0) && (
          <span>
            <CustomCollapseWithChildren
              onLeftIconClick={handleTechniciansClicked}
              label={t("Responsible")}
              defaultText={t("NoResponsibleTechnicianAssigned")}
              active={technicians.length > 0}
              color="gray"
              hideLeftIcon={false}
            >
              {technicians.map((technician) => (
                <TechnicianListItem
                  key={technician.id}
                  user={technician}
                  selected={true}
                />
              ))}
            </CustomCollapseWithChildren>
          </span>
        )}
      </div>

      <CustomInput
        label="Empfehlungen"
        placeholder="Beschreibung"
        onInputChange={handleRecommendationChange}
        onBlur={() => {}}
        initialValue={deficiency.recommendation}
      />

      {/* Multiple images */}
      <MultiImageUploader2
        images={deficiency.images}
        onImageUpload={handleImageUploaded}
        onDeleteImage={handleImageDelete}
        onLoadingChange={handleLoadingChange}
      />

      <span className={styles["date-time-picker"]}>
        <h4>Zu erledigen bis:</h4>
        <DateTimePicker2
          onDateChange={handleDateChange}
          onTimeChange={handleTimeChange}
          date={deficiency.plannedStart.date}
          time={deficiency.plannedStart.time}
        />
      </span>

      <Info type="info">Auftrag wird automatisch erstellt.</Info>
    </>
  );
};
