import { MouseEventHandler } from "react";

import download from "./download_icon2_16px_dark.png";

type DownloadIconProps = {
  color?: "dark";
  handleDownloadButtonClicked: MouseEventHandler<HTMLImageElement>;
};

const DOWNLOAD_IMAGES: Record<"dark", string> = {
  dark: download,
};

export const DownloadIcon2 = ({
  color = "dark",
  handleDownloadButtonClicked,
}: DownloadIconProps) => (
  <img
    src={DOWNLOAD_IMAGES[color]}
    alt="Download icon2"
    onClick={handleDownloadButtonClicked}
    style={{cursor: "pointer"}}
  />
);
