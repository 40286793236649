import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import AbsenceService from "../../api/clockodo/AbsenceService";
import { AbsenceCard } from "../../components/AbsenceCard/AbsenceCard";
import { ApprovalStatus } from "../../components/ApprovalStatus/ApprovalStatus";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { SubMenu } from "../../components/SubMenu/SubMenu";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { activeTabsActions } from "../../store/tabs-slice";
import styles from "../../styles/absence-insight.module.css";
import { ClockodoAbsence } from "../../types/absence";

const AbsenceInsight = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [absence, setAbsence] = useState<ClockodoAbsence | null>(null);
  const { absenceId } = useParams();

  useEffect(() => {
    setAbsence(location.state?.absence);
  }, [location.state?.absence]);

  useEffect(() => {
    if (absenceId) {
      AbsenceService.GetAbsence(absenceId)
        .then((absence) => {
          setAbsence(absence.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [absenceId]);

  useEffect(() => {
    dispatch(
      activeTabsActions.setCalendarsStep({
        page: "details",
        data: absence,
      })
    );
  }, [absence, dispatch, location.state]);

  function returnToCalendarPage() {
    dispatch(
      activeTabsActions.setCalendarsStep({
        page: "list",
        data: null,
      })
    );
    navigate("/calendar");
  }

  return (
    <AppLayout hideHeader={true}>
      <div>
        <BackButtonHeader handleBackButtonClicked={returnToCalendarPage} />

        {absence && (
          <AbsenceCard
            dateFrom={absence.dateSince}
            dateUntil={absence.dateUntil}
            status={absence.status}
            countDays={absence.countDays}
            countHours={absence.countHours || 0}
            sickNote={absence.sickNote || null}
            type={absence.type}
            asHeader={true}
          />
        )}

        <div className={styles["content"]}>
          <SubMenu showSearchButton={false}>{t("AdditionalHint")}</SubMenu>

          <div className={styles["content__text-card"]}>
            {absence && <p>{absence.note ?? "No note"}</p>}
          </div>

          {absence?.reviewed && (
            <ApprovalStatus info={absence.reviewed} status={absence.status} />
          )}
        </div>
      </div>
    </AppLayout>
  );
};

export default AbsenceInsight;
