import { Job, JobUpdateRequestPayload } from "../types/job";
import { ResponseMeta, ResponseWarningQueries } from "../types/response";

import ApiService from "./ApiService";

export interface JobQueryParams {
  "filter[sinceDate]"?: string;
  "filter[untilDate]"?: string;
}

type JobResponse = { data: any } & ResponseMeta & ResponseWarningQueries;

const JobService = {
  AddJob: async (data: Job): Promise<JobResponse> => {
    return ApiService.Post("jobs", data);
  },
  UpdateJob: async (
    id: string,
    data: JobUpdateRequestPayload
  ): Promise<JobResponse> => {
    return ApiService.Update(id, "jobs", data);
  },
  GetGroupedMaterialsByJob: async (jobId: string): Promise<JobResponse> => {
    return ApiService.Get(`jobs/${jobId}/grouped-materials`);
  },
  GetGroupedEventsByJob: async (jobId: string): Promise<JobResponse> => {
    return ApiService.Get(`jobs/${jobId}/grouped-events`);
  },
  GetGroupedEventsPerPropertyAreaByJob: async (
    jobId: string
  ): Promise<JobResponse> => {
    return ApiService.Get(`jobs/${jobId}/grouped-events/pdf-version`);
  },
  GetGroupedDeficienciesPerPropertyAreaByJob: async (
    jobId: string,
    params: JobQueryParams
  ): Promise<JobResponse> => {
    return ApiService.Get(`jobs/${jobId}/grouped-deficiencies/pdf-version`, {
      ...params,
    });
  },
};

export default JobService;
