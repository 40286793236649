import { createSlice } from "@reduxjs/toolkit";

export type MonitorEvent = {
  id: string;
  monitorId: string;
};
export type ServicedMonitorsSliceState = {
  servicedMonitors: string[];
  servicedMonitorsCount: {
    [propertyArea: string]: { [monitorType: string]: number };
  };
  monitorEvents: MonitorEvent[];
};

const initialState: ServicedMonitorsSliceState = {
  servicedMonitors: [],
  servicedMonitorsCount: {},
  monitorEvents: [],
};

export const servicedMonitorSlice = createSlice({
  name: "serviced-monitors",
  initialState: initialState,
  reducers: {
    setServicedMonitors: (state, action: { payload: string }) => {
      const monitorId = action.payload;

      if (!state.servicedMonitors.includes(monitorId)) {
        state.servicedMonitors.push(monitorId);
      }
    },
    setServicedMonitorsCount(state, action) {
      state.servicedMonitorsCount = {
        ...state.servicedMonitorsCount,
        ...action.payload,
      };
    },
    setMonitorEvents: (state, action: { payload: MonitorEvent }) => {
      state.monitorEvents.push(action.payload);
    },
    resetServicedMonitorsCount(state) {
      state.servicedMonitorsCount = {};
    },
    resetState(state: ServicedMonitorsSliceState) {
      return { ...initialState };
    },
  },
});

export const servicedMonitorsActions = servicedMonitorSlice.actions;
