import { t } from "i18next";

import { MonitorEventByIdResponse } from "../../api/MonitorService";
import { formatServiceData } from "../../utils/formatServiseData";
import { CustomTextArea } from "../CustomTextArea/CustomTextArea";
import { ResourceImages } from "../ResourceImages/ResourceImages";

interface Props {
  event: MonitorEventByIdResponse | undefined;
}

export const NoInfestationDetails = ({ event }: Props) => {
  const { images } = formatServiceData(event);

  return (
    <div>
      <ResourceImages images={images} />
      <CustomTextArea
        placeholder={t("AdditionalText")}
        defaultValue={event?.data?.comment ?? ""}
        onTextareaChange={() => {}}
        readonly
      />
    </div>
  );
};
