import { useTranslation } from "react-i18next";

import { useDeficiencyRedirect } from "../../hooks/useDeficiencyRedirect";

import { GroupedDeficiencies } from "./Deficiencies";
import styles from "./deficiencies.module.css";
import { DeficiencySwipe } from "./DeficiencySwipe";

interface TechnicianViewProps {
  groupedDeficiencies: GroupedDeficiencies;
  handleDelete: (id: string) => void;
}

const TechniciansView = ({
  groupedDeficiencies,
  handleDelete,
}: TechnicianViewProps) => {
  const [t] = useTranslation();
  const { handleRedirect } = useDeficiencyRedirect();

  return (
    <>
      {groupedDeficiencies.open.length > 0 && (
        <>
          <h3 className={styles["heading"]}>{t("Open")}</h3>
          {groupedDeficiencies.open.map((deficiency) => (
            <DeficiencySwipe
              key={`today-${deficiency.id}`}
              deficiency={deficiency}
              handleDelete={handleDelete}
            />
          ))}
        </>
      )}

      {groupedDeficiencies.resolved.length > 0 && (
        <>
          <h3 className={`${styles["heading"]} ${styles["heading--resolved"]}`}>
            {t("Resolved")}
          </h3>
          {groupedDeficiencies.resolved.map((deficiency) => (
            <DeficiencySwipe
              key={`today-${deficiency.id}`}
              deficiency={deficiency}
              handleDelete={handleDelete}
              onClick={() => handleRedirect(deficiency)}
            />
          ))}
        </>
      )}
    </>
  );
};

export default TechniciansView;
