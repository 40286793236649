import { createContext } from "react";

import BackEndService from "../api/BackEndService";

type WorkerContextProps = {
  children: React.ReactNode;
};

type WorkerContextType = {
  submitAbsence: Function;
};

const initialWorker = {
  submitAbsence: () => {},
};

export const WorkerContext = createContext<WorkerContextType>(initialWorker);

const WorkerProvider = (props: WorkerContextProps) => {
  const submitAbsence = async (
    absence: AbsenceSubmitRequest,
    callback: Function
  ) => {
    try {
      await BackEndService.SubmitAbsence(absence);
      callback(true);
    } catch (e: any) {
      callback(false, e.data.error.message);
    }
  };

  const values = {
    submitAbsence,
  };

  return (
    <WorkerContext.Provider value={values}>
      {props.children}
    </WorkerContext.Provider>
  );
};

export default WorkerProvider;
