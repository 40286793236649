import { useDispatch } from "react-redux";

import { creatingServiceActions } from "../store/job-slice";
import { ImageProps } from "../types/image";

export const useHandleServiceImages = () => {
  const dispatch = useDispatch();

  const handleImageUploaded = (image: ImageProps) => {
    dispatch(creatingServiceActions.addImage(image));
  };

  const handleImageDelete = (uuid: string) => {
    dispatch(creatingServiceActions.removeImage(uuid));
  };

  return {
    handleImageUploaded,
    handleImageDelete,
  };
};
