import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Appointment } from "../types/appointment";

const initialState: Appointment = {
  typeOfAppointment: null,
  plannedStart: "",
  plannedEnd: "",
  realStart: "",
  realEnd: "",
  appointmentState: "",
  estimatedDuration: 0,
  id: "",
  createdBy: {
    id: "",
    type: "",
  },
  changedBy: {
    id: "",
    type: "",
  },
  number: "",
  changedAt: "",
  createdAt: "",
  technicians: [],
  jobs: [],
  customerId: null,
  type: "",
  propertyId: "",
  comment: null,
  additionalData: {
    customer: {
      id: "",
      name: "",
    },
    property: {
      id: "",
      address: "",
    },
  },
  incidents: [],
};

export const appointmentSlice = createSlice({
  name: "appointment",
  initialState: initialState,
  reducers: {
    setAppointment(_, { payload }: PayloadAction<Appointment>) {
      return payload;
    },
    resetState() {
      return initialState;
    },
  },
});

export const { setAppointment, resetState } = appointmentSlice.actions;
