import L, { LatLngBoundsExpression, LatLngExpression, Marker as MarkerType } from 'leaflet';
import { useEffect, useMemo, useRef, useState } from "react";
import { Marker, useMap } from 'react-leaflet';

import { Document } from '../../types/document';
import { Crop, MonitorPinAbbr } from '../../types/map';

import 'leaflet/dist/leaflet.css';
import styles from "./pin-crop-map.module.css";


interface MapContentProps {
    imageHeight: number;
    imageWidth: number;
    propertyMap: Document;
    monitorPinAbbr: MonitorPinAbbr;
    crop: Crop;
    isDraggable?: boolean;
    zoomLevel: number;
    onCropChange: (crop: Crop) => void;
};

const MapContent = (props: MapContentProps) => {
    const map = useMap();
    const pinRef = useRef<MarkerType>(null);

    const { imageHeight, imageWidth, propertyMap, monitorPinAbbr, crop, isDraggable, zoomLevel, onCropChange } = props;
    const [position, setPosition] = useState<LatLngExpression>([crop.x, crop.y]);


    let bounds: LatLngBoundsExpression = [
        [0, 0],
        [imageHeight, imageWidth],
    ];

    const imageOverlay = L.imageOverlay(propertyMap.url, bounds).addTo(map);
    imageOverlay?.on('load', () => {
        if (zoomLevel === map.getMinZoom())
            return;

        map.invalidateSize(true);

        map.setMinZoom(zoomLevel);
        map.setZoom(zoomLevel);
    });

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = pinRef.current;
                if (marker != null) {
                    const pos = marker.getLatLng();
                    onCropChange({ x: pos.lat, y: pos.lng });
                }
            },
        }),
        [onCropChange]
    );

    const generateMarkerIcon = () => {
        return new L.DivIcon({
            html: `
            <div class=${styles["map-pin"]}>
            <p class=${styles["pin-name"]}>${monitorPinAbbr.name}</p>
            <p class=${styles["pin-number"]}>${monitorPinAbbr.number}</p>
            </div>
      `,
            className: `${styles["pin-container"]}`,
            iconSize: [45, 45],
            iconAnchor: [22, 53],
        });
    }

    useEffect(() => {
        setPosition({ lat: crop.x, lng: crop.y })
    }, [crop])

    return (
        <Marker
            draggable={isDraggable ?? true}
            eventHandlers={eventHandlers}
            position={position}
            ref={pinRef}
            icon={generateMarkerIcon()}
        />
    )
}

export default MapContent;