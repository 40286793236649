import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { Info } from "../../../components/Info/Info";
import { Spinner } from "../../../components/Spinner/Spinner";
import { useAuth } from "../../../hooks/useAuth";
import { RootState } from "../../../store";
import styles from "../form.module.css";

const Home: React.FC = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const profileName = useSelector((state: RootState) => state.user.profileName);

  const { error, isSigningIn, handleSignIn } = useAuth({ email, password });

  useEffect(() => {
    if (isSignedIn) {
      const timer = setTimeout(() => {
        navigate("/");
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [isSignedIn, handleSignIn]);

  return (
    <div className={styles["sign-in"]}>
      {isSigningIn && <Spinner />}

      {isSignedIn && (
        <div className={styles["welcome-container"]}>
          <h1 className={styles["welcome-container__message"]}>
            {`${t("Welcome")}, ${profileName}!`}
          </h1>
          <button
            onClick={() => navigate("/")}
            className={styles["welcome-container__redirect-button"]}
          >
            <img alt="Go to Dashboard" src="/img/complete-button.svg" />
          </button>
        </div>
      )}

      <form className={styles["form"]}>
        <div className={styles["form__email"]}>
          <input
            type="text"
            className={error ? styles["form__email--error"] : ""}
            placeholder={t("Email") ?? ""}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className={styles["form__password"]}>
          <input
            autoComplete="off"
            type="password"
            placeholder={t("Password") ?? ""}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <button
          type="button"
          className={styles["form__button"]}
          onClick={handleSignIn}
          disabled={isSigningIn || isSignedIn}
        >
          {t("Sign in")}
        </button>

        {error && <Info type="error">{error}</Info>}

        <Link to="/password-reset" className={styles["navigation-button"]}>
          {t("Forgot password?")}
        </Link>
      </form>

      <img className={styles["logo"]} alt="" src="/img/pestix.svg" />
    </div>
  );
};

export default Home;
