import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AppointmentService from "../../api/AppointmentService";
import JobService from "../../api/JobService";
import { StepOne } from "../../components/AppointmentForm/StepOne";
import { StepThree } from "../../components/AppointmentForm/StepThree";
import { StepTwo } from "../../components/AppointmentForm/StepTwo";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { Info } from "../../components/Info/Info";
import { SubMenu } from "../../components/SubMenu/SubMenu";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { createCombinedDateTime } from "../../services/DateTimeService";
import { generateUUIDFromID } from "../../services/UUIDGeneratorService";
import { RootState } from "../../store";
import { activeTabsActions } from "../../store/tabs-slice";
import { creatingTaskActions } from "../../store/task-slice";
import { techniciansSliceActions } from "../../store/technicians-slice";
import styles from "../../styles/create-appointment.module.css";

export const CreateAppointment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [error, setError] = useState("");
  const [requestLoading, setRequestLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const task = useSelector((state: RootState) => state.task);
  const technicians = useSelector((state: RootState) => state.technicians);
  const step = useSelector((state: RootState) => state.tabs.appointments.step);

  //create job for this task, and use as a last element of jobs array in appointment object.
  //Task created with date required job state as scheduled
  //If clock/service is started job state is changed to processing
  //If clock is stopped, job state is changed to processed
  const createJob = async (appointmentId: string) => {
    await JobService.AddJob({
      jobState: "scheduled",
      appointmentId: appointmentId,
      customerId: generateUUIDFromID(task.customer.id),
      propertyId: task.property.isSevdeskProperty === 0 ? task.property.id : generateUUIDFromID(task.property.id),
      scheduledDate: createCombinedDateTime(task.date, task.time),
    });
  };

  const handleCreateAppointment = () => {
    if (!task.typeOfAppointment) {
      setError(`${t("SelectTaskType")}`);
      return;
    }

    setError("");
    setRequestLoading(true);

    AppointmentService.CreateAppointment({
      customerId: generateUUIDFromID(task.customer.id),
      propertyId: task.property.isSevdeskProperty === 0 ? task.property.id : generateUUIDFromID(task.property.id),
      plannedStart: createCombinedDateTime(task.date, task.time),
      typeOfAppointment: task.typeOfAppointment.toLowerCase(),
      comment: task.comment,
      additionalData: task.additionalData,
      technicians: technicians.map((technician) => technician.id),
    })
      .then((appointment) => {
        createJob(appointment.data.id).then((res) => {
          dispatch(creatingTaskActions.resetAppointment());
          dispatch(techniciansSliceActions.resetState());
          dispatch(activeTabsActions.setAppointmentsStep(1));
          setRequestLoading(false);
          navigate("/");
        });
      })
      .catch((error) => {
        setRequestLoading(false);
        if (error.status === 422) {
          setError("Validation error");
        } else {
          setError("Error saving the appointment");
        }
      });
  };

  const handleGoBack = () => {
    if (step === 1) {
      dispatch(creatingTaskActions.resetAppointment());
      navigate("/");
    }

    if (step === 2) {
      dispatch(creatingTaskActions.setCustomer({ customer: "" }));
      dispatch(
        creatingTaskActions.setAdditionalDataCustomer({
          customer: {
            id: "",
            name: "",
          },
        })
      );
      dispatch(activeTabsActions.setAppointmentsStep(1));
    }

    if (step === 3) {
      dispatch(creatingTaskActions.setProperty({ property: "" }));
      dispatch(
        creatingTaskActions.setTypeOfAppointment({ typeOfAppointment: null })
      );
      dispatch(
        creatingTaskActions.setAdditionalDataProperty({
          property: {
            id: "",
            address: "",
            isSevdeskProperty: 0,
          },
        })
      );
      dispatch(techniciansSliceActions.resetState());
      dispatch(activeTabsActions.setAppointmentsStep(2));
    }
  };

  return (
    <AppLayout
      className={styles["tasks__list"]}
      withBackButton={true}
      onGoBack={handleGoBack}
    >
      <SubMenu showSearchButton={true}>{t("EnterTask")}</SubMenu>

      {step === 1 && <StepOne />}
      {step === 2 && <StepTwo />}
      {step === 3 && <StepThree setButtonDisabled={setDisabled} />}

      {error && <Info type="error">{error}</Info>}

      {step === 3 && (
        <FloatingButtons>
          <CustomButton
            color="green"
            loading={requestLoading}
            disabled={disabled}
            onClick={handleCreateAppointment}
          >
            {t("CreateTermin")}
          </CustomButton>
        </FloatingButtons>
      )}
    </AppLayout>
  );
};
