import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { activeTabsActions } from "../store/tabs-slice";

export const useRedirect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setDashboardUrlAndRedirect = (url: string) => {
    navigate(url);
    dispatch(activeTabsActions.setDashbaordUrl(url));
  };

  return {
    setDashboardUrlAndRedirect,
  };
};
