import moment from "moment";

import BackEndService from "../api/BackEndService";
import JobService from "../api/JobService";
import { Appointment } from "../types/appointment";
import { ClockodoUser } from "../types/user";

type User = {
  id: string;
  type: string;
};

export const handleServiceEvent = async (
  appointment: Appointment,
  isRunning: boolean,
  clockodoUser: ClockodoUser | null,
  user?: User
) => {
  try {
    // Call the AddTimeTrackingEvent function from BackEndService
    const data = {
      employeeId: user!.id,
      appointmentId: appointment?.id,
      eventTimestamp: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      eventType: isRunning ? "stop" : "start",
      mileage: clockodoUser?.lastEndingMileage,
      errorMsg: "mollit dolore laboris cupidatat",
      createdAt: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      updatedAt: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    };

    const response = await BackEndService.AddTimeTrackingEvent(data);

    if (response) {
      const jobId = appointment?.jobs?.slice(-1).pop().id;
      if (!isRunning) {
        JobService.UpdateJob(jobId, { jobState: "partlyProcessed" });
      } else {
        JobService.UpdateJob(jobId, { jobState: "processing" });
      }
    }
  } catch (error) {
    console.log(error);
  }
};
