import warningLight from "./warning_24px_light.png";

type WarningIconProps = {
  color?: "light";
};

const WARNING_IMAGES: Record<"light", string> = {
  light: warningLight,
};

export const WarningIcon = ({ color }: WarningIconProps) => (
  <img
    style={{ height: "24px" }}
    src={WARNING_IMAGES[color || "light"]}
    alt="Wrench icon"
  />
);
