import deficiencyTypes from "../data/deficiency_types.json";

export const getDeficiencyType = (defType = "", defSubType = "") => {
  const type = deficiencyTypes.find((t) => t.deficiencyType === defType) || {
    name: "",
    deficiencyType: "",
    deficiencySubtypes: [],
  };

  if (type.deficiencyType === "other") {
    return { type: type.name, subType: defSubType };
  }

  const subType =
    type.deficiencySubtypes?.find(({ key }) => key === defSubType)?.name || "";

  return { type: type.name, subType };
};
