import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import MonitorService from "../../api/MonitorService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { List } from "../../components/List/List";
import PullToRefresh from "../../components/PullToRefresh/PullToRefresh";
import { SubMenu } from "../../components/SubMenu/SubMenu";
import { useMonitorContext } from "../../context/monitor-context";
import { useAppointment } from "../../hooks/useAppointment";
import { MonitorIcon } from "../../icons/MonitorIcon/MonitorIcon";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { go } from "../../services/NavigationService";
import { RootState } from "../../store";
import { creatingMonitorActions } from "../../store/monitor-slice";
import { activeTabsActions } from "../../store/tabs-slice";
import styles from "../../styles/select-monitor-type.module.css";

export const SelectMonitorType = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const appointment = useSelector((state: RootState) => state.appointment);
  const { monitorTypes, setMonitorTypes } = useMonitorContext();

  const { fetchAppointments } = useAppointment(
    {
      "filter[sinceDate]": moment().format("YYYY-MM-DD"),
    },
    true
  );

  useEffect(() => {
    dispatch(creatingMonitorActions.setInProgress(true));
    dispatch(creatingMonitorActions.setPropertyId(appointment.propertyId));
    dispatch(activeTabsActions.setDashbaordUrl(location.pathname));

    MonitorService.GetMonitorTypes()
      .then((monitors) => {
        const monitorsMapped = monitors.data.map((monitor) => ({
          id: monitor.id,
          name: monitor.label,
          onClick: () => handleMonitorTypeChosen(monitor.id),
        }));

        setMonitorTypes(monitorsMapped);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleMonitorTypeChosen = (monitor: any) => {
    navigate(`step-2`, {
      state: {
        monitorTypeName: monitor?.name,
        monitorId: monitor?.id,
        appointment: appointment,
      },
    });
  };

  const handleMonitorErstellenClicked = () => {
    console.log("Clicked!");
  };

  const pullToRefresh = (callback: Function) => {
    fetchAppointments()
      .then((result) => {
        callback();
      })
      .catch((error) => {
        callback(error);
      });
  };

  return (
    <AppLayout hideHeader={true} className="calendar-wrapper-content">
      <div>
        <BackButtonHeader
          handleBackButtonClicked={() =>
            go(navigate, `/properties/${appointment.id}/show`, location.state)
          }
        />
        <div className={styles["content-wrapper"]}>
          <AppointmentDetails />
          <div className={styles["content"]}>
            <SubMenu showSearchButton={true}>
              <span>Monitor Erstellen</span>
              <MonitorIcon color="dark" />
            </SubMenu>

            <PullToRefresh onRefresh={pullToRefresh}>
              <div className={styles["monitors__list-wrapper"]}>
                <List
                  title="Monitor auswählen"
                  data={monitorTypes}
                  onItemSelected={handleMonitorTypeChosen}
                />
              </div>
            </PullToRefresh>
          </div>
        </div>

        <FloatingButtons>
          <CustomButton
            color="gray"
            icon="monitor"
            iconPosition="right"
            onClick={handleMonitorErstellenClicked}
            disabled
            width={60}
          >
            Monitor Erstellen
          </CustomButton>
        </FloatingButtons>
      </div>
    </AppLayout>
  );
};
