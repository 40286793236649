import { useOpenPreview } from "../../hooks/useOpenPreview";
import { ImageProps } from "../../types/image";
import FullScreenPreviewImage from "../FullScreenPreviewImage/FullScreenPreviewImage";

import styles from "./readonly-image.module.css";

type DeleteableImageProps = {
  image: ImageProps;
};

export const ReadonlyImage = ({ image }: DeleteableImageProps) => {
  const { showPreview, openPreview, closePreview } = useOpenPreview();

  return (
    <div className={styles["container"]} onClick={openPreview}>
      <img
        className={styles["container__image"]}
        src={image.thumbnails[0]?.url}
        alt="Pests or monitor that is set"
      />

      {showPreview && (
        <FullScreenPreviewImage
          closePreview={closePreview}
          imageUrl={image?.originalUrl}
        />
      )}
    </div>
  );
};
