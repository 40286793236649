import { useTranslation } from "react-i18next";

import { formatServiceData } from "../../utils/formatServiseData";
import { CustomTextArea } from "../CustomTextArea/CustomTextArea";
import { MaterialsList } from "../MaterialsList/MaterialsList";
import NoProductDocumentsModal from "../Modal/NoProductDocumentsModal";
import { ResourceImages } from "../ResourceImages/ResourceImages";

import styles from "./infestation-details.module.css";

interface Props {
  event: any;
}

export const MaintenanceDetails = ({ event }: Props) => {
  const { t } = useTranslation();
  const { images, products } = formatServiceData(event);

  return (
    <>
      <NoProductDocumentsModal />

      {products.length > 0 ? (
        <div className={styles.materials}>
          <MaterialsList title={t("Materials")} data={products} readOnly />
        </div>
      ) : null}
      <ResourceImages images={images} />
      <CustomTextArea
        placeholder={t("AdditionalText")}
        defaultValue={event?.data?.comment ?? ""}
        onTextareaChange={() => {}}
        readonly
      />
    </>
  );
};
