import { ClipLoader } from "react-spinners";

import styles from "./loading-spinner.module.css";

type LoadingSpinnerProps = {
  loading: boolean;
  size?: number;
  top?: string;
};

export const LoadingSpinner = ({
  loading,
  size = 150,
  top = "30%",
}: LoadingSpinnerProps) => {
  return (
    <div className={styles["spinner-container"]} style={{ marginTop: top }}>
      <ClipLoader color="#11A8B8" loading={loading} size={size} />
    </div>
  );
};
