import { Pest } from "../types/pest";
import { ResponseMeta, ResponseWarningQueries } from "../types/response";

import ApiService from "./ApiService";

const SettingsService = {
  GetPests: async <
    T extends { data: Pest[] } & ResponseMeta & ResponseWarningQueries,
  >(): Promise<T> => {
    return ApiService.Get("settings/pests");
  },
};

export default SettingsService;
