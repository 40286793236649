import wrenchDark from "./wrench_24px_dark.png";
import wrenchLight from "./wrench_24px_light.png";

type WrenchIconProps = {
  color?: "light" | "dark";
};

const WRENCH_IMAGES: Record<"light" | "dark", string> = {
  light: wrenchLight,
  dark: wrenchDark,
};

export const WrenchIcon = ({ color }: WrenchIconProps) => (
  <img src={WRENCH_IMAGES[color || "light"]} alt="Wrench icon" />
);
