import { t } from "i18next";

import styles from "./badge.module.css";

type BadgeProps = {
  type: "reported" | "declined" | "approved";
};

type BadgeVariant = {
  class: string;
  title: string;
};

const BADGE_VARIANTS: Record<BadgeProps["type"], BadgeVariant> = {
  reported: {
    class: styles["badge--reported"],
    title: "Reported",
  },
  declined: {
    class: styles["badge--declined"],
    title: "Declined",
  },
  approved: {
    class: styles["badge--approved"],
    title: "Approved",
  },
};

export const Badge = ({ type }: BadgeProps): JSX.Element => {
  return (
    <div className={`${styles["badge"]} ${BADGE_VARIANTS[type].class}`}>
      {t(BADGE_VARIANTS[type].title)}
    </div>
  );
};
