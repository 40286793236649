import classNames from "classnames";
import { ChangeEvent } from "react";

import styles from "./custom-textarea.module.css";

type CustomTextAreaProps = {
  placeholder: string;
  onTextareaChange: (arg: string) => void;
  defaultValue?: string;
  color?: "white" | "gray";
  readonly?: boolean;
};

const BACKGROUND_COLOR: Record<any, any> = {
  white: styles["note--white"],
  gray: styles["note--gray"],
};

export const CustomTextArea = ({
  placeholder,
  onTextareaChange,
  defaultValue = "",
  color = "white",
  readonly = false,
}: CustomTextAreaProps) => {
  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onTextareaChange(e.target.value);
  };

  const textareaClasses = classNames(styles["note"], BACKGROUND_COLOR[color], {
    [styles["note--readonly"]]: readonly,
  });

  return (
    <textarea
      readOnly={readonly}
      className={textareaClasses}
      placeholder={placeholder}
      onChange={handleTextChange}
      value={defaultValue}
    />
  );
};
