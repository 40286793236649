import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SignaturePad from "react-signature-canvas";

import { DownloadIcon } from "../../icons/DownloadIcon/DownloadIcon";
import { ResetIcon } from "../../icons/ResetIcon/ResetIcon";
import { RootState } from "../../store";
import { creatingDocumentationActions } from "../../store/documentation-slice";

import styles from "./signature-canvas.module.css";

export const SignatureCanvas = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const signatureRef = useRef<SignaturePad | null>(null);

  const signatureUrl = useSelector(
    (state: RootState) => state.documentation.signature.url
  );

  useEffect(() => {
    if (signatureUrl && signatureRef.current) {
      signatureRef.current.fromDataURL(signatureUrl);
    }
  }, [signatureUrl]);

  const handleEnd = () => {
    if (signatureRef.current) {
      const dataUrl = signatureRef.current.toDataURL();
      dispatch(
        creatingDocumentationActions.setSignatureImage({ url: dataUrl })
      );
    }
  };

  const handleClear = useCallback(() => {
    if (signatureRef.current) {
      signatureRef.current.clear();
      dispatch(creatingDocumentationActions.setSignatureImage({ url: "" }));
    }
  }, [dispatch]);

  const handleSave = useCallback(() => {
    if (signatureRef.current) {
      const signatureData = signatureRef.current.toDataURL();
      const blob = dataURLToBlob(signatureData);

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "signature.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, []);

  // Function to convert data URL to Blob
  const dataURLToBlob = (dataURL: string): Blob => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  return (
    <div className={`${styles["content-wrapper"]}`}>
      <div className={`${styles["signature-canvas__label"]}`}>
        <span>{t("Signature of client")}</span>

        <div className={`${styles["signature-canvas__icons"]}`}>
          <ResetIcon handleResetButtonClicked={handleClear} />

          <DownloadIcon handleDownloadButtonClicked={handleSave} />
        </div>
      </div>

      <div className={`${styles["signature-canvas"]}`}>
        <SignaturePad
          canvasProps={{ className: styles["signature-canvas__canvas"] }}
          ref={signatureRef}
          onEnd={handleEnd}
        />

        <div className={styles["signature-canvas__line"]} />
      </div>
    </div>
  );
};
