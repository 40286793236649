import { t } from "i18next";

import { Technician } from "../../types/user";
import { CustomCollapseWithChildren } from "../CustomCollapseWithChildren/CustomCollapseWithChildren";
import { TechnicianListItem } from "../TechnicianListItem/TechnicianListItem";

import styles from "./technician-list.module.css";

interface TechnicianListProps {
  technicians: Technician[];
}

export const TechniciansList = ({ technicians }: TechnicianListProps) => (
  <div className={styles["technicians"]}>
    <span>
      <CustomCollapseWithChildren
        label={t("Responsible")}
        defaultText={t("NoResponsibleTechnicianAssigned")}
        active={technicians.length > 0}
        color="light-gray"
        rightIcon="accountDark"
      >
        {technicians.map((technician) => (
          <TechnicianListItem
            readonly
            key={technician.id}
            user={technician}
            selected
          />
        ))}
      </CustomCollapseWithChildren>
    </span>
  </div>
);
