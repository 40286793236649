import config from "../config/api.config";

import ApiService from "./ApiService";

const BackEndService = {
  SignIn: async <T extends object>(
    email: string,
    password: string
  ): Promise<T> => {
    const data = {
      email,
      password,
    };

    return ApiService.Post("auth/login", data);
  },

  PasswordRequest: async (email: string): Promise<object> => {
    const data = {
      email,
    };

    return ApiService.Post("auth/password-request", data);
  },

  NewPassword: async (
    token: string,
    password: string,
    passwordConfirmation: string
  ): Promise<object> => {
    const data = {
      token,
      password,
      passwordConfirmation,
    };

    const queryString = `auth/password-reset?token=${data.token}`;
    return ApiService.Post(queryString, data);
  },

  CheckIn: async (
    userId: number | undefined,
    mileage: number
  ): Promise<object> => {
    const data = {
      users_id: userId,
      starting_mileage: mileage,
    };

    return ApiService.Post(
      `clockodo/clock/${config.customersId}/${config.servicesId}/start`,
      data
    );
  },

  CheckOut: async (
    userId: number | undefined,
    mileage: number,
    clockId: number
  ): Promise<object> => {
    const data = {
      users_id: userId,
      ending_mileage: mileage,
    };

    return ApiService.Post(`clockodo/clock/${clockId}/stop`, data);
  },

  GetActiveUsers: async (): Promise<object> => {
    const currentDate = new Date();

    const monthAgo = new Date(currentDate);
    monthAgo.setMonth(currentDate.getMonth() - 1);
    const monthInFuture = new Date(currentDate);
    monthInFuture.setMonth(currentDate.getMonth() + 1);

    return ApiService.Get("clockodo/users/active", {
      timeSince: monthAgo.toISOString().split(".")[0] + "Z",
      timeUntil: monthInFuture.toISOString().split(".")[0] + "Z",
      enhancedList: "1",
    });
  },

  GetAbsentUsers: async (): Promise<object> => {
    return ApiService.Get("clockodo/users/absent", {
      year: new Date().getFullYear(),
    });
  },

  GetNotCheckedInUsers: async (): Promise<object> => {
    return ApiService.Get("clockodo/users/not-checked-in");
  },

  CheckClockodoUser: async (email: string): Promise<object> => {
    return ApiService.Get("clockodo/user", {
      email,
    });
  },

  GetAbsenceTypes: async (): Promise<AbsenceTypesResponse> => {
    return ApiService.Get("clockodo/absence/types");
  },

  GetAbsenceStatuses: async (): Promise<AbsenceStatusesResponse> => {
    return ApiService.Get("clockodo/absence/statuses");
  },

  GetAbsences: async (): Promise<object> => {
    return ApiService.Get("clockodo/absence", {
      year: new Date().getFullYear(),
    });
  },

  SubmitAbsence: async (request: AbsenceSubmitRequest): Promise<object> => {
    return ApiService.Post("clockodo/absence", request);
  },

  GetCustomers: async (): Promise<object> => {
    return ApiService.Get("sevdesk/customers");
  },

  GetObjects: async (
    customerId: string
  ): Promise<{ data: CustomerObject[] }> => {
    return ApiService.Get(`sevdesk/customer/${customerId}/properties`);
  },

  CreateTask: async (task: Order): Promise<object> => {
    return ApiService.Post(`sevdesk/task`, task);
  },

  // This is actually `get tasks`. Consider renaming this.
  GetOrders: async (): Promise<object> => {
    return ApiService.Get(`sevdesk/task`);
  },

  GetTask: async (taskId: number): Promise<object> => {
    return ApiService.Get(`sevdesk/task/${taskId}`);
  },

  DeleteTask: async (taskId: string): Promise<object> => {
    return ApiService.Delete(taskId, "sevdesk/task");
  },

  UploadImage: async (data: any): Promise<object> => {
    return ApiService.Post("media/uploads", data);
  },

  AddDocument: async (data: any): Promise<object> => {
    return ApiService.Post("documents", data);
  },

  UpdateDocument: async (id: string, data: any): Promise<object> => {
    return ApiService.Update(id, "documents", data);
  },

  GetDocumentById: async (id: string): Promise<object> => {
    return ApiService.Get("documents", { id });
  },

  AddTimeTrackingEvent: async (data: any): Promise<object> => {
    return ApiService.Post("time-tracking/events", data);
  },
};

export default BackEndService;
