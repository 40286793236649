import { MouseEventHandler } from "react";

import download from "./download_icon_21px_dark.png";

type DownloadIconProps = {
  color?: "dark";
  handleDownloadButtonClicked: MouseEventHandler<HTMLImageElement>;
};

const DOWNLOAD_IMAGES: Record<"dark", string> = {
  dark: download,
};

export const DownloadIcon = ({
  color = "dark",
  handleDownloadButtonClicked,
}: DownloadIconProps) => (
  <img
    src={DOWNLOAD_IMAGES[color]}
    alt="Download icon"
    onClick={handleDownloadButtonClicked}
  />
);
