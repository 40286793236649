import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import JobService from "../../api/JobService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import Modal from "../../components/Modal/Modal";
import { InfestationOverview } from "../../components/ServicesOverviewTypes/InfestationOverview";
import { MaintenanceOverview } from "../../components/ServicesOverviewTypes/MaintenanceOverview";
import { MonitorsToBeChecked } from "../../components/ServicesOverviewTypes/MonitorsToBeChecked";
import { NoInfestationOverview } from "../../components/ServicesOverviewTypes/NoInfestationOverview";
import { UnreachableOverview } from "../../components/ServicesOverviewTypes/UnreachableOverview";
import { useDocumentation } from "../../hooks/useDocumentation";
import { useScreenSize } from "../../hooks/useScreenSize";
import { ReturnArrowIcon } from "../../icons/ReturnArrowIcon/ReturnArrowIcon";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { goOneStepBack } from "../../services/NavigationService";
import { RootState } from "../../store";
import styles from "../../styles/property-area-services-overview.module.css";

export const PropertyAreaServicesOverview = () => {
  const { isDesktop } = useScreenSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const monitors = location.state.monitor?.monitors;
  const { t } = useTranslation();
  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );
  const [jobId, setJobId] = useState("");
  const [loading, setLoading] = useState(true);
  const [infestationMonitors, setInfestationMonitors] = useState<any[]>([]);
  const [noInfestationMonitors, setNoInfestationMonitors] = useState<any[]>([]);
  const [maintenanceMonitors, setMaintenanceMonitors] = useState<any[]>([]);
  const [unreachableMonitors, setUnreachableMonitors] = useState<any[]>([]);
  const [monitorsToBeChecked, setMonitorsToBeChecked] = useState<any[]>([]);
  const [infestationCountMonitors, setInfestationCountMonitors] =
    useState<number>();
  const [noInfestationCountMonitors, setNoInfestationCountMonitors] =
    useState<number>();
  const [maintenanceCountMonitors, setMaintenanceCountMonitors] =
    useState<number>();
  const [unreachableCountMonitors, setUnreachableCountMonitors] =
    useState<number>();
  const [monitorsToBeCheckedCount, setMonitorsToBeCheckedCount] =
    useState<number>();
  const propertyAreaName = location.state.monitor.propertyArea;

  const isOnline = useSelector((state: RootState) => state.online.isOnline);

  const { fetchDocumentation, requestLoading, noDocument, setNoDocument } =
    useDocumentation();

  const selectedAppointment = useSelector(
    (state: RootState) => state.appointment
  );
  const overviewAppointment = useSelector(
    (state: RootState) => state.overviewAppointment
  );

  let appointment = selectedAppointment;
  const readonly = location.state?.readonly;
  if (readonly) {
    appointment = overviewAppointment;
  }

  useEffect(() => {
    if (appointment.jobs.length > 0) {
      const copiedJobs = Array.from(appointment.jobs);
      const lastJobId = copiedJobs.pop();
      setJobId(lastJobId.id);
    }
  }, [appointment.jobs]);

  const filterMonitorsToBeChecked = (
    monitors: any,
    associatedMonitors: any
  ) => {
    return monitors.filter(
      (monitor: any) =>
        !associatedMonitors.some((associated: any) => associated === monitor.id)
    );
  };

  const mapEventsToMonitors = (monitors: any, events: any, eventType: any) => {
    return events
      .filter((event: any) => event.eventType === eventType)
      .map((event: any) => {
        const matchingMonitor = monitors.find(
          (monitor: any) => monitor.id === event.monitorId
        );

        if (matchingMonitor) {
          return {
            eventId: event.id,
            id: matchingMonitor.id,
            monitorType: matchingMonitor.monitorType,
            location: matchingMonitor.location,
          };
        }
        return null;
      })
      .filter(Boolean);
  };

  const groupMonitorsByEventType = useCallback(async () => {
    if (jobId) {
      try {
        setLoading(true);
        const { data } = await JobService.GetGroupedEventsByJob(jobId);

        let allEventMonitors: string[] = [];

        const filteredData = data.filter(
          (item: any) => item.label === propertyAreaName
        );

        filteredData.forEach((areaData: any) => {
          const infestationEvents = areaData.events.infestation || [];
          const maintenanceEvents = areaData.events.maintenancePerformed || [];
          const unreachableEvents = areaData.events.unreachableOrMissing || [];
          const noInfestationEvents = areaData.events.noInfestation || [];

          allEventMonitors = [
            ...allEventMonitors,
            ...infestationEvents.map((event: any) => event.monitorId),
            ...maintenanceEvents.map((event: any) => event.monitorId),
            ...unreachableEvents.map((event: any) => event.monitorId),
            ...noInfestationEvents.map((event: any) => event.monitorId),
          ];

          setInfestationMonitors(
            mapEventsToMonitors(monitors, infestationEvents, "infestation")
          );
          setMaintenanceMonitors(
            mapEventsToMonitors(
              monitors,
              maintenanceEvents,
              "maintenancePerformed"
            )
          );
          setNoInfestationMonitors(
            mapEventsToMonitors(monitors, noInfestationEvents, "noInfestation")
          );
          setUnreachableMonitors(
            mapEventsToMonitors(
              monitors,
              unreachableEvents,
              "unreachableOrMissing"
            )
          );

          setInfestationCountMonitors(infestationEvents.length);
          setNoInfestationCountMonitors(noInfestationEvents.length);
          setMaintenanceCountMonitors(maintenanceEvents.length);
          setUnreachableCountMonitors(unreachableEvents.length);
        });

        const monitorsToBeChecked = filterMonitorsToBeChecked(
          monitors,
          allEventMonitors
        );

        setMonitorsToBeChecked(monitorsToBeChecked);
        setMonitorsToBeCheckedCount(monitorsToBeChecked.length);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  }, [dispatch, jobId, propertyAreaName, monitors]);

  useEffect(() => {
    if (isOnline) {
      groupMonitorsByEventType();
    }
  }, [isOnline, groupMonitorsByEventType]);

  const handleBackButtonClicked = () => {
    goOneStepBack(navigate);
  };

  return (
    <AppLayout hideHeader={!isCustomerAdmin}>
      <div>
        <BackButtonHeader handleBackButtonClicked={handleBackButtonClicked} />

        <div>
          <AppointmentDetails isServicesOverview={true} />
        </div>
        {loading && <LoadingSpinner loading={loading} />}

        {!loading && (
          <div className={styles["content-wrapper"]}>
            <ReturnArrowIcon
              handleBackButtonClicked={handleBackButtonClicked}
            />{" "}
            <span className={styles["title"]}>{propertyAreaName}</span>
            <div className={styles.monitors}>
              <InfestationOverview
                monitors={infestationMonitors}
                infestationCount={infestationCountMonitors}
              />

              <UnreachableOverview
                monitors={unreachableMonitors}
                infestationCount={unreachableCountMonitors}
              />

              <MonitorsToBeChecked
                monitors={monitorsToBeChecked}
                count={monitorsToBeCheckedCount}
              />

              <NoInfestationOverview
                monitors={noInfestationMonitors}
                infestationCount={noInfestationCountMonitors}
              />

              <MaintenanceOverview
                monitors={maintenanceMonitors}
                infestationCount={maintenanceCountMonitors}
              />
            </div>
            {!isDesktop && (
              <FloatingButtons>
                <CustomButton
                  color="gray"
                  icon="leftarrow"
                  iconPosition="left"
                  width={70}
                  onClick={handleBackButtonClicked}
                >
                  {t("Back to overview")}
                </CustomButton>

                {readonly && (
                  <CustomButton
                    color="gray"
                    icon="document"
                    width={48}
                    loading={requestLoading}
                    onClick={() =>
                      fetchDocumentation(appointment.document?.url)
                    }
                  />
                )}
              </FloatingButtons>
            )}
          </div>
        )}
      </div>

      <Modal
        isOpen={noDocument}
        message={t("NoDocument")}
        confirmText="OK"
        showAlertIcon
        onConfirm={() => setNoDocument(false)}
      />
    </AppLayout>
  );
};
