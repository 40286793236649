import { MouseEventHandler } from "react";

import decrementButton from "./decrement_button_24px.png";
import incrementButton from "./increment_button_24px.png";
import styles from "./quantity-button.module.css";

type quantityButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  active: boolean;
  icon: "increment" | "decrement";
};

const QUANTITY_BUTTON_ICONS: Record<
  "increment" | "decrement",
  { src: string; alt: string }
> = {
  increment: {
    src: incrementButton,
    alt: "Increment button icon",
  },
  decrement: {
    src: decrementButton,
    alt: "Decrement button icon",
  },
};

export const QuantityButton = ({
  onClick,
  active = false,
  icon,
}: quantityButtonProps): JSX.Element => {
  return (
    <button
      className={`${styles["quantity-button"]} ${
        active && styles["quantity-button--clicked"]
      }`}
      onClick={onClick}
    >
      <img
        src={QUANTITY_BUTTON_ICONS[icon].src}
        alt={QUANTITY_BUTTON_ICONS[icon].alt}
      />
    </button>
  );
};
