import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { RootState } from "../store";

interface PrivateProps {
  Component: FC;
  requiredRoles: string[];
}

const Private: FC<PrivateProps> = ({ Component, requiredRoles }) => {
  const { isSignedIn, roles } = useSelector((state: RootState) => state.user);
  const isAuthorized =
    isSignedIn && requiredRoles.some((role) => roles.includes(role));

  if (!isSignedIn) {
    return <Navigate to="/signin" />;
  }

  return isAuthorized ? <Component /> : <Navigate to="/" />;
};

export default Private;
