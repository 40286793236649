import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";

import {
  TotalTimeResponse,
  calculateTotalTime,
} from "../../utils/calculateTotalTime";

import styles from "./time-tracking-overview.module.css";

interface TimeTrackingOverviewProps {
  appointmentId: string;
}

type TimeTrackingData = {
  total: string;
  start: string;
  end: string;
};

export const TimeTrackingOverview = ({
  appointmentId,
}: TimeTrackingOverviewProps) => {
  const [timeTrackingData, setTimeTrackingData] = useState<TimeTrackingData>({
    total: "",
    start: "",
    end: "",
  });

  useEffect(() => {
    if (!appointmentId) return;

    calculateTotalTime(appointmentId)
      .then((result: TotalTimeResponse) => {
        const totalSeconds = result.totalTime;

        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(seconds).padStart(2, "0");

        const total = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

        const start = moment(result.firstEvent?.createdAt)
          .utcOffset("+02:00")
          .format("HH:mm:ss");
        const end = moment(result.lastEvent?.createdAt)
          .utcOffset("+02:00")
          .format("HH:mm:ss");

        setTimeTrackingData({
          total: total,
          start: start,
          end: end,
        });
      })
      .catch((error) => console.error(error));
  }, [appointmentId]);

  return (
    <div className={styles["list"]}>
      <h1 className={styles["list__title"]}>
        <span>{t("ServiceDuration")}</span>
        <span>{timeTrackingData.total}</span>
      </h1>

      <ul>
        <li>
          <span>{t("Start")}</span>
          <span>{timeTrackingData.start}</span>
        </li>
        <li>
          <span>{t("End")}</span>
          <span>{timeTrackingData.end}</span>
        </li>
      </ul>
    </div>
  );
};
