import { createSlice } from "@reduxjs/toolkit";

import { ClockodoAbsenceStatus } from "./../types/absence";

type AbsencesSliceState = {
  statuses: ClockodoAbsenceStatus[] | [];
  types: { id: number; value: string }[] | [];
};

const initialState: AbsencesSliceState = {
  statuses: [],
  types: [],
};

export const absencesSlice = createSlice({
  name: "absences",
  initialState,
  reducers: {
    setTypes(state, action) {
      state.types = action.payload;
    },
  },
});

export const absencesActions = absencesSlice.actions;
