import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CardWrapper } from "../../components/CardWrapper/CardWrapper";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomTextArea } from "../../components/CustomTextArea/CustomTextArea";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { MonitorTypeListItem } from "../../components/MonitorTypeListItem/MonitorTypeListItem";
import deficiency_types from "../../data/deficiency_types.json";
import { useScreenSize } from "../../hooks/useScreenSize";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { RootState } from "../../store";
import { creatingDeficiencyActions } from "../../store/deficiencies-slice";
import { activeTabsActions } from "../../store/tabs-slice";
import styles from "../../styles/select-deficiency-type.module.css";

export const SelectDeficiencyTypeAndSubtype = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isDesktop } = useScreenSize();

  const deficienciesStep = useSelector(
    (state: RootState) => state.tabs.deficiencies.step
  );
  const appointment = useSelector((state: RootState) => state.appointment);
  const deficiency = useSelector((state: RootState) => state.deficiency);
  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );

  useEffect(() => {
    dispatch(creatingDeficiencyActions.setInProgress(true));
    dispatch(creatingDeficiencyActions.setPropertyId(appointment?.propertyId));
    dispatch(activeTabsActions.setDashbaordUrl(location.pathname));
  }, [dispatch, location.pathname, appointment, deficiency]);

  const handleNextStep = () => {
    dispatch(activeTabsActions.setDeficienciesStep(3));
    navigate(`/deficiencies/create/step-2`);
  };

  const handleDeficiencyTypeClick = (status: any) => {
    const deficiencyType = {
      key: status.deficiencyType,
      name: status.name,
    };

    dispatch(creatingDeficiencyActions.setDeficiencyType(deficiencyType));

    dispatch(activeTabsActions.setDeficienciesStep(2));
  };

  const handleDeficiencySubtypeClick = (subtype: any) => {
    dispatch(creatingDeficiencyActions.setDeficiencySubtype(subtype));

    handleNextStep();
  };

  const handleTextareaChange = (note: string) => {
    dispatch(
      creatingDeficiencyActions.setDeficiencySubtype({
        key: note.toLowerCase().trim(),
        name: note,
      })
    );
  };

  const handleBackButtonClicked = () => {
    if (deficienciesStep === 2) {
      dispatch(activeTabsActions.setDeficienciesStep(1));

      dispatch(
        creatingDeficiencyActions.setDeficiencySubtype({
          key: "",
          name: "",
        })
      );
    } else {
      // Clear deficiency state and navigate to property insight
      dispatch(creatingDeficiencyActions.resetState());

      if (isCustomerAdmin) {
        navigate("/deficiencies");
      } else {
        navigate(`/properties/${appointment.id}/show`);
      }
    }
  };

  return (
    <AppLayout
      hideHeader={!isCustomerAdmin || (isCustomerAdmin && !isDesktop)}
      className="calendar-wrapper-content"
    >
      <div>
        <BackButtonHeader
          handleBackButtonClicked={handleBackButtonClicked}
          isDeficiency
        />

        <div className={styles["content-wrapper"]}>
          {(!isCustomerAdmin || (isCustomerAdmin && !isDesktop)) && (
            <AppointmentDetails />
          )}

          <div className={styles["content"]}>
            {deficienciesStep === 1 ? (
              <CardWrapper title="Mangel" color="gray">
                {deficiency_types.map((status: any, i: number) => (
                  <MonitorTypeListItem
                    key={i}
                    onClick={() => handleDeficiencyTypeClick(status)}
                  >
                    <span>{status.name}</span>
                    <img alt="" src="/img/icon-next.svg" />
                  </MonitorTypeListItem>
                ))}
              </CardWrapper>
            ) : (
              <>
                {deficiency.deficiencyType.key === "other" ? (
                  <>
                    <span className={styles["new-deficiency-type-name"]}>
                      {deficiency.deficiencyType.name}
                    </span>

                    <CustomTextArea
                      placeholder="Zusätzlicher Text"
                      onTextareaChange={handleTextareaChange}
                      defaultValue={deficiency.deficiencySubtype.name}
                    />

                    <FloatingButtons>
                      <CustomButton
                        color="green"
                        onClick={handleNextStep}
                        width={60}
                      >
                        {t("Confirm")}
                      </CustomButton>
                    </FloatingButtons>
                  </>
                ) : (
                  <CardWrapper
                    title={deficiency.deficiencyType.name}
                    color="gray"
                  >
                    {deficiency_types
                      .filter(
                        (deficiency_types) =>
                          deficiency_types.deficiencyType ===
                          deficiency.deficiencyType.key
                      )
                      .flatMap((status: any) =>
                        status.deficiencySubtypes.map(
                          (subtype: any, i: number) => (
                            <MonitorTypeListItem
                              key={i}
                              onClick={() =>
                                handleDeficiencySubtypeClick(subtype)
                              }
                            >
                              <span>{subtype.name}</span>
                              <img alt="" src="/img/icon-next.svg" />
                            </MonitorTypeListItem>
                          )
                        )
                      )}
                  </CardWrapper>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  );
};
