import { MouseEventHandler, ReactNode, useState } from "react";

import { BlockButton } from "../BlockButton/BlockButton";

import styles from "./custom-collapse.module.css";

type CustomInputProps = {
  children: ReactNode;
  onLeftIconClick?: MouseEventHandler<HTMLImageElement>;
  label: string;
  active?: boolean;
  defaultText: string;
  color?: "gray" | "default" | "light-gray";
  rightIcon?:
    | "account"
    | "accountDark"
    | "collapseArrow"
    | "collapseArrowRotated";
  collapsedByDefault?: boolean;
  hideLeftIcon?: boolean;
};

export const CustomCollapseWithChildren = ({
  children,
  onLeftIconClick,
  label,
  active,
  defaultText,
  color,
  rightIcon = "account",
  collapsedByDefault = false,
  hideLeftIcon = true,
}: CustomInputProps) => {
  const [collapsed, setCollapsed] = useState(collapsedByDefault);

  const determineRightIcon = () => {
    if (rightIcon === "collapseArrow" && !collapsed) {
      return "collapseArrowRotated";
    }
    return rightIcon;
  };

  return (
    <>
      <BlockButton
        onClick={() => setCollapsed(!collapsed)}
        leftIcon="plusLight"
        hideLeftIcon={hideLeftIcon}
        rightIcon={determineRightIcon()}
        standalone={false}
        color={color}
        onLeftIconClick={onLeftIconClick}
      >
        {label}
      </BlockButton>

      <ul
        style={{ background: "red !important", margin: 0 }}
        className={`${styles["collapse__list"]} ${
          !collapsed ? styles["collapse__list--show"] : ""
        } ${active && styles["collapse__list--show--active"]}`}
      >
        {!active && (
          <li className={styles["collapse__list--empty"]}>{defaultText}</li>
        )}

        {active && (
          <span className={styles["collapse__content"]}>{children}</span>
        )}
      </ul>
    </>
  );
};
