import { createSlice } from "@reduxjs/toolkit";

export type ImageUploaderState = {
  images: FormData[];
};

const initialState: ImageUploaderState = {
  images: [],
};

export const imageUploaderSlice = createSlice({
  name: "image-uploader",
  initialState: initialState,
  reducers: {
    addImages(state: ImageUploaderState, action: { payload: FormData[] }) {
      state.images = action.payload;
    },

    resetState(state: ImageUploaderState) {
      return { ...initialState };
    },
  },
});

export const imageUploaderActions = imageUploaderSlice.actions;
