import { useDispatch, useSelector } from "react-redux";

import { useDownloadFiles } from "../../hooks/useDownloadFiles";
import { DownloadIcon2 } from "../../icons/DownloadIcon2/DownloadIcon2";
import { RootState } from "../../store";
import { techniciansSliceActions } from "../../store/technicians-slice";
import { Technician } from "../../types/user";
import { AddButton } from "../AddButton/AddButton";

import styles from "./technician-list-item.module.css";

type TechnicianListItemProps = {
  user: Technician;
  selected: boolean;
  darkBackground?: boolean;
  readonly?: boolean;
};

export const TechnicianListItem = ({
  user,
  selected,
  darkBackground = false,
  readonly = false,
}: TechnicianListItemProps) => {
  const dispatch = useDispatch();
  const customerAdmin = useSelector((state: RootState) => state.user);
  const isCustomerEditing = useSelector(
    (state: RootState) => state.deficiencyService.isCustomerEditing
  );
  const profileName =
    !isCustomerEditing && user.firstName && user.lastName
      ? `${user.firstName} ${user.lastName}`
      : user?.profileName;

  const { fetchDocuments } = useDownloadFiles();

  return (
    <li
      className={`${styles["card"]} ${darkBackground && styles["card--dark"]}`}
    >
      <div className={styles["card__user-info"]}>
        <button
          className={`${styles["card__user-info__avatar"]} ${
            readonly && styles["item--readonly"]
          }`}
        />
        <span className={styles["card__user-info__name"]}>{profileName}</span>
      </div>

      {readonly &&
        (!customerAdmin.isCustomerAdmin ||
          customerAdmin.currentUser.id !== user.id) && (
          <DownloadIcon2
            handleDownloadButtonClicked={() => {
              fetchDocuments(user.id);
            }}
          />
        )}

      {!readonly && (
        <div className={styles["card__button-wrapper"]}>
          <AddButton
            selected={selected}
            background="dark"
            iconColor="light"
            onClick={() => {
              dispatch(
                techniciansSliceActions.toggleTechnician({
                  id: user.id,
                  firstName: user.firstName,
                  lastName: user.lastName ?? "",
                })
              );
            }}
          />
        </div>
      )}
    </li>
  );
};
