import calendar from "./calendar_28px_light.png";

type CalendarIconProps = {
  color?: "light";
};

const CALENDAR_IMAGES: Record<"light", string> = {
  light: calendar,
};

export const CalendarIcon = ({ color = "light" }: CalendarIconProps) => (
  <img src={CALENDAR_IMAGES[color]} alt="Camera icon" height={15} />
);
