import { t } from "i18next";
import { now } from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import BackEndService from "../../api/BackEndService";
import PropertyService from "../../api/PropertyService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { BlockButton } from "../../components/BlockButton/BlockButton";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { Info } from "../../components/Info/Info";
import { SubMenu } from "../../components/SubMenu/SubMenu";
import { LayersIcon } from "../../icons/LayersIcon/LayersIcon";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { go } from "../../services/NavigationService";
import { generateUUIDFromID } from "../../services/UUIDGeneratorService";
import { RootState } from "../../store";
import { activeTabsActions } from "../../store/tabs-slice";
import styles from "../../styles/create-floor.module.css";
import { PropertyArea } from "../../types/property";

type Props = {
  creationType: string;
};

export const CreatePropertyArea = ({ creationType }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [propertyArea, setPropertyArea] = useState<PropertyArea>();
  const [propertyAreaIsCreated, setPropertyAreaIsCreated] = useState(false);

  const [map, setMap] = useState<File>();
  const [image, setImage] = useState("");
  const [mapIsUploaded, setIsMapUploaded] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const appointment = useSelector((state: RootState) => state.appointment);

  useEffect(() => {
    dispatch(activeTabsActions.setDashbaordUrl(location.pathname));
  }, [appointment, dispatch, location.pathname]);

  const handleInputChange = (customInput: string) => {
    setShowError(false);
    setInputValue(customInput);
  };

  const handleMapAdd = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      // Handle the selected image(s) here
      const selectedImage = files[0];
      setMap(selectedImage);
      setImage(URL.createObjectURL(selectedImage));
      setIsMapUploaded(true);
    }
  };

  const handleMapUpload = (propertyArea: PropertyArea) => {
    if (!map) return;

    const formData = new FormData();
    formData.append("uuid", generateUUIDFromID(now().toString()));
    formData.append("name", map.name);
    formData.append("file", map);

    BackEndService.UploadImage(formData)
      .then((image: any) => {
        setMap(image);

        BackEndService.AddDocument({
          documentType: "map",
          fileName: propertyArea.id + "-" + map.name,
          isInternal: true,
          media: image.uuid,
          attachedTo: {
            type: "PropertyArea",
            id: propertyArea.id,
          },
        })
          .then((document) => {
            setRequestLoading(false);
          })
          .catch((error) => {
            console.log("error", error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFloorCreation = () => {
    setRequestLoading(true);
    const propertyAreaRequestPayload = {
      propertyId: appointment.propertyId,
      label: inputValue,
      map: null,
    };

    if (inputValue && !propertyAreaIsCreated) {
      PropertyService.CreatePropertyArea(propertyAreaRequestPayload)
        .then((propertyArea) => {
          setPropertyArea(propertyArea.data);
          handleMapUpload(propertyArea.data);
          setPropertyAreaIsCreated(true);
          handleBackButtonClicked();
        })
        .catch(() => {
          setShowError(true);
          setRequestLoading(false);
        });
    }

    if (inputValue && propertyArea) {
      PropertyService.UpdatePropertyArea(propertyArea.id, {
        label: inputValue,
      })
        .then((result) => {
          setRequestLoading(false);
        })
        .catch((error) => {
          setError(error.data.message);
          setRequestLoading(false);
        });
    }
  };

  const handleBackButtonClicked = () => {
    if (creationType === "monitor") {
      go(navigate, "/monitors/create", location.state);
    } else if (creationType === "deficiency") {
      dispatch(activeTabsActions.setDeficienciesStep(2));

      go(navigate, "/deficiencies/create/step-2", location.state);
    }
  };

  const isButtonDisabled = inputValue.length === 0;

  return (
    <AppLayout hideHeader={true} className={styles["create-floor"]}>
      <div>
        <BackButtonHeader handleBackButtonClicked={handleBackButtonClicked} />

        <div className={styles["content-wrapper"]}>
          <AppointmentDetails />

          <div className={styles["content"]}>
            <SubMenu showSearchButton={false}>
              <span>Ebene erstellen</span>
              <LayersIcon color="dark" />
            </SubMenu>

            <CustomInput
              label={t("SelectEnterFloorName")}
              placeholder={t("Enter")}
              onInputChangeLabel={t("EnterFloorName")}
              onInputChange={handleInputChange}
            />
            {error && <Info>{error.split(". ")[0]}</Info>}

            {propertyAreaIsCreated && (
              <Info type="success">Bereich erfolgreich erstellt</Info>
            )}

            {showError && (
              <Info type="error">
                Ein Stockwerk mit diesem Namen existiert bereits.
              </Info>
            )}

            {!mapIsUploaded && (
              <BlockButton
                leftIcon="plus"
                rightIcon="map"
                onClick={handleMapAdd}
              >
                Karte hinzufügen
              </BlockButton>
            )}

            {image && (
              <img className={styles["content__map"]} src={image} alt="Map" />
            )}

            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileInputChange}
            />
          </div>
        </div>

        <FloatingButtons>
          <CustomButton
            color="white"
            icon="layers"
            loading={requestLoading}
            onClick={handleFloorCreation}
            disabled={isButtonDisabled}
            width={60}
          >
            Ebene eintragen
          </CustomButton>
        </FloatingButtons>
      </div>
    </AppLayout>
  );
};
