import { CheckCircle } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useHandleServiceImages } from "../../hooks/useHandleServiceImages";
import { RootState } from "../../store";
import { creatingServiceActions } from "../../store/job-slice";
import { Pest } from "../../types/pest";
import { CustomCollapse } from "../CustomCollapse/CustomCollapse";
import { CustomSlider } from "../CustomSlider/CustomSlider";
import { MultiImageUploader2 } from "../MultiImageUploader2/MultiImageUploader2";

import styles from "./issues-type.module.css";

interface Props {
  monitorEventId: string;
  redirect: () => void;
  onLoadingChange?: (loading: boolean) => void;
}

export const BaitEaten = ({
  monitorEventId,
  redirect,
  onLoadingChange,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { handleImageUploaded, handleImageDelete } = useHandleServiceImages();
  const pests = useSelector((state: RootState) => state.settings.pests);
  const service = useSelector((state: RootState) => state.service);
  const products = useSelector((state: RootState) => state.products);
  // eslint-disable-next-line no-unused-vars
  const [imageLoading, setImageLoading] = useState(false);
  const handleMaterialsClicked = () => {
    navigate(`/products`);
  };
  const pestExist = (id: string) => {
    if (service.importedPests.find((pest: Pest) => pest.id === id)) return true;
    else return false;
  };

  const handleImportedPests = (pest: Pest) => {
    if (pestExist(pest.id))
      dispatch(
        creatingServiceActions.addImportedPests(
          service.importedPests.filter((_pest: Pest) => _pest.id !== pest.id)
        )
      );
    else
      dispatch(
        creatingServiceActions.addImportedPests([
          ...service.importedPests,
          { id: pest.id, name: pest.name },
        ])
      );
  };

  const handleComment = (value: string) => {
    dispatch(creatingServiceActions.addComment(value));
  };

  const setEatenQuantity = (value: number) => {
    dispatch(creatingServiceActions.setEatenQuantity(value));
  };
  const handleLoadingChange = (loading: boolean) => {
    setImageLoading(loading);
    if (onLoadingChange) onLoadingChange(loading);
  };

  useEffect(() => {
    dispatch(
      creatingServiceActions.addPests(
        service.importedPests.map((pest: any) => ({
          pestId: pest.id,
          quantity: 1,
          quantityAdult: null,
          quantityNymph: null,
        }))
      )
    );
  }, [dispatch, service.importedPests]);

  return (
    <div className={styles["content-wrapper"]}>
      <div className={styles["infastation-type"]}>
        <p className={styles["infastation-type__title"]}>
          {t("InfestationType")}
        </p>
        <p className={styles["infastation-type__description"]}>
          {t("Quantity in percent of bait eaten")}
        </p>
        <CustomSlider
          value={service.eatenQuantity ?? 0}
          setValue={setEatenQuantity}
        />
        <div className={styles["infastation-type__pests"]}>
          {pests?.map((pest: Pest) => (
            <div
              key={pest.id}
              className={styles["infastation-type__pests__item"]}
              onClick={() => handleImportedPests(pest)}
            >
              <p>{pest.name}</p>
              <CheckCircle
                className={
                  pestExist(pest.id)
                    ? styles["infastation-type__pests__item--checked"]
                    : styles["infastation-type__pests__item--unchecked"]
                }
              />
            </div>
          ))}
          <div className={styles["infastation-type__pests__other"]}>
            <p>{t("Sonstige")}</p>
            <textarea
              value={service.comment}
              onChange={(e) => handleComment(e.target.value)}
              placeholder={`${t("AdditionalText")}`}
              className={styles["infastation-type__pests__other--textarea"]}
            />
          </div>
        </div>
      </div>
      <CustomCollapse onClick={handleMaterialsClicked} products={products}>
        Arbeits-, Material- und Präparateeinsatz
      </CustomCollapse>

      <MultiImageUploader2
        images={service.images}
        onImageUpload={handleImageUploaded}
        onDeleteImage={handleImageDelete}
        onLoadingChange={handleLoadingChange}
      />
    </div>
  );
};
