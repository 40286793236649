import { t } from "i18next";
import { useRef } from "react";

import { CalendarIcon } from "../../icons/CalendarIcon/CalendarIcon";

import styles from "./custom-picker.module.css";

type CustomPickerProps = {
  data: string[];
  label?: string;
  onScroll: (arg: number) => void;
  selected: string | null | undefined;
  variant?: "large" | "slim";
  background?: "light" | "dark";
  enableTranslation?: boolean;
  className?: string;
  disable?: boolean;
  withIcon?: boolean;
};

export const CustomPicker = ({
  data,
  label,
  onScroll,
  selected,
  variant = "slim",
  background = "light",
  enableTranslation = false,
  className = "",
  disable = false,
  withIcon = false,
}: CustomPickerProps) => {
  const options: string[] = ["", ...data, " "]; // For the beginning and ending empty space

  const ref = useRef<HTMLUListElement>(null);

  const handleScroll = () => {
    if (ref.current) {
      const listHeight = ref.current.clientHeight; // Calculate the height of the list
      const itemHeight = listHeight / 3; // Calculate the list of the one out of three visible items
      /**
       * EXPLANATION:
       * (ref.current.scrollTop + listHeight / 2): calculates the total distance from the top of the scrollable container to the point that is half the container's height.
       * (... / itemHeight): calculates how many items (each with a height of itemHeight) fit into the distance calculated above. This gives you an approximate index of the item that is around the center of the visible portion.
       * Math.floor(...): Since the division may result in a non-integer value (which cannot be an index), Math.floor is used to round down to the nearest integer.
       */
      const middleIndex: number = Math.floor(
        (ref.current.scrollTop + listHeight / 2) / itemHeight
      );

      //middleIndex-1 because it is never going to return index 0
      onScroll(middleIndex - 1); // Lift the state up
      // onScroll(options[middleIndex]); // Lift the state up
    }
  };

  return (
    <div className={styles["picker"]}>
      {label && (
        <div
          className={`${styles["header"]} ${
            withIcon && styles["header--flex"]
          }`}
        >
          {enableTranslation ? t(label) : label}
          {withIcon && <CalendarIcon />}
        </div>
      )}
      <ul
        className={`${styles["scrollable-list"]} ${
          variant === "large" && styles["scrollable-list--large"]
        } ${background === "dark" && styles["scrollable-list--dark"]}
        ${disable && styles["disable-scroll"]}
        `}
        ref={ref}
        onScroll={handleScroll}
      >
        {options.map((option, i) => (
          <li
            key={i}
            className={`${styles["list-item"]} ${
              variant === "large" && styles["list-item--large"]
            } ${selected === option ? styles["selected"] : ""} ${className}`}
          >
            {enableTranslation ? t(option) : option}
          </li>
        ))}
      </ul>
    </div>
  );
};
