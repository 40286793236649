import { MouseEventHandler, ReactNode } from "react";

import styles from "./tab-button.module.css";

type TabButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  active: boolean;
  children: ReactNode;
};

export const TabButton = ({
  onClick,
  active,
  children,
}: TabButtonProps): JSX.Element => (
  <button
    onClick={onClick}
    className={`${styles["tab-button"]} ${
      active ? styles["tab-button--clicked"] : ""
    }`}
  >
    {children}
  </button>
);
