import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AuthService from "../api/AuthService";
import LocalStorageService from "../api/LocalStorageService";
import { RootState } from "../store";
import { ROLES, userSliceActions } from "../store/user-slice";

interface AuthProps {
  email: string;
  password: string;
}

type AuthSuccessResponse = {
  accessToken: string;
  scope: {
    customerId: string;
    email: string;
    expirationDate: string;
    id: string;
    permissions: unknown;
    profileName: string;
    roles: string[];
    user: {
      id: string;
      type: string;
    };
  };
};

export const useAuth = ({ email, password }: AuthProps) => {
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [isSigningIn, setIsSigningIn] = useState<boolean>(false);
  const [user, setUser] = useState<AuthSuccessResponse["scope"] | null>(null);

  const { isOnline } = useSelector((state: RootState) => state.online);

  useEffect(() => {
    if (!isOnline) {
      setError(() => t("NoInternet"));
    } else {
      setError("");
    }
  }, [isOnline]);

  useEffect(() => {
    const token = LocalStorageService.get("accessToken");

    if (!token) {
      dispatch(userSliceActions.setIsSignedIn(false));
    }
  }, []);

  const handleClockodoError = () => {
    dispatch(userSliceActions.setAccessToken(null));
    LocalStorageService.removeAccessToken();
    dispatch(userSliceActions.setIsSignedIn(false));

    setError(() => t("NoClockodoAccount"));
  };

  const checkClockodoAccount = (email: string) => {
    AuthService.GetClockodoData(email)
      .then((clockodoUser) => {
        if (!clockodoUser.active) {
          return handleClockodoError();
        }

        dispatch(userSliceActions.setClockodoUser(clockodoUser));
        LocalStorageService.set({ clockodoUser: JSON.stringify(clockodoUser) });

        dispatch(userSliceActions.setIsSignedIn(true));
      })
      .catch(() => {
        handleClockodoError();
      })
      .finally(() => {
        setIsSigningIn(false);
      });
  };

  const handleLoginSuccess = ({ accessToken, scope }: AuthSuccessResponse) => {
    setUser(scope);

    dispatch(userSliceActions.setAccessToken(accessToken));
    dispatch(userSliceActions.setProfileName(scope.profileName));
    dispatch(userSliceActions.setRole(scope.roles));
    LocalStorageService.set({
      accessToken: accessToken,
      profileName: scope.profileName,
    });

    // Role handling
    if (
      scope.roles.includes(ROLES.CUSTOMER_ADMIN) ||
      scope.roles.includes(ROLES.PROPERTY_MANAGER)
    ) {
      dispatch(userSliceActions.setIsSignedIn(true));
      setIsSigningIn(false);
    } else {
      checkClockodoAccount(scope.email);
    }
  };

  const handleSignIn = () => {
    setError("");

    // Return if no internet connection
    if (!isOnline) {
      return;
    }
    // Empty email or password
    if (!email || !password) {
      return setError(() => t("WrongCredentials"));
    }

    setIsSigningIn(true);

    AuthService.SignIn({
      email,
      password,
    })
      .then(handleLoginSuccess)
      .catch(() => {
        setError(() => t("WrongCredentials"));
        setIsSigningIn(false);
      });
  };

  return {
    user,
    error,
    isSigningIn,
    handleSignIn,
  };
};
