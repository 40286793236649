import React from "react";

import { CheckIcon } from "../../icons/CheckIcon/CheckIcon";
import { PlusIcon } from "../../icons/PlusIcon/PlusIcon";

import styles from "./add-button.module.css";

type ColorScheme = "light" | "dark";

type AddButtonProps = {
  background: ColorScheme;
  iconColor: ColorScheme;
  onClick: () => void;
  selected: boolean;
};

export const AddButton: React.FC<AddButtonProps> = ({
  background,
  iconColor = "light",
  onClick,
  selected,
}) => {
  return (
    <button
      className={`${styles["add-button"]} ${
        selected && styles["add-button--dark"]
      }`}
      onClick={onClick}
    >
      {selected ? <CheckIcon /> : <PlusIcon color={iconColor} />}
    </button>
  );
};
