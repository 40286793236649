import { TimeTrackingEvent } from "../types/event";

import ApiService from "./ApiService";

type GetTimeTrackingEventsByAppointmentIdResponse = {
  data: TimeTrackingEvent[];
};

type GetTotalTimeByResponse = {
  data: {
    totalSeconds: number;
    firstEvent: TimeTrackingEvent;
    lastEvent: TimeTrackingEvent;
  };
};

export const TrackingEventService = {
  GetAllByAppointment: async (
    appointmentId: string
  ): Promise<GetTimeTrackingEventsByAppointmentIdResponse> => {
    return ApiService.Get(`time-tracking/events/appointment/${appointmentId}`);
  },
  GetAllByEmployee: async (
    employeeId: string
  ): Promise<GetTimeTrackingEventsByAppointmentIdResponse> => {
    return ApiService.Get(`time-tracking/events/employee/${employeeId}`, {
      sort: "-eventTimestamp",
    });
  },
  GetTotalTimeByAppointment: async (
    appointmentId: string
  ): Promise<GetTotalTimeByResponse> => {
    return ApiService.Get(
      `time-tracking/events/appointment/${appointmentId}/total`
    );
  },
  DeleteTimeTracking: async (
    timeTrackingId: string
  ): Promise<object> => {
    return ApiService.Delete(timeTrackingId, "time-tracking/events");
  }
};
