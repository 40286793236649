import clockLight from "./clock_icon_24px_light.png";

type ClockIconProps = {
  color?: "light"; // NOTE: Will add 'dark' in the future
};

const CLOCK_IMAGES: Record<"light", string> = {
  light: clockLight,
};

export const ClockIcon = ({ color = "light" }: ClockIconProps) => (
  <img src={CLOCK_IMAGES[color]} alt="Clock icon" height={24} />
);
