import { Check, PeopleAlt } from "@mui/icons-material";
import classNames from "classnames";
import { MouseEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import UserService from "../../api/clockodo/UserService";
import { NoConnectionModal } from "../../components/NoConnectionModal/NoConnectionModal";
import Clock from "../../components/Timer/Clock";
import { useClockNavigator } from "../../hooks/useClockNavigator";
import { useScreenSize } from "../../hooks/useScreenSize";
import { WarningIcon } from "../../icons/WarningIcon/WarningIcon";
import { RootState } from "../../store";
import { userSliceActions } from "../../store/user-slice";
import { workersActions } from "../../store/workers-slice";

import styles from "./header.module.css";

type HeaderProps = {
  withBackButton?: boolean;
  isMinimized?: boolean;
  onClick: Function;
  onGoBack?: MouseEventHandler<HTMLImageElement>;
};

export const Header = ({
  withBackButton,
  isMinimized,
  onClick,
  onGoBack,
}: HeaderProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { handleClockClick } = useClockNavigator();
  const { isDesktop } = useScreenSize();

  const [showNoConnectionModal, setShowNoConnectionModal] = useState(false);

  const isCheckedIn = useSelector((state: RootState) => state.user.isCheckedIn);
  const isOnline = useSelector((state: RootState) => state.online.isOnline);
  const profileName = useSelector((state: RootState) => state.user.profileName);
  const clockodoUser = useSelector(
    (state: RootState) => state.user.clockodoUser
  );
  const activeWorkers = useSelector(
    (state: RootState) => state.workers.totalActive
  );

  const { isRunning } = useSelector((state: RootState) => state.clock);

  const [activeWorkersMessage, setActiveWorkersMessage] = useState(
    "No coworkers online"
  );

  // Check if user is checked in (Clockodo)
  useEffect(() => {
    UserService.GetActiveUsers()
      .then(({ data: { entries } }) => {
        // Check if logged in user is checked in
        const checkedInUserEntry = entries.find(
          (entry) => entry.usersId === clockodoUser?.id
        );

        dispatch(userSliceActions.setIsCheckedIn(!!checkedInUserEntry));

        if (checkedInUserEntry) {
          dispatch(userSliceActions.setClockId(checkedInUserEntry.id));
        }

        // Update active users in redux state
        dispatch(
          workersActions.setActive(
            entries.map((entry) => ({
              id: entry.usersId,
              usersName: entry.usersName,
              initials: entry.usersName.substring(0, 2).toUpperCase(),
              text: "At Work",
              status: "working",
            }))
          )
        );
        dispatch(workersActions.setTotalActive(entries.length));
      })
      .catch();
  }, [clockodoUser?.id, dispatch]);

  useEffect(() => {
    if (activeWorkers > 0) {
      setActiveWorkersMessage(
        `${activeWorkers} coworker${activeWorkers > 1 ? "s" : ""} online`
      );
    }
  }, [activeWorkers]);

  const handleCloseModal = () => {
    setShowNoConnectionModal(false);
  };

  return (
    <div
      onClick={() => onClick()}
      className={classNames(
        styles["header"],
        { [styles["header--green"]]: isCheckedIn || isRunning },
        {
          [styles["desktop-header"]]: isDesktop,
        },
        {
          [styles["header--minimized"]]: isMinimized,
        }
      )}
    >
      {!isMinimized && isOnline && (
        <div className={styles["header__welcome-message"]}>
          <p>
            {t("Welcome") ?? ""}, {profileName}
          </p>
          !{isCheckedIn && " You are checked-in!"}
        </div>
      )}

      <div className={styles["header__actions"]}>
        {withBackButton && (
          <img alt="" src="/img/return-arrow.png" onClick={onGoBack} />
        )}

        {!withBackButton && (
          <button
            className={`${styles["status"]} ${
              !isOnline && styles["status--offline"]
            } ${
              !isOnline && isMinimized && styles["status--offline--minimized"]
            }`}
            onClick={() => {
              if (isOnline) {
                navigate("/coworkers");
              } else {
                setShowNoConnectionModal(true);
              }
            }}
          >
            {/* Button text when online */}
            {isOnline && !isMinimized && activeWorkersMessage}
            {isOnline && <PeopleAlt className={styles["status__icon"]} />}

            {/* Button text when offline */}
            {!isOnline && (
              <>
                <WarningIcon /> <span>{t("NoInternet")}</span>
              </>
            )}
          </button>
        )}

        {isMinimized && isRunning && (
          <div>
            <Clock onClick={handleClockClick} />
          </div>
        )}

        {!isCheckedIn && (
          <button
            className={styles["checkin"]}
            onClick={() => navigate("/check-in")}
          >
            <>Check-in {!isMinimized && <>👋</>}</>
          </button>
        )}

        {isCheckedIn && (
          <button
            className={styles["checkin"]}
            onClick={() => navigate("/check-in")}
          >
            <>
              <Check />
            </>
          </button>
        )}
      </div>
      {showNoConnectionModal && (
        <NoConnectionModal onClose={handleCloseModal} />
      )}
    </div>
  );
};
