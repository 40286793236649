import { MouseEventHandler } from "react";

import styles from "./no-data.module.css";

type NoDataProps = {
  children: React.ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const NoData = ({ children, onClick }: NoDataProps): JSX.Element => {
  return (
    <div onClick={onClick} className={styles["data__empty"]}>
      {children}
    </div>
  );
};
