import { MouseEventHandler, ReactNode } from "react";

import { AccountCircleIcon } from "../../icons/AccountCircleIcon/AccountCircleIcon";
import { CameraIcon } from "../../icons/CameraIcon/CameraIcon";
import { CollapseArrowIcon } from "../../icons/CollapseArrow/CollapseArrow";
import { LayersIcon } from "../../icons/LayersIcon/LayersIcon";
import { MapIcon } from "../../icons/MapIcon/MapIcon";
import { PlusIcon } from "../../icons/PlusIcon/PlusIcon";

import styles from "./block-button.module.css";

type BlockButtonProps = {
  children: ReactNode;
  leftIcon: "plus" | "plusLight";
  hideLeftIcon?: boolean;
  rightIcon:
    | "layers"
    | "map"
    | "camera"
    | "account"
    | "accountDark"
    | "collapseArrow"
    | "collapseArrowRotated";
  onClick: MouseEventHandler<HTMLButtonElement>;
  onLeftIconClick?: MouseEventHandler<HTMLImageElement>;
  standalone?: boolean;
  color?: "gray" | "default" | "light-gray";
  readonly?: boolean;
};

const LEFT_ICON: Record<BlockButtonProps["leftIcon"], JSX.Element> = {
  plus: <PlusIcon />,
  plusLight: <PlusIcon color="light" />,
};

const RIGHT_ICON: Record<BlockButtonProps["rightIcon"], JSX.Element> = {
  layers: <LayersIcon />,
  map: <MapIcon />,
  camera: <CameraIcon />,
  account: <AccountCircleIcon />,
  accountDark: <AccountCircleIcon color="dark" />,
  collapseArrow: <CollapseArrowIcon />,
  collapseArrowRotated: <CollapseArrowIcon reversed />,
};

const COLORS: Record<"gray" | "default" | "light-gray", string> = {
  default: "",
  gray: styles["block-button--gray"],
  "light-gray": styles["block-button--light-gray"],
};

export const BlockButton = ({
  children,
  rightIcon,
  hideLeftIcon = false,
  leftIcon,
  onClick,
  onLeftIconClick,
  standalone = true,
  color = "default",
  readonly = false,
}: BlockButtonProps): JSX.Element => {
  return (
    <button
      className={`${styles["block-button"]} ${
        standalone && styles["block-button--standalone"]
      } ${COLORS[color]} ${readonly && styles["block-button--readonly"]}`}
      onClick={onClick}
    >
      <span className={styles["block-button__left"]}>{children}</span>
      <span className={styles["block-button__right"]}>
        {!hideLeftIcon && !readonly && (
          <span onClick={onLeftIconClick}>{LEFT_ICON[leftIcon]}</span>
        )}
        {RIGHT_ICON[rightIcon]}
      </span>
    </button>
  );
};
