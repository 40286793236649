import { MouseEventHandler } from "react";

import styles from "./return-arrow.module.css";
import returnArrow from "./return-arrow.png";

type ReturnArrowIconProps = {
  handleBackButtonClicked: MouseEventHandler<HTMLImageElement>;
};

export const ReturnArrowIcon = ({
  handleBackButtonClicked,
}: ReturnArrowIconProps) => (
  <img
    className={styles["return-arrow"]}
    src={returnArrow}
    alt="Return arrow icon"
    onClick={handleBackButtonClicked}
  />
);
