import { MinimalProduct } from "../../types/product";
import { MaterialListItem } from "../MaterialListItem/MaterialListItem";

import styles from "./materials-list.module.css";

type MaterialsListProps = {
  title?: string | null;
  data: MinimalProduct[] | [];
  readOnly?: boolean;
};

export const MaterialsList = ({
  title,
  data,
  readOnly,
}: MaterialsListProps) => {
  return (
    <div className={styles["materials-list"]}>
      {title && <h1 className={styles["materials-list__title"]}>{title}</h1>}

      <ul>
        {data?.map((product: MinimalProduct, i) => (
          <MaterialListItem key={i} product={product} readOnly={readOnly} />
        ))}
      </ul>
    </div>
  );
};
