import { createSlice } from "@reduxjs/toolkit";

import { Appointment } from "../types/appointment";

export type AppointmentsSliceState = {
  today: Appointment[];
  tomorrow: Appointment[];
  future: Appointment[];
};

const initialState: AppointmentsSliceState = {
  today: [],
  tomorrow: [],
  future: [],
};

export const appointmentsSlice = createSlice({
  name: "appointments",
  initialState: initialState,
  reducers: {
    setAppointments(state, { payload }: { payload: AppointmentsSliceState }) {
      state.today = payload.today;
      state.tomorrow = payload.tomorrow;
      state.future = payload.future;
    },
    removeAppointment(state, { payload }: { payload: string }) {
      state.today = state.today.filter((item) => item.id !== payload);
      state.tomorrow = state.tomorrow.filter((item) => item.id !== payload);
      state.future = state.future.filter((item) => item.id !== payload);
    },
  },
});

export const appointmentsActions = appointmentsSlice.actions;
