import { DirectionsCar } from "@mui/icons-material";
import classNames from "classnames";
import { t } from "i18next";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import CheckInService from "../../api/clockodo/CheckInService";
import { RootState } from "../../store";
import { userSliceActions } from "../../store/user-slice";
import { workersActions } from "../../store/workers-slice";
import styles from "../../styles/check-in.module.css";

const MAXIMUM_KILOMETERS = 10_000_000;

const CheckIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isEnteringKm, setIsEnteringKm] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const isCheckedIn = useSelector((state: RootState) => state.user.isCheckedIn);
  const clockodoUser = useSelector(
    (state: RootState) => state.user.clockodoUser
  );
  const clockId = useSelector((state: RootState) => state.user.clockId);

  const [kilometers, setKilometers] = useState<number>(
    +(clockodoUser?.lastEndingMileage ?? 0)
  );

  const handleError = (error: any) => {
    if (error.status === 422) {
      setError(() => t("InvalidMileage"));
    } else {
      setError(() => t("UnknownError"));
    }
  };

  const handleSubmit = (km: number) => {
    if (isCheckedIn && clockodoUser && clockId) {
      CheckInService.CheckOut({
        users_id: clockodoUser.id,
        clockId: clockId,
        ending_mileage: km,
      })
        .then(() => {
          dispatch(userSliceActions.setMileage(km));
          dispatch(userSliceActions.setIsCheckedIn(false));
          dispatch(userSliceActions.setClockId(null));

          // Update state
          dispatch(workersActions.removeUserFromActive(clockodoUser.id));
          dispatch(
            workersActions.addUserToNotCheckedIn({
              id: clockodoUser.id,
              usersName: clockodoUser.name,
              initials: clockodoUser.initials,
              text: "Absent",
              status: "absent",
            })
          );
          navigate("/");
        })
        .catch((error) => {
          handleError(error);
        });
    } else {
      if (clockodoUser) {
        CheckInService.CheckIn({
          users_id: clockodoUser.id,
          starting_mileage: km,
        })
          .then(({ clock }) => {
            dispatch(userSliceActions.setMileage(km));
            dispatch(userSliceActions.setIsCheckedIn(true));
            dispatch(userSliceActions.setClockId(clock.running.id));

            // Update state
            dispatch(
              workersActions.removeUserFromNotCheckedIn(clockodoUser.id)
            );
            dispatch(
              workersActions.addUserToActive({
                id: clockodoUser.id,
                usersName: clockodoUser.name,
                initials: clockodoUser.initials,
                text: "At Work",
                status: "working",
              })
            );
            navigate("/");
          })
          .catch((error) => {
            handleError(error);
          });
      }
    }
  };

  return (
    <div className={styles["check-in"]}>
      {!isEnteringKm && (
        <button
          onClick={() => setIsEnteringKm(true)}
          className={styles["check-in__button"]}
        >
          <DirectionsCar />
          Kilometerstand eintragen
        </button>
      )}

      {isEnteringKm && (
        <>
          <div
            className={classNames(styles["check-in__km"], {
              [styles["check-in__km--entered"]]: !!kilometers,
            })}
          >
            <h1>Aktuellen Kilometerstand eintragen:</h1>
            <div className={styles["check-in__km--container"]}>
              <input
                className={styles["check-in__km--input"]}
                type="number"
                value={kilometers || ""}
                onChange={(e) => {
                  let newValue = parseInt(e.target.value);
                  setError("");
                  setKilometers(
                    Math.min(Math.max(newValue, 0), MAXIMUM_KILOMETERS)
                  );
                }}
                max="1000000"
              />
              <div className={styles["check-in__km--unit"]}>km</div>
            </div>
          </div>

          {error && <div className={styles["check-in__error"]}>{error}</div>}

          <button
            className={styles["check-in__button"]}
            onClick={() => handleSubmit(kilometers)}
            disabled={!kilometers}
          >
            {!!kilometers ? (
              <>
                <DirectionsCar />
                Kilometerstand bestätigen
              </>
            ) : (
              <>
                <DirectionsCar />
                Kilometerstand eintragen"
              </>
            )}
          </button>
        </>
      )}
    </div>
  );
};

export default CheckIn;
