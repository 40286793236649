import { createSlice } from "@reduxjs/toolkit";

export type technicianDocumentsState = {
  empty: boolean;
};

const initialState: technicianDocumentsState = {
  empty: false,
};

export const technicianDocumentsSlice = createSlice({
  name: "technicianDocuments",
  initialState,
  reducers: {
    setIsEmpty(state: technicianDocumentsState, action: { payload: boolean }) {
      state.empty = action.payload;
    },
  },
});

export const technicianDocumentsActions = technicianDocumentsSlice.actions;
