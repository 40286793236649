import { t } from "i18next";
import { Fragment } from "react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import DeficiencyService from "../../api/DeficiencyService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomCollapseWithChildren } from "../../components/CustomCollapseWithChildren/CustomCollapseWithChildren";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { CustomTextArea } from "../../components/CustomTextArea/CustomTextArea";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { MaterialsList } from "../../components/MaterialsList/MaterialsList";
import Modal from "../../components/Modal/Modal";
import NoProductDocumentsModal from "../../components/Modal/NoProductDocumentsModal";
import PinCropMap from "../../components/PinCropMap/PinCropMap";
import { ResourceImages } from "../../components/ResourceImages/ResourceImages";
import { TechniciansList } from "../../components/TechniciansList/TechniciansList";
import { TimeTrackingOverview } from "../../components/TimeTrackingOverview/TimeTrackingOverview";
import { useDocumentation } from "../../hooks/useDocumentation";
import { useDownloadFiles } from "../../hooks/useDownloadFiles";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { RootState } from "../../store";
import { setAppointment } from "../../store/appointment-slice";
import { deficiencyServiceActions } from "../../store/deficiency-service-slice";
import styles from "../../styles/deficiency-service-overview.module.css";
import { Appointment } from "../../types/appointment";
import { Deficiency } from "../../types/deficiencies";
import { Document } from "../../types/document";
import { ImageProps } from "../../types/image";
import { PropertyArea } from "../../types/property";
import { Technician } from "../../types/user";

type DeficiencyStateType = {
  data: Deficiency;
  includes?: {
    propertyArea?: PropertyArea[];
    appointments?: Appointment[];
    documents?: ImageProps[];
  };
};

export const DeficiencyServiceOverviewReadonly = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { deficiencyId } = useParams();

  const [loading, setLoading] = useState(false);
  const [deficiency, setDeficiency] = useState<DeficiencyStateType | null>(
    null
  );
  const { fetchDocumentation } = useDocumentation();

  const isEmpty = useSelector(
    (state: RootState) => state.technicianDocuments.empty
  );

  const { closeModal } = useDownloadFiles();

  const customerAdmin = useSelector((state: RootState) => state.user);

  const performedActions = deficiency?.data?.performedActions?.join("\n");

  const Kunde: Technician = {
    id: customerAdmin.isCustomerAdmin ? customerAdmin.currentUser.id : "101",
    firstName: customerAdmin.isCustomerAdmin
      ? customerAdmin.currentUser.firstName
      : "Kunde",
    lastName: customerAdmin.isCustomerAdmin
      ? customerAdmin.currentUser.lastName
      : "",
    profileName: customerAdmin.isCustomerAdmin
      ? customerAdmin.currentUser.profileName
      : "Kunde",
  };

  useEffect(() => {
    if (!deficiencyId) {
      return;
    }
    setLoading(true);
    DeficiencyService.GetDeficiency(deficiencyId)
      .then((deficiency) => {
        setDeficiency(deficiency);
        if (deficiency?.includes?.appointments?.[0])
          dispatch(setAppointment(deficiency?.includes?.appointments?.[0]));
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [deficiencyId, dispatch, setAppointment]);

  const images = deficiency?.includes?.documents ?? [];
  const appointmentId = deficiency?.includes?.appointments?.[0]?.id ?? "";
  const comment = deficiency?.includes?.appointments?.[0]?.comment ?? "";
  const map = useMemo(() => {
    const [propertyArea] = deficiency?.includes?.propertyArea || [];

    return propertyArea?.map;
  }, [deficiency?.includes?.propertyArea]);

  const products = useMemo(
    () => [
      ...(deficiency?.data.usedProducts ?? []),
      ...(deficiency?.data.sevdeskProducts ?? []),
    ],
    [deficiency]
  );

  const isExterminatorResponsibility =
    deficiency?.data.responsibility === "exterminator";
  const technicians: Technician[] = isExterminatorResponsibility
    ? deficiency?.includes?.appointments?.[0]?.technicians ?? []
    : [Kunde];

  const handleTextareaChange = (note: string) => {
    dispatch(deficiencyServiceActions.setComment(note));
  };

  const handleBackButtonClicked = () => {
    navigate(-1);
  };

  const fetchDocument = () => {
    const document = deficiency?.data.documents?.find(
      (doc: Document) => doc.documentType === "receipt"
    );
    fetchDocumentation(document?.url);
  };

  const monitorPinAbbr = useMemo(
    () => ({
      name: deficiency?.data.deficiencyType?.slice(0, 3).toUpperCase() ?? "",
      number: "001",
    }),
    [deficiency?.data.deficiencyType]
  );

  return (
    <AppLayout hideHeader>
      <BackButtonHeader
        handleBackButtonClicked={handleBackButtonClicked}
        handleFetchDocument={fetchDocument}
        isEditedByCustomer={
          deficiency?.data.changedBy.type == "Customer" ? true : false
        }
      />
      <AppointmentDetails isDeficiencyOverview showMangelTag />

      <Modal
        isOpen={isEmpty}
        message={t("NoInformation")}
        note={"NoAvailableDocuments"}
        showNoInformationIcon
        confirmText={"Back"}
        onConfirm={closeModal}
      />

      <NoProductDocumentsModal />

      {loading && <LoadingSpinner loading={loading} />}

      {!loading && (
        <>
          <div className={styles["content-wrapper"]}>
            {deficiency?.data?.responsibility == "exterminator" && (
              <TimeTrackingOverview appointmentId={appointmentId} />
            )}

            <TechniciansList technicians={technicians} />

            <div className={styles.materials}>
              <CustomInput
                label={t("Remark")}
                placeholder=""
                hideTick
                initialValue={comment}
                readonly
              />
            </div>

            <div className={styles["map"]}>
              <CustomCollapseWithChildren
                active={!!deficiency?.includes?.propertyArea?.map}
                collapsedByDefault
                rightIcon="collapseArrow"
                label={t("Map")}
                defaultText={t("NoMapAvailable")}
              >
                <div className={styles["map-content"]}>
                  <PinCropMap
                    map={map}
                    crop={deficiency?.data.mapPosition?.mapPoint ?? { x: 1, y: 1 }}
                    onCropChange={() => { }}
                    monitorPinAbbr={monitorPinAbbr}
                    isDraggable={false}
                    zoom={false}
                  />
                </div>
              </CustomCollapseWithChildren>
            </div>

            {products.length > 0 ? (
              <div className={styles.materials}>
                <MaterialsList
                  title={t("Materials")}
                  data={products}
                  readOnly={true}
                />
              </div>
            ) : null}

            <div className={styles["actions"]}>
              <h1 className={styles["actions__title"]}>{t("Actions")}</h1>
              <h5 className={styles["actions__content"]}>
                {performedActions?.split("\n").map((line, index) => (
                  <Fragment key={index}>
                    {line}
                    <br />
                  </Fragment>
                ))}
              </h5>
            </div>

            <ResourceImages images={images} />

            <CustomTextArea
              placeholder={t("AdditionalText")}
              onTextareaChange={handleTextareaChange}
              defaultValue={deficiency?.data.comment ?? ""}
              readonly
            />
          </div>
        </>
      )}
    </AppLayout>
  );
};
