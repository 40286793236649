import defectDark from "./defect_dark.png";
import defectLight from "./defect_light.png";

type DefectIconProps = {
  color?: "dark" | "light";
};

const DEFECT_IMAGES: Record<"dark" | "light", string> = {
  dark: defectDark,
  light: defectLight,
};

export const DefectIcon = ({ color }: DefectIconProps) => (
  <img src={DEFECT_IMAGES[color || "dark"]} alt="Defect icon" />
);
