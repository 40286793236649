import classNames from "classnames";
import { ReactNode } from "react";

import styles from "./floating-buttons.module.css";

type FloatingButtonsProps = {
  children: ReactNode;
  justify?: "right";
};

export const FloatingButtons = ({
  children,
  justify,
}: FloatingButtonsProps) => {
  const floatingButtonsClasses = classNames(styles.wrapper, {
    [styles["justify-right"]]: justify === "right",
  });

  return <div className={floatingButtonsClasses}>{children}</div>;
};
