import { MouseEventHandler } from "react";

import styles from "./close-full-screen.module.css";
import FullScreenIcon from "./close-full-screen.png";

type CloseFullScreenIconProps = {
  onClick: MouseEventHandler<HTMLImageElement>;
};

export const CloseFullScreenIcon = ({ onClick }: CloseFullScreenIconProps) => (
  <img
    className={styles["container"]}
    src={FullScreenIcon}
    alt="Remove icon"
    onClick={onClick}
  />
);
