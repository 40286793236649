import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "../store";
import { Appointment } from "../types/appointment";
import { Deficiency } from "../types/deficiencies";

export const useUnauthorizedAccess = (
  data: Appointment | Deficiency | null | undefined
) => {
  const navigate = useNavigate();
  const { isCustomerAdmin, currentUser } = useSelector(
    (state: RootState) => state.user
  );

  useEffect(() => {
    if (
      data === null ||
      (isCustomerAdmin && data && data.customerId !== currentUser.customerId)
    )
      navigate("/");
  }, [data]);

  return {};
};
