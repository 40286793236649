import { t } from "i18next";
import moment from "moment";
import { MouseEventHandler } from "react";

import { Status } from "../../types/absence";
import { Badge } from "../Badge/Badge";

import styles from "./absence-card.module.css";
import hinweisImage from "./hinweis.png";

type AbsenceDetailsProps = {
  status: Status;
  type: string;
  dateFrom: string;
  dateUntil: string;
  countDays: number;
  countHours: number;
  sickNote: boolean | null;
  color?: "green" | "white";
  onClick?: MouseEventHandler<HTMLDivElement>;
  asHeader?: boolean;
};

type AdditionalInfoFunction = (param: any) => string | undefined;

const STATUS: Record<Status, "reported" | "approved" | "declined"> = {
  "enquired/reported": "reported",
  approved: "approved",
  declined: "declined",
  "approval cancelled": "declined",
  "request cancelled": "declined",
};

const BACKGROUND_COLOR: Record<any, any> = {
  white: styles["card--white"],
  green: styles["card--green"],
};

// Define a constant object to map type values to additionalInfos strings
const ADDITIONAL_INFOS: Record<string, AdditionalInfoFunction> = {
  "Regular holiday": (countDays: number) =>
    countDays === 0.5 ? "Halber Tag" : undefined,
  "Reduction of overtime": (countHours: number) =>
    countHours > 0 ? `Anzahl der Stunden: ${countHours}` : undefined,
  "Sick day": (sickNote: boolean) =>
    sickNote === true ? "Mit Krankschreibung" : "Ohne Krankschreibung",
  "Sick day of a child": (sickNote: boolean) =>
    sickNote === true ? "Mit Krankschreibung" : "Ohne Krankschreibung",
};

export const AbsenceCard = ({
  status,
  type,
  dateFrom,
  dateUntil,
  countDays,
  countHours,
  sickNote,
  color = "white",
  onClick,
  asHeader = false,
}: AbsenceDetailsProps) => {
  const isBeforeToday = moment(dateUntil).isBefore(moment(), "day");
  const from = moment(dateFrom).format("DD.MM.");
  const to = moment(dateUntil).format("DD.MM.");

  const PARAMETER: Record<string, number | boolean | null> = {
    "Regular holiday": countDays,
    "Reduction of overtime": countHours,
    "Sick day": sickNote,
    "Sick day of a child": sickNote,
  };

  // Use the ADDITIONAL_INFOS object to determine additionalInfos
  const additionalInfos = ADDITIONAL_INFOS[type]?.(PARAMETER[type]);

  return (
    <div
      className={`${styles["card"]} ${BACKGROUND_COLOR[color]} ${
        isBeforeToday && !asHeader && styles["card--grayed-out"]
      }`}
      onClick={onClick}
    >
      <div
        className={`${styles["card__date"]} ${
          STATUS[status] === "approved" ? styles["card__date--approved"] : ""
        } ${from !== to && styles["card__date--small"]} `}
      >
        <span>{from}</span>
        {from !== to && (
          <>
            <span>-</span>
            <span>{to}</span>
          </>
        )}
      </div>

      <div className={styles["card__info"]}>
        <h3 className={styles["card__info__title"]}>{t(type)}</h3>
        <i className={styles["card__info__additional-infos"]}>
          {additionalInfos}
        </i>
        <Badge type={STATUS[status]} />
      </div>

      {STATUS[status] === "declined" && (
        <img className={styles["exclamation"]} alt="" src={hinweisImage} />
      )}
    </div>
  );
};
