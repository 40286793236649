import { ClockodoUser } from "../types/user";

import ApiService from "./ApiService";

type SignInResponse = {
  accessToken: string;
  tokenType: string;
  scope: any;
};

const AuthService = {
  SignIn: async (data: {
    email: string;
    password: string;
  }): Promise<SignInResponse> => {
    return ApiService.Post(`auth/login`, data);
  },
  GetClockodoData: async (email: string): Promise<ClockodoUser> => {
    return ApiService.Get(`clockodo/user`, {
      email,
    });
  },
  SignOut: async (): Promise<object> => {
    return ApiService.Post(`auth/logout`, null);
  },
};

export default AuthService;
