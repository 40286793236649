import { Message, Phone } from "@mui/icons-material";
import classNames from "classnames";

import styles from "./worker-status.module.css";

type WorkerStatusProps = {
  worker: {
    id: string;
    usersName: string;
    initials: string;
    text: string;
    status: "working" | "holiday" | "absent";
  };
};

const WorkerStatus = ({ worker }: WorkerStatusProps) => {
  const [in1, in2] = worker.initials.split("");

  return (
    <div
      className={classNames(styles["worker"], {
        [styles["worker--absent"]]: worker.status !== "working",
      })}
    >
      <div className={styles["worker__avatar"]}>
        <img
          alt={`${worker.usersName}'s avatar`}
          src={`https://ui-avatars.com/api/?name=${in1}+${in2}`}
        />
        <div
          className={classNames(styles["worker__status"], {
            [styles["worker__status--online"]]: worker.status === "working",
            [styles["worker__status--absent"]]: worker.status === "holiday",
          })}
        />
      </div>

      <div className={styles["worker__info"]}>
        <h1>{worker.usersName}</h1>
        <h2>{worker.text}</h2>
      </div>

      <div className={styles["worker__contact"]}>
        <a href={`sms:${""}`} onClick={() => console.log("SMS")}>
          <Message />
        </a>
        <a href={`tel:${""}`} onClick={() => console.log("Phone")}>
          <Phone />
        </a>
      </div>
    </div>
  );
};

export default WorkerStatus;
