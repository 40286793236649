import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import UserService from "../../api/UserService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { SubMenu } from "../../components/SubMenu/SubMenu";
import { TechnicianListItem } from "../../components/TechnicianListItem/TechnicianListItem";
import { AccountCircleIcon } from "../../icons/AccountCircleIcon/AccountCircleIcon";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { go } from "../../services/NavigationService";
import { RootState } from "../../store";
import styles from "../../styles/choose-technicians.module.css";
import { Technician } from "../../types/user";

export const ChooseTechnicians = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const firstOpen = state?.firstOpen;

  const [users, setUsers] = useState<Technician[]>([]);

  const technicians = useSelector((state: RootState) => state.technicians);
  const isDeficiency = useSelector(
    (state: RootState) => state.deficiency.inProgress
  );

  // Order technicians
  const selectedTechnicians = users.filter((user) =>
    technicians.some((tech) => tech.id === user.id)
  );
  const unselectedTechnicians = users.filter(
    (user) => !technicians.some((tech) => tech.id === user.id)
  );

  const orderedUsers = [...selectedTechnicians, ...unselectedTechnicians];

  const fetchUsers = useCallback(async () => {
    try {
      const { data } = await UserService.GetTechnicians();
      setUsers(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const onFinish = () => {
    if (isDeficiency) {
      navigate("/deficiencies/create/step-3");
    } else {
      go(navigate, "/appointments/create", {
        techniciansSelected: true,
      });
    }
  };

  return (
    <AppLayout hideHeader={true} className="calendar-wrapper-content">
      <div>
        <BackButtonHeader handleBackButtonClicked={onFinish} />
        <AppointmentDetails />

        <div className={styles["content-wrapper"]}>
          <div className={styles["content"]}>
            {/* If we are opening this page for the first time, 
            show first version of design from Figma */}
            {firstOpen && (
              <>
                <SubMenu showSearchButton={false} stretched={true}>
                  Verantwortlichen hinzufügen
                  <AccountCircleIcon color="dark" />
                </SubMenu>
                <div className={styles["technician-list"]}>
                  {orderedUsers.map((user: Technician, i: number) => {
                    const selected = technicians.some(
                      (tech) => tech.id === user.id
                    );
                    return (
                      <TechnicianListItem
                        key={i}
                        user={user}
                        selected={selected}
                        darkBackground={selected}
                      />
                    );
                  })}
                </div>
              </>
            )}
            {/* If we are NOT opening this page for the first time, 
            show second version of design from Figma */}
            {!firstOpen && (
              <>
                <SubMenu showSearchButton={false} stretched={true}>
                  Verantwortliche
                  <AccountCircleIcon color="dark" />
                </SubMenu>

                <div className={styles["technician-list"]}>
                  {selectedTechnicians.map((user: Technician, i: number) => {
                    const selected = technicians.some(
                      (tech) => tech.id === user.id
                    );
                    return (
                      <TechnicianListItem
                        key={i}
                        user={user}
                        selected={selected}
                        darkBackground={false}
                      />
                    );
                  })}
                </div>

                <SubMenu showSearchButton={false}>weitere hinzufügen</SubMenu>

                <div className={styles["technician-list"]}>
                  {unselectedTechnicians.map((user: Technician, i: number) => {
                    const selected = technicians.some(
                      (tech) => tech.id === user.id
                    );
                    return (
                      <TechnicianListItem
                        key={i}
                        user={user}
                        selected={selected}
                        darkBackground={false}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>

        <FloatingButtons>
          <CustomButton color="gray" onClick={onFinish} disabled={false}>
            Hinzufügen
          </CustomButton>
        </FloatingButtons>
      </div>
    </AppLayout>
  );
};
