import { createSlice } from "@reduxjs/toolkit";

import { Pest } from "../types/pest";

export type SettingsSliceState = {
  pests: Pest[] | [];
};

type PestsAction = {
  type: string;
  payload: { pests: Pest[] };
};

const initialState: SettingsSliceState = {
  pests: [],
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    setPests(state: SettingsSliceState, action: PestsAction) {
      state.pests = action.payload.pests;
    },
    resetState(state: SettingsSliceState) {
      return { ...initialState };
    },
  },
});

export const settingsActions = settingsSlice.actions;
