import { UsePDFInstance } from "@react-pdf/renderer";
import { now } from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BackEndService from "../api/BackEndService";
import { generateUUIDFromID } from "../services/UUIDGeneratorService";
import { RootState } from "../store";
import {
  Attachment,
  creatingDocumentationActions,
} from "../store/documentation-slice";

export const useDocumentation = () => {
  const dispatch = useDispatch();
  const [requestLoading, setLoading] = useState(false);
  const [noDocument, setNoDocument] = useState(false);

  const documentation = useSelector((state: RootState) => state.documentation);

  const generateFormData = (instance: UsePDFInstance) => {
    if (!instance.url || !instance.blob) return;

    const formData = new FormData();
    formData.append("uuid", generateUUIDFromID(now().toString()));
    formData.append("name", instance.url);
    formData.append("file", instance.blob, "receipt.pdf");

    return formData;
  };

  const savePDF = async (instance: UsePDFInstance, attachedTo: Attachment) => {
    const formData = generateFormData(instance);

    try {
      const image: any = await BackEndService.UploadImage(formData);
      const document: any = await BackEndService.AddDocument({
        documentType: "receipt",
        fileName: "receipt.pdf",
        mimeType: "application/pdf",
        fileSize: instance.blob?.size,
        isInternal: true,
        url: instance.url,
        media: image.uuid,
        attachedTo: attachedTo,
      });
      dispatch(
        creatingDocumentationActions.setExistingReceiptId(document.data.id)
      );
      dispatch(creatingDocumentationActions.setExistingReceipt(true));
    } catch (error) {
      throw error;
    }
  };

  const updatePDF = async (instance: UsePDFInstance) => {
    const formData = generateFormData(instance);

    try {
      const image: any = await BackEndService.UploadImage(formData);
      const document: any = await BackEndService.UpdateDocument(
        documentation.existingReceiptId,
        {
          fileName: "receipt.pdf",
          fileSize: instance.blob?.size,
          isInternal: true,
          url: image.name,
        }
      );
      dispatch(
        creatingDocumentationActions.setExistingReceiptId(document.data.id)
      );
      dispatch(creatingDocumentationActions.setExistingReceipt(true));
    } catch (error) {
      throw error;
    }
  };

  const generatePDF = () => { };

  const sendPDFviaEmail = () => { };

  const fetchDocumentation = (url: string | undefined) => {
    if (!url) {
      setNoDocument(true);
      return;
    }

    if (setLoading) setLoading(true);

    window.open(url);

    if (setLoading) setLoading(false);
  };

  return {
    fetchDocumentation,
    generatePDF,
    sendPDFviaEmail,
    savePDF,
    updatePDF,
    requestLoading,
    noDocument,
    setNoDocument,
  };
};
