import map from "./map_24px_dark.png";

type MapIconProps = {
  color?: "dark";
};

const MAP_IMAGES: Record<"dark", string> = {
  dark: map,
};

export const MapIcon = ({ color }: MapIconProps) => (
  <img src={MAP_IMAGES[color || "dark"]} alt="Map icon" />
);
