import moment from "moment";
import { ReactNode, useState } from "react";
import { useDispatch } from "react-redux";
import { animated, useSpring } from "react-spring";
import { useSwipeable } from "react-swipeable";

import AppointmentService from "../../api/AppointmentService";
import { TrackingEventService } from "../../api/TrackingEventService";
import { useAppointment } from "../../hooks/useAppointment";
import { appointmentsActions } from "../../store/appointments-slice";
import { Appointment } from "../../types/appointment";
import Modal from "../Modal/Modal";

import deleteIcon from "./image 11.png";
import styles from "./swipeable.module.css";

type TaskCardProps = {
  appointment: Appointment;
  children: ReactNode;
};

export const Swipeable = ({ appointment, children }: TaskCardProps) => {
  const dispatch = useDispatch();

  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { fetchAppointments } = useAppointment(
    {
      "filter[sinceDate]": moment().format("YYYY-MM-DD"),
    },
    true
  );

  const handleSwipeLeft = () => {
    setIsDeleteVisible(true);
  };

  const handleSwipeRight = () => {
    setIsDeleteVisible(false);
  };

  const handleDelete = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDelete = async (taskId: number) => {
    try {
      if (appointment.id) {
        const {
          data: trackingEvents,
        } = await TrackingEventService.GetAllByAppointment(appointment.id);

        const deletePromises = trackingEvents.map((event: { id: string }) =>
          TrackingEventService.DeleteTimeTracking(event.id)
        );

        await Promise.all(deletePromises);

        AppointmentService.DeleteAppointment(appointment.id)
          .then(() => {
            if (appointment.id) {
              dispatch(appointmentsActions.removeAppointment(appointment.id));
              setIsDeleteVisible(false);
            }
          })
          .catch((error) => {
            console.log(error);
          });

        setIsModalOpen(false);
        fetchAppointments();
      }
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
  });

  const taskCardAnimation = useSpring({
    width: isDeleteVisible ? "calc(100% - 45px)" : "calc(100% - 0px)",
    config: { duration: 200 },
  });

  const deleteButtonAnimation = useSpring({
    width: isDeleteVisible ? "41px" : "0px",
    config: { duration: 200 },
  });

  return (
    <div {...swipeHandlers}>
      <div className={styles["swipeable-container"]}>
        <animated.div
          className={styles["swipeable-card"]}
          style={taskCardAnimation}
        >
          {children}
        </animated.div>
        {isDeleteVisible && (
          <animated.div
            className={styles["delete-button"]}
            style={deleteButtonAnimation}
          >
            <button onClick={handleDelete}>
              <img src={deleteIcon} alt="Delete button icon" />
            </button>
          </animated.div>
        )}
        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onCancel={handleCloseModal}
            onConfirm={() => handleConfirmDelete(Number(appointment.id))}
          />
        )}
      </div>
    </div>
  );
};
