import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AbsenceService from "../../api/clockodo/AbsenceService";
import { AbsenceCard } from "../../components/AbsenceCard/AbsenceCard";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { NoData } from "../../components/NoData/NoData";
import PullToRefresh from "../../components/PullToRefresh/PullToRefresh";
import { TabButton } from "../../components/TabButton/TabButton";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { navigateToAbsenceInsight } from "../../services/NavigationService";
import { RootState } from "../../store";
import styles from "../../styles/absences.module.css";

type Tab = {
  tab: 1 | 2;
  text: string;
};

const TABS: Tab[] = [
  {
    tab: 1,
    text: "PersonalOverview",
  },
  {
    tab: 2,
    text: "Jobs",
  },
];

export const Absences = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<1 | 2>(1);
  const [absences, setAbsences] = useState<any>([]);

  const currentPage = useSelector((state: RootState) => state.tabs.calendar);
  const clockodoUserId = useSelector(
    (state: RootState) => state.user.clockodoUser?.id
  );

  const fetchAbsences = useCallback(async () => {
    if (clockodoUserId) {
      try {
        const { data } = await AbsenceService.GetAbsences(clockodoUserId);
        setAbsences(data);
      } catch (error) {
        console.log(error);
      }
    }
    // Return a resolved promise if clockodoUserId is not available
    return Promise.resolve();
  }, [clockodoUserId]);

  useEffect(() => {
    fetchAbsences();
  }, [fetchAbsences]);

  useEffect(() => {
    if (currentPage.page === "details") {
      navigate(`/calendar/${currentPage.data.id}/show`, {
        state: {
          absence: { ...currentPage.data },
        },
      });
    }
  }, [currentPage.data, currentPage.page, navigate]);

  const handlePullToRefresh = (callback: Function) => {
    // Call fetchAbsences and wait for the promise to resolve
    fetchAbsences()
      .then(() => {
        // After the fetch is done, call the callback function
        callback();
      })
      .catch((error) => {
        console.error("Error refreshing data:", error);
        // Still call the callback function in case of an error
        callback(error);
      });
  };

  return (
    <AppLayout hideHeader={true}>
      <div className={styles.tabs}>
        {/* Render Tabs */}
        {TABS.map(({ tab, text }) => (
          <TabButton
            key={tab}
            onClick={() => {
              setActiveTab(tab);
            }}
            active={activeTab === tab}
          >
            {t(text)}
          </TabButton>
        ))}
      </div>

      <div className={styles.content}>
        <PullToRefresh onRefresh={handlePullToRefresh}>
          {absences.length > 0 ? (
            absences.map((absence: any) => (
              <AbsenceCard
                type={absence.type}
                key={absence.id}
                status={absence.status}
                countDays={absence.countDays}
                countHours={absence.countHours || 0}
                sickNote={absence.sickNote || null}
                dateFrom={absence.dateSince}
                dateUntil={absence.dateUntil}
                onClick={() => navigateToAbsenceInsight(navigate, absence)}
              />
            ))
          ) : (
            <NoData>{t("NoEntries")}</NoData>
          )}
        </PullToRefresh>
      </div>

      <FloatingButtons>
        <CustomButton color="green" onClick={() => navigate("/new-absence")}>
          {t("EnterTermin")}
        </CustomButton>
      </FloatingButtons>
    </AppLayout>
  );
};
