import accountCircleDark from "./account_circle_24px_dark.png";
import accountCircleLight from "./account_circle_24px_light.png";

type AccountCircleIconProps = {
  color?: "dark" | "light";
};

const ACCOUNT_CIRCLE_IMAGES: Record<"light" | "dark", string> = {
  light: accountCircleLight,
  dark: accountCircleDark,
};

export const AccountCircleIcon = ({ color }: AccountCircleIconProps) => (
  <img
    height={20}
    src={ACCOUNT_CIRCLE_IMAGES[color || "light"]}
    alt="Account circle icon"
  />
);
