import { createSlice } from "@reduxjs/toolkit";

export type onlineStatusState = {
  isOnline: boolean;
};

const initialState: onlineStatusState = {
  isOnline: true,
};

export const onlineStatusSlice = createSlice({
  name: "online-status",
  initialState,
  reducers: {
    setOnline(state: onlineStatusState, action: { payload: boolean }) {
      state.isOnline = action.payload;
    },
  },
});

export const onlineStatusActions = onlineStatusSlice.actions;
