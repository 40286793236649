import { MouseEventHandler } from "react";

import reset from "./reset_icon_21px_dark.png";

type ResetIconProps = {
  color?: "dark";
  handleResetButtonClicked: MouseEventHandler<HTMLImageElement>;
};

const RESET_IMAGES: Record<"dark", string> = {
  dark: reset,
};

export const ResetIcon = ({
  color = "dark",
  handleResetButtonClicked,
}: ResetIconProps) => (
  <img
    src={RESET_IMAGES[color]}
    alt="Reset icon"
    onClick={handleResetButtonClicked}
  />
);
