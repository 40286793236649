import { MouseEventHandler } from "react";

import plusDark from "./plus_icon_24px_dark.png";
import plusLight from "./plus_icon_24px_light.png";

type PlusIconProps = {
  color?: "dark" | "light";
  handlePlusClicked?: MouseEventHandler<HTMLImageElement>;
};

const PLUS_IMAGES: Record<"dark" | "light", string> = {
  dark: plusDark,
  light: plusLight,
};

export const PlusIcon = ({ color, handlePlusClicked }: PlusIconProps) => (
  <img
    src={PLUS_IMAGES[color || "dark"]}
    alt="Plus icon"
    onClick={handlePlusClicked}
  />
);
