import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Deficiency } from "../types/deficiencies";
import { ImageProps } from "../types/image";

export type DeficiencyServiceSliceState = {
  deficiency: Deficiency | null;
  actions: string;
  comment: string;
  images: ImageProps[];
  allImages: ImageProps[];
  isCustomerEditing: boolean;
  existingImages: ImageProps[];
};

const initialState: DeficiencyServiceSliceState = {
  deficiency: null,
  actions: "",
  comment: "",
  images: [],
  allImages: [],
  isCustomerEditing: false,
  existingImages: [],
};

export const deficiencyServiceSlice = createSlice({
  name: "deficiencyService",
  initialState: initialState,
  reducers: {
    setDeficiency(state, { payload }: PayloadAction<Deficiency>) {
      state.deficiency = payload;
    },
    setActions(state, { payload }: PayloadAction<string>) {
      state.actions = payload;
    },
    setComment(state, { payload }: PayloadAction<string>) {
      state.comment = payload;
    },
    setIsCustomerEditing(state, { payload }: PayloadAction<boolean>) {
      state.isCustomerEditing = payload;
    },
    setExistingImages(state, { payload }: PayloadAction<ImageProps[]>) {
      state.existingImages = payload;
    },
    addImage(state, { payload }: PayloadAction<ImageProps>) {
      state.images.push(payload);
    },
    saveAllImages(state, { payload }: PayloadAction<ImageProps>) {
      if (!state.allImages) {
        state.allImages = [];
      }
      state.allImages.push(payload);
    },
    removeImage(state, { payload }: PayloadAction<string>) {
      state.images = state.images.filter((image) => image.uuid !== payload);

      state.allImages = state.allImages.filter(
        (image) => image.uuid !== payload
      );
    },
    removeImages(state) {
      state.images = [];
    },
    resetState() {
      return initialState;
    },
    resetServiceData(state) {
      return { ...initialState, deficiency: state.deficiency };
    },
  },
});

export const deficiencyServiceActions = deficiencyServiceSlice.actions;
