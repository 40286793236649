import classNames from "classnames";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useScreenSize } from "../../hooks/useScreenSize";
import { ReturnArrowIcon } from "../../icons/ReturnArrowIcon/ReturnArrowIcon";
import { RootState } from "../../store";
import { CustomButton } from "../CustomButton/CustomButton";

import styles from "./back-button-header.module.css";

type BackButtonHeaderProps = {
  handleBackButtonClicked: MouseEventHandler<HTMLImageElement>;
  handleFetchDocument?: MouseEventHandler<HTMLButtonElement>;
  backButtonForModal?: boolean;
  isDeficiency?: boolean;
  isEditedByCustomer?: boolean;
};

export const BackButtonHeader = ({
  handleBackButtonClicked,
  handleFetchDocument,
  backButtonForModal = false,
  isDeficiency = false,
  isEditedByCustomer = false,
}: BackButtonHeaderProps): JSX.Element => {
  const [t] = useTranslation();
  const isRunning = useSelector((state: RootState) => state.clock.isRunning);
  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );
  const { isDesktop } = useScreenSize();

  const classes = classNames(styles["back-header"], {
    [styles["back-header__green"]]: isRunning && !backButtonForModal,
    [styles["back-header__white"]]: !isRunning,
    [styles["back-header__white--modal"]]: isRunning && backButtonForModal,
  });

  return (
    <div className={classes}>
      <div>
        <ReturnArrowIcon handleBackButtonClicked={handleBackButtonClicked} />
        {isCustomerAdmin && isDesktop && isDeficiency && (
          <p className={styles["deficiency-type__title"]}>
            {t("CreateDeficiency")}
          </p>
        )}
      </div>
      {isDesktop && !isEditedByCustomer && (
        <div className={styles["back-header__download"]}>
          <p>{t("DownloadDocumentation")}</p>
          <CustomButton
            color="gray"
            icon="document"
            width={48}
            onClick={handleFetchDocument}
          />
        </div>
      )}
    </div>
  );
};
