import { createSlice } from "@reduxjs/toolkit";

type AppSliceState = {
  properties: any[];
  appointments: any[];
};

const initialState: AppSliceState = {
  properties: [],
  appointments: [],
};

export const appStateSlice = createSlice({
  name: "app-state",
  initialState,
  reducers: {
    addProperty(state, action) {
      const existingPropertyIndex = state.properties.findIndex(
        (property) => property.id === action.payload.id
      );

      if (existingPropertyIndex !== -1) {
        // If the property already exists, replace it with the new data
        state.properties[existingPropertyIndex] = action.payload;
      } else {
        // If it doesn't exist, push it as a new property
        state.properties.push(action.payload);
      }
    },
    addAppointment(state, action) {
      const existingAppointmentIndex = state.appointments.findIndex(
        (appointment) => appointment.id === action.payload.id
      );

      if (existingAppointmentIndex !== -1) {
        state.appointments[existingAppointmentIndex] = action.payload;
      } else {
        state.appointments.push(action.payload);
      }
    },
  },
});

export const appStateActions = appStateSlice.actions;
