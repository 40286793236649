import { t } from "i18next";

import { ButtonType as CustomerButtonType } from "../../pages/Customer/Dashboard/Dashboard";
import { ButtonType } from "../../pages/Dashboard/PropertyInsight";

import styles from "./sub-menu-button.module.css";

type SubMenuButtonProps = {
  currentTab: ButtonType | CustomerButtonType | string;
  value: string;
  onSetTab: Function;
};

export const SubMenuButton = ({
  onSetTab,
  currentTab,
  value,
}: SubMenuButtonProps) => (
  <button
    onClick={() => onSetTab(value)}
    className={
      currentTab === value ? styles["low-button-clicked"] : styles["low-button"]
    }
  >
    {t(value)}
  </button>
);
