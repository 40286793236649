import moment from "moment";

import { ClockodoAbsence } from "../../types/absence";
import { ClockodoEntry, ClockodoUser } from "../../types/user";
import ApiService from "../ApiService";

type ActiveUsersResponse = {
  paging: {
    itemsPerPage: number;
    currentPage: number;
    countPages: number;
    countItems: number;
  };
  filter: any;
  countItems: 1;
  entries: ClockodoEntry[];
};

type AbsentUser = ClockodoAbsence & {
  user: ClockodoUser;
};

type AbsentUsersResponse = {
  data: AbsentUser[];
};

const ONE_MONTH_BEFORE_TODAY = moment()
  .subtract(1, "month")
  .startOf("day")
  .format("YYYY-MM-DDTHH:mm:ss\\Z");

const ONE_MONTH_AFTER_TODAY = moment()
  .add(1, "month")
  .startOf("day")
  .format("YYYY-MM-DDTHH:mm:ss\\Z");

const UserService = {
  GetActiveUsers: async <
    T extends { data: ActiveUsersResponse },
  >(): Promise<T> => {
    return ApiService.Get(`clockodo/users/active`, {
      time_since: ONE_MONTH_BEFORE_TODAY,
      time_until: ONE_MONTH_AFTER_TODAY,
      enhanced_list: 1,
    });
  },

  GetAbsentUsers: async (): Promise<AbsentUsersResponse> => {
    return ApiService.Get("clockodo/users/absent", {
      year: moment().year(),
    });
  },

  GetNotCheckedIn: async (): Promise<{ data: ClockodoUser[] }> => {
    return ApiService.Get("clockodo/users/not-checked-in");
  },
};

export default UserService;
