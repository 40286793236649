import { CloseFullScreenIcon } from "../../icons/CloseFullScreenIcon/CloseFullScreenIcon";

import styles from "./full-screen-preview-image.module.css";

type FullScreenPreviewImageProps = {
  imageUrl: string;
  closePreview: (
    event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>
  ) => void;
};

const FullScreenPreviewImage = ({
  imageUrl,
  closePreview,
}: FullScreenPreviewImageProps) => (
  <div className={styles["fullscreen-preview"]} onClick={closePreview}>
    <div className={styles["fullscreen-image-container"]}>
      <div className={styles["fullscreen-close-button"]}>
        <CloseFullScreenIcon onClick={closePreview} />
      </div>
      <img
        src={imageUrl}
        alt="Preview"
        className={styles["fullscreen-image"]}
      />
    </div>
  </div>
);

export default FullScreenPreviewImage;
