import { CheckCircle } from "@mui/icons-material";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../store";
import { creatingTaskActions } from "../../store/task-slice";

import styles from "./task-form.module.css";

const TYPES = ["Monitoring", "Bekämpfung"];

export const AppointmentType = () => {
  const dispatch = useDispatch();
  const task = useSelector((state: RootState) => state.task);

  const handleSetType = (type: string | null) => {
    dispatch(
      creatingTaskActions.setTypeOfAppointment({
        typeOfAppointment: type === "Bekämpfung" ? "auftrag" : type,
      })
    );
  };

  return (
    <div
      className={`${styles["new-task-info"]} ${
        task.typeOfAppointment && [styles["new-task-info--active"]]
      }`}
      onClick={() => task.typeOfAppointment && handleSetType(null)}
    >
      <div className={styles["task-type"]}>
        <p className={styles["task__customer-name"]}>{task.customer.name}</p>
        <h2>{task.property.name}</h2>

        {task.typeOfAppointment && (
          <div
            className={styles["new-task-info-complete"]}
            onClick={() => handleSetType(null)}
          >
            <CheckCircle />
          </div>
        )}

        {!task.typeOfAppointment && (
          <>
            <h2>{t("OrderTypeSelect")}</h2>
            {TYPES.map((type, i) => (
              <div
                key={i}
                className={`${styles["task-type__value"]} ${
                  task.typeOfAppointment === type &&
                  styles["task-type__value--active"]
                }`}
                onClick={() => handleSetType(type)}
              >
                <div>{type}</div>
                <CheckCircle />
              </div>
            ))}
          </>
        )}

        {task.typeOfAppointment && (
          <span>
            {task.typeOfAppointment === "auftrag"
              ? "Bekämpfung"
              : task.typeOfAppointment}
          </span>
        )}
      </div>
    </div>
  );
};
