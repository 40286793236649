import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MonitorService from "../../api/MonitorService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import PinCropMap from "../../components/PinCropMap/PinCropMap";
import { MonitorIcon } from "../../icons/MonitorIcon/MonitorIcon";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { RootState } from "../../store";
import { adjustPinActions } from "../../store/adjust-pin-slice";
import styles from "../../styles/monitor-adjust-position.module.css";

export const MonitorAdjustPositionData = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const monitor = useSelector((state: RootState) => state.monitor.monitor);
    const appointment = useSelector((state: RootState) => state.appointment);
    const { monitorPosition, position, pinName, location } = useSelector((state: RootState) => state.adjustPin);
    const monitorTypes = useSelector(
        (state: RootState) => state.monitor.monitorTypes
    );
    const [loading, setLoading] = useState(false);
    const pinAbbr = {
        name: pinName,
        number: position.toString() ?? "001",
    };

    const handleBack = () => {
        navigate(-1);
    }

    const setLocation = (location: string) => {
        dispatch(adjustPinActions.setLocation(location));
    }

    const setPosition = (number: string) => {
        dispatch(adjustPinActions.setPosition(number));
    }

    const saveChanges = async () => {
        setLoading(true);

        await MonitorService.UpdateMonitor(
            monitor.id,
            {
                monitorTypeId: monitor.monitorTypeId,
                location,
                position,
                mapPosition: {
                    mapVersionId: null,
                    mapPoint: {
                        ...monitorPosition,
                    },
                },
            })
            .then(() => {
                dispatch(adjustPinActions.resetState());
                navigate(`/properties/${appointment.id}/show`);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <AppLayout hideHeader>
            <BackButtonHeader handleBackButtonClicked={handleBack} />
            <AppointmentDetails />
            <div className={styles["content-wrapper"]}>
                <div className={styles["content"]}>
                    <p className={styles["monitor-adjust--title"]}>
                        <MonitorIcon color="dark" />  {monitor?.monitorType} {position}
                    </p>

                    <div className={`${styles["monitor-adjust--map"]} ${styles["monitor-adjust-data--map"]}`}>
                        <PinCropMap
                            map={monitorTypes.map!}
                            crop={monitorPosition}
                            onCropChange={() => { }}
                            monitorPinAbbr={pinAbbr}
                            isDraggable={false}
                            zoom={false}
                        />
                    </div>

                    <CustomInput
                        label="Standortinformation"
                        placeholder="Kein Standort eingetragen"
                        onInputChange={setLocation}
                        onBlur={() => { }}
                        initialValue={location}
                        maxLength={60}
                    />

                    <CustomInput
                        label="Monitor Nummer"
                        placeholder="Kein Nummer eingetragen"
                        onInputChange={setPosition}
                        onBlur={() => { }}
                        initialValue={position.toString()}
                        maxLength={10}
                    />

                </div>
            </div>
            <FloatingButtons>
                <CustomButton
                    color="gray"
                    onClick={saveChanges}
                    width={80}
                    loading={loading}
                >
                    {t("Confirm")}
                </CustomButton>
            </FloatingButtons>
        </AppLayout>
    )
};