import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CardWrapper } from "../../components/CardWrapper/CardWrapper";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import Modal from "../../components/Modal/Modal";
import { MonitorTypeListItem } from "../../components/MonitorTypeListItem/MonitorTypeListItem";
import statuses from "../../data/statuses.json";
import { useHandleService } from "../../hooks/useHandleService";
import { useService } from "../../hooks/useService";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { goOneStepBack } from "../../services/NavigationService";
import { RootState } from "../../store";
import { adjustPinActions } from "../../store/adjust-pin-slice";
import { clockActions } from "../../store/clock-slice";
import { creatingServiceActions } from "../../store/job-slice";
import { creatingMonitorActions } from "../../store/monitor-slice";
import { servicedMonitorsActions } from "../../store/serviced-monitors";
import { activeTabsActions } from "../../store/tabs-slice";
import styles from "../../styles/monitor-box-status.module.css";
import { MonitorEventType } from "../../types/service";

export const MonitorBoxStatus = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const monitor = useSelector((state: RootState) => state.monitor.monitor);
  const isRunning = useSelector((state: RootState) => state.clock.isRunning);

  const [isStartModalOpen, setIsModalOpen] = useState(false);
  const [isStopModalOpen, setIsModalStopped] = useState(false);
  const [loading, setLoading] = useState(false);

  const appointment = useSelector((state: RootState) => state.appointment);

  const { stopService } = useService(appointment);

  const { handleStartService, handleStopService } = useHandleService(
    setIsModalOpen,
    setLoading,
    setIsModalStopped
  );

  const handleBack = () => {
    goOneStepBack(navigate);
  };

  const handleNavigation = (route: string, eventType: MonitorEventType) => {
    dispatch(creatingServiceActions.setEventType(eventType));
    navigate(`/services/${route}`);
  };

  const returnToDashboard = () => {
    stopService(() => {
      dispatch(creatingMonitorActions.resetState());
      dispatch(creatingMonitorActions.setInProgress(false));
      dispatch(creatingMonitorActions.setPropertyId(""));
      dispatch(clockActions.resetClock());
    });

    dispatch(activeTabsActions.setDashbaordUrl(""));

    // Reset tab
    dispatch(activeTabsActions.setProperyInsightTab("Monitors"));

    dispatch(servicedMonitorsActions.resetState());
    navigate("/");
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleStopModal = () => {
    setIsModalStopped(true);
  };

  const handleCloseModal = () => {
    setIsModalStopped(false);
  };

  const handleAdjustPosition = () => {
    dispatch(adjustPinActions.setMonitorPosition({ x: monitor.x ?? 1, y: monitor.y ?? 1 }));
    dispatch(adjustPinActions.setPosition(monitor.position ?? ""));
    dispatch(adjustPinActions.setPinName(monitor?.monitorType.slice(0, 3).toUpperCase() ?? ""));
    navigate("/monitor/adjust-position");
  };

  const Title = () => (
    <>
      <p className={styles["monitor-status__monitortype--name"]}>
        {monitor?.monitorType} {monitor?.position}
      </p>
      <p className={styles["monitor-status__propertyarea--name"]}>
        {monitor?.location}
      </p>
    </>
  );

  return (
    <AppLayout hideHeader>
      <div>
        <BackButtonHeader handleBackButtonClicked={handleBack} />

        <AppointmentDetails />
        {loading && <LoadingSpinner loading={loading} />}

        {!loading && (
          <div className={styles["content-wrapper"]}>
            <div className={styles["content"]}>
              <p className={styles["monitor-status--title"]}>
                {t("NewService")}
              </p>
              <CardWrapper title={<Title />} color="white">
                {statuses.map((status: any, i: number) => (
                  <MonitorTypeListItem
                    key={i}
                    onClick={() =>
                      handleNavigation(status.route, status.eventType)
                    }
                  >
                    <span>{status.name}</span>
                    <img alt="" src="/img/icon-next.svg" />
                  </MonitorTypeListItem>
                ))}
              </CardWrapper>
              <CustomButton color="green" customClass={styles["button-adjust-position"]} onClick={handleAdjustPosition}>{t("AdjustPosition")}</CustomButton>

              <FloatingButtons>
                <CustomButton
                  color={isRunning ? "red" : "green"}
                  icon="clock"
                  iconPosition="left"
                  onClick={isRunning ? handleStopModal : handleOpenModal}
                  width={80}
                >
                  {isRunning ? "Service beenden" : "Service starten"}
                </CustomButton>
              </FloatingButtons>

              {isStartModalOpen && (
                <Modal
                  isOpen={isStartModalOpen}
                  message={t("ServiceStartModalMessage")}
                  note={"ServiceStartModalNote"}
                  onCancel={() => setIsModalOpen(false)}
                  onConfirm={handleStartService}
                />
              )}

              {isStopModalOpen && (
                <Modal
                  isOpen={isStopModalOpen}
                  message={t("ServiceStopModalMessage")}
                  onCancel={returnToDashboard}
                  onConfirm={handleStopService}
                  cancelText={"CancelText"}
                  confirmText={"ConfirmText"}
                  backButtonShow={handleCloseModal}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};
