import { createSlice } from "@reduxjs/toolkit";

export type TechnicianProps = {
  id: string;
  firstName: string;
  lastName: string;
};

const initialState: TechnicianProps[] = [];

export const techniciansSlice = createSlice({
  name: "technicians",
  initialState: initialState,
  reducers: {
    toggleTechnician: (state, action: { payload: TechnicianProps }) => {
      const { id } = action.payload;
      const index = state.findIndex((tech) => tech.id === id);

      if (index === -1) {
        // Technician ID not found in the array, add it
        state.push(action.payload);
      } else {
        // Technician ID found in the array, remove it
        state.splice(index, 1);
      }
    },
    resetState() {
      return initialState;
    },
  },
});

export const techniciansSliceActions = techniciansSlice.actions;
