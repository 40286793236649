import { ReactNode } from "react";

import styles from "./info.module.css";

type InfoProps = {
  type?: "success" | "error" | "info";
  children: ReactNode;
};

const INFO_TYPES = {
  success: styles["info--success"],
  error: styles["info--error"],
  info: styles["info--basic"],
};

export const Info = ({ type = "error", children }: InfoProps): JSX.Element => (
  <div className={`${styles["info"]} ${INFO_TYPES[type]}`}>{children}</div>
);
