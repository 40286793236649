import { MouseEventHandler } from "react";

import remove from "./remove_icon_24px.png";

type RemoveIconProps = {
  handleRemoveClicked: MouseEventHandler<HTMLImageElement>;
};

export const RemoveIcon = ({ handleRemoveClicked }: RemoveIconProps) => (
  <img src={remove} alt="Remove icon" onClick={handleRemoveClicked} />
);
