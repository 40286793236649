import { Check, Close } from "@mui/icons-material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AbsenceService from "../../api/clockodo/AbsenceService";
import { CustomPicker } from "../../components/CustomPickerV2/CustomPicker";
import { CustomToggleSelect } from "../../components/CustomToggleSelect/CustomToggleSelect";
import { DateFromAndToPicker } from "../../components/DateFromAndToPicker/DateFromAndToPicker";
import { WorkerContext } from "../../context/worker-context";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { RootState } from "../../store";
import { absencesActions } from "../../store/absences-slice";
import styles from "../../styles/new-absence.module.css";

const NewAbsence = () => {
  const dispatch = useDispatch();
  const [selectedAbsenceType, setSelectedAbsenceType] = useState<{
    id: number | null;
    value: string;
  } | null>(null);

  const [dateFrom, setDateFrom] = useState<string>();
  const [dateTo, setDateTo] = useState<string>();
  const [isHalfDay, setIsHalfDay] = useState<boolean | undefined>(undefined);
  const [withSickNote, setWithSickNote] = useState<boolean | null>(null);
  const [overtimeHours, setOvertimeHours] = useState<number | null>(null);
  const [note, setNote] = useState<string>("");
  const [error, setError] = useState<string>("");

  const navigate = useNavigate();

  const clockodoUser = useSelector(
    (state: RootState) => state.user.clockodoUser
  );

  const { submitAbsence } = useContext(WorkerContext);

  const absenceTypes = useSelector((state: RootState) => state.absences.types);

  useEffect(() => {
    if (absenceTypes.length === 0) {
      AbsenceService.GetAbsenceTypes()
        .then((types) => {
          dispatch(
            absencesActions.setTypes(
              types.data.map((type) => ({
                id: type.id,
                value: type.type,
              }))
            )
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [absenceTypes.length, dispatch]);

  const handleDateFromChange = (newDateFrom: string) => {
    setDateFrom(newDateFrom);
  };

  const handleDateToChange = (newDateTo: string) => {
    setDateTo(newDateTo);
  };

  const onSubmitAbsence = () => {
    setError("");

    submitAbsence(
      {
        date_since: moment(dateFrom, "D.M.YYYY").format("YYYY-MM-DD"),
        date_until: moment(dateTo, "D.M.YYYY").format("YYYY-MM-DD"),
        type: selectedAbsenceType?.id,
        count_days: isHalfDay ? 0.5 : overtimeHours ? null : null,
        sick_note: withSickNote,
        count_hours: overtimeHours,
        note,
        usersId: clockodoUser?.id,
        status: 0,
      },
      (isSuccessful: boolean, error?: string) => {
        if (isSuccessful) {
          navigate("/calendar");
        } else {
          if (error) {
            setError(error);
          } else {
            setError("Failed to submit absence");
          }
        }
      }
    );
  };

  const onCancel = () => {
    navigate("/calendar");
  };

  const showAdditionalOptions = () => {
    if (!selectedAbsenceType) return;

    switch (selectedAbsenceType.id) {
      case 4:
      case 5:
        // Sick Days. With doctor's notice?
        return (
          <CustomToggleSelect
            selected={withSickNote}
            onClick={() => setWithSickNote(!withSickNote)}
          >
            Mit Krankschreibung
          </CustomToggleSelect>
        );
      case 3:
        // Overtime Reduction. How many hours?
        return (
          <div className={styles["new-absence__options--overtime"]}>
            <span>Anzahl der Stunden</span>
            <input
              type="number"
              placeholder="0"
              value={overtimeHours || ""}
              onClick={() => setOvertimeHours(0)}
              onChange={(e) =>
                setOvertimeHours(
                  Math.min(Math.max(parseInt(e.target.value), 0), 100)
                )
              }
            />
          </div>
        );
      case 1:
        // Regular Holiday. Half day off?
        return (
          <CustomToggleSelect
            selected={isHalfDay}
            onClick={() => setIsHalfDay(!isHalfDay)}
          >
            Halber Tag Abwesenheit
          </CustomToggleSelect>
        );
    }
  };

  useEffect(() => {
    if (selectedAbsenceType) {
      if (selectedAbsenceType.id !== 4 && selectedAbsenceType.id !== 5) {
        setWithSickNote(null);
      }
      if (selectedAbsenceType.id !== 3) {
        setOvertimeHours(null);
      }
      if (selectedAbsenceType.id !== 1) {
        setIsHalfDay(undefined);
      }
      setError("");
    }
  }, [selectedAbsenceType?.id]);

  const handleAbsenceTypeScroll = (index: number) => {
    const absenceType = absenceTypes[index];

    if (absenceType) {
      setSelectedAbsenceType({
        id: absenceType.id,
        value: absenceType.value,
      });
    }
  };

  useEffect(() => {
    if (!selectedAbsenceType) {
      setSelectedAbsenceType(absenceTypes[0]);
    }
  }, [absenceTypes]);

  return (
    <AppLayout
      hideHeader={true}
      className={styles["new-absence"]}
      wrapperClass="absence-wrapper"
    >
      <h2>Abwesenheit eintragen</h2>

      <div className={styles["new-absence__type"]}>
        <CustomPicker
          variant="large"
          data={absenceTypes.map((type) => type.value)}
          selected={selectedAbsenceType?.value}
          onScroll={handleAbsenceTypeScroll}
          background="dark"
          enableTranslation={true}
        />
      </div>

      <DateFromAndToPicker
        onDateFromChange={handleDateFromChange}
        onDateToChange={handleDateToChange}
        isHalfDay={isHalfDay}
      />

      {showAdditionalOptions()}

      <div className={styles["new-absence__note"]}>
        <textarea
          placeholder="Zusätzlicher Text"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </div>

      {error && (
        <div
          className={styles["new-absence__error"]}
          onClick={() => setError("")}
        >
          {error}
        </div>
      )}

      <div className={styles["new-absence__actions"]}>
        <button
          onClick={onCancel}
          className={styles["new-absence__actions--cancel"]}
        >
          <Close />
        </button>

        <button
          onClick={onSubmitAbsence}
          className={styles["new-absence__actions--submit"]}
        >
          <Check />
        </button>
      </div>
    </AppLayout>
  );
};

export default NewAbsence;
