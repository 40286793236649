import moment from "moment";

import {
  ClockodoAbsence,
  ClockodoAbsenceStatus,
  ClockodoAbsenceTypes,
} from "../../types/absence";
import ApiService from "../ApiService";

const AbsenceService = {
  GetAbsences: async <T extends { data: ClockodoAbsence }>(
    users_id: number | null
  ): Promise<T> => {
    return ApiService.Get(`clockodo/absence`, {
      year: moment().year(),
      users_id: users_id ?? "",
    });
  },

  GetAbsence: async (absenceId: string): Promise<{ data: ClockodoAbsence }> => {
    return ApiService.Get(`clockodo/absences/${absenceId}`);
  },

  GetAbsenceStatuses: async (): Promise<{ data: ClockodoAbsenceStatus[] }> => {
    return ApiService.Get(`clockodo/absence/statuses`);
  },

  GetAbsenceTypes: async (): Promise<{ data: ClockodoAbsenceTypes[] }> => {
    return ApiService.Get(`clockodo/absence/types`);
  },
};

export default AbsenceService;
