import { createSlice } from "@reduxjs/toolkit";

export type productDocumentsState = {
  empty: boolean;
};

const initialState: productDocumentsState = {
  empty: false,
};

export const productDocumentsSlice = createSlice({
  name: "productDocuments",
  initialState,
  reducers: {
    setIsEmpty(state: productDocumentsState, action: { payload: boolean }) {
      state.empty = action.payload;
    },
  },
});

export const productDocumentsActions = productDocumentsSlice.actions;
