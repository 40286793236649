import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type TaskSliceState = {
  customer: any;
  property: any;
  date: string;
  time: string;
  typeOfAppointment: "monitoring" | "auftrag" | null;
  comment: string;
  additionalData: {
    customer: {
      id: string;
      name: string;
    };
    property: {
      id: string;
      address: string;
      isSevdeskProperty?: number;
    };
  };
};

const initialState: TaskSliceState = {
  customer: "",
  property: "",
  date: "",
  time: "",
  typeOfAppointment: null,
  comment: "",
  additionalData: {
    customer: {
      id: "",
      name: "",
    },
    property: {
      id: "",
      address: "",
      isSevdeskProperty: 0,
    },
  },
};

export const creatingTaskSlice = createSlice({
  name: "task",
  initialState: initialState,
  reducers: {
    setCustomer(state, { payload }) {
      state.customer = payload.customer;
    },
    setCustomerProperties(state, { payload }) {
      state.customer.properties = payload.customerProperties;
    },
    setProperty(state, { payload }) {
      state.property = payload.property;
    },
    setDate(state, { payload }) {
      state.date = payload;
    },
    setTime(state, { payload }) {
      state.time = payload;
    },
    setTypeOfAppointment(state, { payload }) {
      state.typeOfAppointment = payload.typeOfAppointment;
    },
    setComment(state, { payload }: PayloadAction<{ comment: string }>) {
      state.comment = payload.comment;
    },
    setAdditionalDataCustomer(
      state,
      { payload }: { payload: { customer: { id: string; name: string } } }
    ) {
      state.additionalData.customer = payload.customer;
    },
    setAdditionalDataProperty(
      state,
      {
        payload,
      }: {
        payload: {
          property: { id: string; address: string; isSevdeskProperty: number };
        };
      }
    ) {
      state.additionalData.property = payload.property;
    },
    resetAppointment() {
      return initialState;
    },
  },
});

export const creatingTaskActions = creatingTaskSlice.actions;
