import { ResponseMeta, ResponseWarningQueries } from "../types/response";
import { Technician } from "../types/user";

import ApiService from "./ApiService";

type UsersResponse = {
  data: Technician[];
} & ResponseMeta &
  ResponseWarningQueries;

const UserService = {
  GetTechnicians: async (): Promise<UsersResponse> => {
    return ApiService.Get("employees", {
      sort: "firstName",
    });
  },

  GetCurrentUser: async (): Promise<{ data: Technician }> => {
    return ApiService.Get("users/me");
  },
};

export default UserService;
