import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useDownloadFiles } from "../../hooks/useDownloadFiles";
import { RootState } from "../../store";

import Modal from "./Modal";

const NoProductDocumentsModal = () => {
  const { t } = useTranslation();
  const isProductDocumentsEmpty = useSelector(
    (state: RootState) => state.productDocuments.empty
  );

  const { closeProductModal } = useDownloadFiles();

  return (
    <Modal
      isOpen={isProductDocumentsEmpty}
      message={t("NoInformation")}
      note={"NoProductDocuments"}
      showNoInformationIcon
      confirmText={"Back"}
      onConfirm={closeProductModal}
    />
  );
};

export default NoProductDocumentsModal;
