import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "../store";

export const useClockNavigator = () => {
  const navigate = useNavigate();

  const servicedDeficiency = useSelector(
    (state: RootState) => state.deficiencyService.deficiency
  );
  const appointmentId = useSelector(
    (state: RootState) => state.clock.appointmentId
  );

  const handleClockClick = () => {
    if (servicedDeficiency) {
      return navigate(`/deficiencies/${servicedDeficiency.id}/resolve`);
    }
    navigate(`/properties/${appointmentId}/show`);
  };

  return {
    handleClockClick,
  };
};
