import saveAs from "file-saver";
import JSZip from "jszip";

export const addDocumentToZip = async (
  zip: JSZip,
  doc: any,
  existingFilenames: Set<string>
) => {
  try {
    const response = await fetch(doc.originalUrl);
    const blob = await response.blob();
    let filename = doc.fileName;

    if (existingFilenames.has(filename)) {
      const extIndex = filename.lastIndexOf(".");
      const name = filename.substring(0, extIndex);
      const ext = filename.substring(extIndex);

      let counter = 1;
      let newFilename = `${name}_${counter}${ext}`;

      while (existingFilenames.has(newFilename)) {
        counter++;
        newFilename = `${name}_${counter}${ext}`;
      }

      filename = newFilename;
    }

    existingFilenames.add(filename);

    zip.file(filename, blob);
  } catch (error) {
    console.error(`Failed to fetch document ${doc.name}:`, error);
  }
};

export const generateAndSaveZip = async (documents: any[]) => {
  const zip = new JSZip();
  const existingFilenames = new Set<string>();

  for (const doc of documents) {
    await addDocumentToZip(zip, doc, existingFilenames);
  }

  zip.generateAsync({ type: "blob" }).then((content) => {
    saveAs(content, "documents.zip");
  });
};
