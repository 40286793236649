import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type ClockState = {
  appointmentId: string | undefined;
  initialTime: number;
  isRunning: boolean;
  currentTime: number;
};

const initialState: ClockState = {
  appointmentId: undefined,
  initialTime: 0,
  isRunning: false,
  currentTime: 0,
};

export const clockSlice = createSlice({
  name: "clock",
  initialState: initialState,
  reducers: {
    setAppointmentId(state, action: PayloadAction<string | undefined>) {
      if (action.payload) {
        localStorage.setItem("servicePropertyId", action.payload);
      }
      state.appointmentId = action.payload;
    },
    setInitialTime(state, action: PayloadAction<number>) {
      state.initialTime = action.payload;
    },
    setIsRunning(state, action: PayloadAction<boolean>) {
      state.isRunning = action.payload;
    },
    setCurrentTime(state, action: PayloadAction<number>) {
      state.currentTime = action.payload;
    },
    tick: (state) => {
      state.currentTime = state.currentTime + 1;
    },
    setClock(_, action: PayloadAction<ClockState>) {
      if (action.payload.appointmentId) {
        localStorage.setItem("servicePropertyId", action.payload.appointmentId);
      }
      return action.payload;
    },
    resetClock(state) {
      localStorage.removeItem("servicePropertyId");

      state.isRunning = false;
      state.initialTime = 0;
      state.currentTime = 0;
      state.appointmentId = undefined;
    },
  },
});

export const clockActions = clockSlice.actions;
