import { capitalize } from "@mui/material";
import classNames from "classnames";
import { t } from "i18next";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useRedirect } from "../../hooks/useRedirect";
import { splitDateTime } from "../../services/DateTimeService";
import { RootState } from "../../store";
import { setAppointment } from "../../store/appointment-slice";
import { Appointment } from "../../types/appointment";
import Modal from "../Modal/Modal";

import styles from "./appointment-card.module.css";

const TODAY = moment().startOf("day");

type AppointmentCardProps = {
  appointment: Appointment;
  dateInCircle?: boolean;
  upcoming?: boolean;
  onClick: () => void;
  color?: "blue";
  isDashboard?: boolean;
};

export const AppointmentCard = ({
  appointment,
  dateInCircle = true,
  upcoming = true,
  onClick,
  color,
  isDashboard = false,
}: AppointmentCardProps) => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>("");

  const { setDashboardUrlAndRedirect } = useRedirect();

  const { appointmentState } = appointment;

  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );

  const handleConfirmModal = () => {
    //We want to cancel the service
    setIsModalOpen(false);
  };

  const { date, time } = splitDateTime(appointment.plannedStart);
  const isToday = moment(appointment.plannedStart).isSame(TODAY, "day");
  const isDeficiency = appointment.incidents.length > 0;

  const tickingClockAppointmentId = useSelector(
    (state: RootState) => state.clock.appointmentId
  );
  const onTaskClicked = () => {
    if (appointmentState === "completed" && !isCustomerAdmin && isDashboard) {
      setModalMessage("TaskCompleted");
      return setIsModalOpen(true);
    }
    /* Handle showing modal if service is running and clicked appointment
    is not the appointment that the service is running for */

    if (
      tickingClockAppointmentId &&
      tickingClockAppointmentId !== appointment.id &&
      isDashboard
    ) {
      setModalMessage("AnotherServiceInProgress");
      return setIsModalOpen(true);
    }

    dispatch(setAppointment(appointment));

    if (isDeficiency) {
      const deficiencyId = appointment.incidents[0].id;
      const url = `/deficiencies/${deficiencyId}/show`;

      setDashboardUrlAndRedirect(url);
    } else {
      const url = `/properties/${appointment.id}/show`;

      setDashboardUrlAndRedirect(url);
    }
    onClick();
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        message={t(modalMessage)}
        onConfirm={handleConfirmModal}
      />

      <div
        className={classNames(styles["card"], {
          [styles["card__completed"]]:
            appointmentState === "completed" && isDashboard,
        })}
        onClick={onTaskClicked}
      >
        <div
          className={`${styles["card__circle"]} ${
            (isToday || color === "blue") && styles["card__circle--today"]
          }`}
        >
          {dateInCircle ? date : time}
        </div>
        <div className={styles["card__info"]}>
          <div className={styles["card__info__title"]}>
            {upcoming && (
              <i className={styles["card__info__title--upcoming"]}>
                {`${time} Uhr `}
              </i>
            )}
            <span>
              {appointment.additionalData?.customer?.name ?? "Not available"}
            </span>
          </div>

          <div className={styles["card__info__address"]}>
            {appointment.additionalData?.property.address ?? "Not available"}
          </div>

          <div
            className={classNames(styles["card__info__type"], {
              [styles["card__info__type--deficiency"]]: isDeficiency,
            })}
          >
            {isDeficiency
              ? t("Deficiency")
              : appointment.typeOfAppointment === "auftrag"
                ? "Bekämpfung"
                : capitalize(appointment.typeOfAppointment || "")}
          </div>
        </div>
      </div>
    </>
  );
};
