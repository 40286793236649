import { useState } from "react";

export const useOpenPreview = () => {
  const [showPreview, setShowPreview] = useState(false);

  const openPreview = () => {
    setShowPreview(true);
  };

  const closePreview = (
    event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setShowPreview(false);
  };

  return { showPreview, openPreview, closePreview };
};
