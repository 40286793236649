import React, { MouseEventHandler } from "react";

import { FullScreenIcon } from "../../icons/FullScreenIcon/FullScreenIcon";

import styles from "./full-screen-button.module.css";

type fullScreenButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const FullScreenButton = ({
  onClick,
}: fullScreenButtonProps): JSX.Element => {
  return (
    <button className={styles["full-screen-button"]} onClick={onClick}>
      <FullScreenIcon />
    </button>
  );
};
