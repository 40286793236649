import classNames from "classnames";
import { MouseEventHandler, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../store";
import { clockActions } from "../../store/clock-slice";
import { formatClockTime } from "../../utils/formatClockTime";

import styles from "./timer.module.css";

type ClockProps = {
  pulledUp?: boolean; //Gives negative margin
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const Clock = ({ pulledUp, onClick }: ClockProps): JSX.Element => {
  const dispatch = useDispatch();

  const { currentTime, initialTime, isRunning } = useSelector(
    (state: RootState) => state.clock
  );

  useEffect(() => {
    if (!isRunning) {
      dispatch(clockActions.setCurrentTime(initialTime)); // Update time when initialTime changes
    }
  }, [dispatch, initialTime, isRunning]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isRunning) {
      interval = setInterval(() => {
        // setTime((prevTime) => prevTime + 1);
        dispatch(clockActions.tick());
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [dispatch, isRunning]);

  const classes = classNames(styles["content"], {
    [styles["content--pulled-up"]]: pulledUp,
  });

  return (
    <div className={classes} onClick={onClick}>
      <div className={styles["timer"]}>{formatClockTime(currentTime)}</div>
      <div className={styles["red-circle"]} />
    </div>
  );
};

export default Clock;
