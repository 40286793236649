import { t } from "i18next";

import { NoData } from "../../components/NoData/NoData";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import styles from "../../styles/time.module.css";

export const Time = () => (
  <AppLayout>
    <div className={styles["container"]}>
      <NoData>{t("UnderConstruction")}</NoData>
    </div>
  </AppLayout>
);
