import { useDispatch } from "react-redux";

import ProductService from "../api/ProductService";
import TechnicianService from "../api/TechnicianService";
import { productDocumentsActions } from "../store/product-documents-slice";
import { technicianDocumentsActions } from "../store/technician-documents-slice";
import { generateAndSaveZip } from "../utils/generateZipDocument";

export const useDownloadFiles = () => {
  const dispatch = useDispatch();

  const fetchDocuments = async (technicianId: string) => {
    try {
      const technician =
        await TechnicianService.GetTechnicianById(technicianId);

      const documents = technician.includes?.documents;

      if (!documents || documents.length === 0) {
        dispatch(technicianDocumentsActions.setIsEmpty(true));
      } else {
        dispatch(technicianDocumentsActions.setIsEmpty(false));

        await generateAndSaveZip(documents);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProductDocuments = async (productId: any) => {
    try {
      const product = await ProductService.GetProduct(productId);

      const documents = product.includes?.documents;

      if (!documents || documents.length === 0) {
        dispatch(productDocumentsActions.setIsEmpty(true));
      } else {
        dispatch(productDocumentsActions.setIsEmpty(false));

        await generateAndSaveZip(documents);
      }
    } catch (error) {
      console.log(error);
      dispatch(productDocumentsActions.setIsEmpty(true));
    }
  };

  const closeModal = () => {
    dispatch(technicianDocumentsActions.setIsEmpty(false));
  };

  const closeProductModal = () => {
    dispatch(productDocumentsActions.setIsEmpty(false));
  };

  return {
    fetchDocuments,
    fetchProductDocuments,
    closeProductModal,
    closeModal,
  };
};
