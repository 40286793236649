import { ReactNode } from "react";

import styles from "./monitor-type-list-item.module.css";

type MonitorTypeListItemProps = {
  children: ReactNode;
  onClick?: () => void;
  isServicesOverview?: boolean;
};

export const MonitorTypeListItem = ({
  children,
  onClick,
  isServicesOverview = false,
}: MonitorTypeListItemProps) => {
  return (
    <li
      className={`${styles["list__row"]} ${
        isServicesOverview && styles["list__row--reverse-border"]
      }`}
      onClick={onClick}
    >
      <span className={styles["list__item"]}>{children}</span>
    </li>
  );
};
