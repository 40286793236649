import { useSelector } from "react-redux";

import config from "../../config/api.config";
import { RootState } from "../../store";
import { NoData } from "../NoData/NoData";

import styles from "./info-tab.module.css";

export const InfoTab = () => {
  const isOnline = useSelector((state: RootState) => state.online.isOnline);
  const appointment = useSelector((state: RootState) => state.appointment);

  // Format address for google maps src url
  let address = "";
  if (appointment?.additionalData.property.address) {
    address = encodeURIComponent(appointment!.additionalData.property.address);
  }

  const createGoogleMapsSrc = (address: string): string => {
    if (!isOnline) {
      return "";
    }
    return `https://www.google.com/maps/embed/v1/place?key=${config.googleMapsKey}&q=${address}`;
  };

  const embedSrc = createGoogleMapsSrc(address);

  return (
    <div className="info">
      {isOnline ? (
        <iframe
          title="task address"
          src={embedSrc}
          width="100%"
          height="250"
          style={{ border: 0 }}
          allowFullScreen={false}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className={styles["map"]}
        />
      ) : (
        <NoData>No Internet</NoData>
      )}
    </div>
  );
};
