import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import BackEndService from "../../../api/BackEndService";
import { Spinner } from "../../../components/Spinner/Spinner";
import styles from "../form.module.css";

function PasswordForgot() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [emailsent, setEmailSent] = useState(false);
  const [loggedInState, setLoggedInState] = useState(false);

  const navigate = useNavigate();

  function isValidEmail() {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleSubmit = () => {
    setError("");
    if (email.length === 0) {
      setError("ANMELDEDATEN FALSCH");
      return;
    } else if (!isValidEmail()) {
      setError("ANMELDEDATEN FALSCH");
      return;
    }
    setLoggedInState(true);

    BackEndService.PasswordRequest(email).then(
      (data) => {
        setEmailSent(true);
        setLoggedInState(false);
      },
      () => {
        setError("Email existiert nicht");
      }
    );
  };

  const onSignInRedirect = () => {
    navigate("/signin");
  };

  const { t } = useTranslation();

  return (
    <div className={styles["sign-in"]}>
      {loggedInState === true ? <Spinner /> : null}
      {emailsent && (
        <div className={styles["welcome-container"]}>
          <h1 className={styles["welcome-container__message"]}>
            {t("CheckInboxMessage")}
          </h1>
          <button
            onClick={onSignInRedirect}
            className={styles["welcome-container__redirect-button"]}
          >
            <img alt="Go to Sign In" src="/img/complete-button.svg" />
          </button>
        </div>
      )}
      <div className={styles["form"]}>
        <div className={styles["form__email"]}>
          <input
            type="text"
            className={error ? styles["form__email--error"] : ""}
            placeholder={t("Email") ?? ""}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <button onClick={handleSubmit} className={styles["form__button"]}>
          {t("Reset password")}
        </button>
        {emailsent ? (
          <h3 className={styles["form__confirmation-message"]}>
            {email}
            {t("EmailReceival")}
          </h3>
        ) : null}
        {error && <div className={styles["error-message"]}>{error}</div>}
        <Link to="/signin" className={styles["navigation-button"]}>
          ←{t("Back")}
        </Link>
      </div>

      <img className={styles["logo"]} alt="" src="/img/pestix.svg" />
    </div>
  );
}

export default PasswordForgot;
