import classNames from "classnames";

import styles from "./collapse-arrow.module.css";
import collapseArrow from "./collapse_arrow_24px_dark.png";

type CollapseArrowIconProps = {
  color?: "dark";
  reversed?: boolean;
};

const COLLAPSE_ARROW_IMAGES: Record<"dark", string> = {
  dark: collapseArrow,
};

export const CollapseArrowIcon = ({
  color = "dark",
  reversed = false,
}: CollapseArrowIconProps) => {
  const iconClasses = classNames(styles["icon"], {
    [styles["icon--rotated"]]: reversed,
  });

  return (
    <img
      height={8}
      src={COLLAPSE_ARROW_IMAGES[color]}
      alt="Account circle icon"
      className={iconClasses}
    />
  );
};
