import { TrackingEventService } from "../api/TrackingEventService";
import { TimeTrackingEvent } from "../types/event";

import { getServiceStatus } from "./getServiceStatus";

export type TotalTimeResponse = {
  totalTime: number;
  firstEvent: TimeTrackingEvent | null;
  lastEvent: TimeTrackingEvent | null;
};

export const calculateTotalTime = async (
  appointmentId: string
): Promise<TotalTimeResponse> => {
  try {
    const { isServiceInProgress, secondsSinceLastService } =
      await getServiceStatus(appointmentId);

    const {
      data: { totalSeconds, firstEvent, lastEvent },
    } = await TrackingEventService.GetTotalTimeByAppointment(appointmentId);

    let totalTime = totalSeconds;

    if (isServiceInProgress) {
      totalTime += secondsSinceLastService;
    }

    return { totalTime, firstEvent, lastEvent };
  } catch (error) {
    console.error(error);

    return {
      totalTime: 0,
      firstEvent: null,
      lastEvent: null,
    };
  }
};
