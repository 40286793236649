import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { PestCount } from "../../hooks/useAnalysis";

import styles from "./bar-chart.module.css";

Chart.register(...registerables);
Chart.register(ChartDataLabels);

export interface Dataset {
  label: string;
  data: (number | null)[];
  borderWidth: number;
}

interface BarChartProps {
  title: string;
  subtitle: string;
  data: PestCount[];
}

const initialData = {
  labels: [] as string[],
  datasets: [] as Dataset[],
};

const BarChart = ({ title, subtitle, data }: BarChartProps) => {
  const [barData, setBarData] = useState(initialData);
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart<"bar"> | null>(null);
  const { t } = useTranslation();

  const translateAndFormatMonth = (monthWithYear: string): string => {
    return monthWithYear.replace(/(\w+),(\d+)/, (match, month, year) => {
      const formattedMonth =
        month.charAt(0).toUpperCase() + month.slice(1).toLowerCase();
      return `${t(formattedMonth)} ${year}`;
    });
  };
  const initializeChart = useCallback(() => {
    if (!chartRef.current || !barData.datasets.length) {
      chartInstance?.current?.destroy();
      return;
    }

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const chartCanvas = chartRef.current;

    const chart = new Chart(chartCanvas, {
      type: "bar",
      data: barData,
      options: {
        responsive: true,
        skipNull: true,
        layout: { padding: 20 },
        plugins: { legend: { display: false } },
        scales: {
          x: { position: "top" },
          y: {
            ticks: { display: false },
            grid: {
              offset: true,
              display: true,
              color: "#EDEFF5",
              drawTicks: false,
            },
            beginAtZero: true,
          },
        },
        maintainAspectRatio: false,
      },
    });

    chartInstance.current = chart;

    return () => {
      chartInstance.current?.destroy();
    };
  }, [barData]);

  useEffect(() => {
    initializeChart();
  }, [initializeChart]);

  useEffect(() => {
    const allPests = new Set(
      data.flatMap((entry) => entry.pestCounts.map((pest) => pest.pest))
    );

    const datasets = Array.from(allPests).map((pest: string) => ({
      label: pest.charAt(0).toUpperCase() + pest.slice(1).toLowerCase(),
      data: data.map(
        (entry) => entry.pestCounts.find((p) => p.pest === pest)?.count ?? 0
      ),
      borderWidth: 1,
      borderColor: "rgba(0,0,0,0)",
      backgroundColor: "#CED3DC",
      barThickness: 30,
      skipNull: true,
      borderRadius: 6,
      datalabels: {
        display: (context: any) => {
          return context.dataset.data[context.dataIndex];
        },
        labels: [
          {
            formatter: (value: any, context: any) => {
              return context.dataset.data[context.dataIndex] > 0
                ? context.dataset?.label
                : "";
            },
            align: "end",
            anchor: "start",
            rotation: -90,
            color: "#3D5170",
          },
          {
            align: "start",
            anchor: "start",
            formatter: Math.round,
            color: "#3D5170",
          },
        ],
      },
    }));

    const maxBarsInMonth = Math.max(
      ...data.map((entry) => entry.pestCounts.length)
    );

    const calculateWidth = (numBars: number) => {
      const minWidthPerBar = 50;
      const maxWidthPerBar = 200;
      return Math.max(
        numBars * minWidthPerBar,
        maxBarsInMonth * maxWidthPerBar
      );
    };

    setBarData({
      ...data,
      labels: data.map((entry) => translateAndFormatMonth(entry.month)),
      datasets: datasets,
    });

    document.documentElement.style.setProperty(
      "--barchart-min-width",
      `${calculateWidth(maxBarsInMonth)}px`
    );
  }, [data]);

  return (
    <div className={styles["barchart"]}>
      <p className={styles["barchart__title"]}>{title}</p>

      <span className={styles["barchart__subtitle"]}>{subtitle}</span>

      <div className={styles["barchart__data"]}>
        <div
          style={{
            minWidth: "var(--barchart-min-width)",
            height: "500px",
          }}
        >
          <canvas ref={chartRef} />
        </div>
      </div>
    </div>
  );
};

export default BarChart;
